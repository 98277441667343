import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import CardDesktop from './CardDesktop';

const CardsWrapperDesktop = ({ tasks, toPull, cardPlaceholder }) => {
  return (
    <Droppable
      droppableId="rows"
      type="TASK"
      isDropDisabled={!toPull}
      direction="horizontal"
    >
      {(provided, snapshot) => (
        <div
          id="cards"
          className={`cards cards-wrapper-desktop ${
            toPull ? 'cards__disable_anim' : ''
          }`}
          onMouseEnter={() => {
            if (toPull) {
              document
                .querySelectorAll('.cards .cardWrapper')
                .forEach((el) => (el.style.opacity = 0.3));
            }
          }}
          onMouseLeave={() => {
            document
              .querySelectorAll('.cards .cardWrapper')
              .forEach((el) => (el.style.opacity = 1));
          }}
          ref={provided.innerRef}
          {...provided.droppableProps}
          isdraggingover={`${snapshot.isDraggingOver}`}
        >
          {tasks?.map((task, index) => (
            <CardDesktop key={task.id} item={task} index={index} />
          ))}
          {provided.placeholder}
          <div className="card-placeholders h-auto">{cardPlaceholder(0)}</div>
        </div>
      )}
    </Droppable>
  );
};

export default CardsWrapperDesktop;
