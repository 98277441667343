import * as yup from 'yup';
import { GetSchemaType } from '../models/localStorage';

export interface RefCompanyData {
  id: number;
  ref_id: string;
  name: string;
  logo: string;
  theme: string;
}

export const schema = yup.object().required().shape<RefCompanyData>({
  id: yup.number().required(),
  ref_id: yup.string().required(),
  name: yup.string().required(),
  logo: yup.string().required(),
  theme: yup.string().required(),
});

export const localStorageSchemas = { refCompanyData: schema };

export type LocalStorageSchemas = typeof localStorageSchemas;
export type LocalStorageKey = keyof LocalStorageSchemas;

export type LocalStorageKeyMap = { [key in LocalStorageKey]: key };
export const localStorageKey: LocalStorageKeyMap = Object.keys(
  localStorageSchemas,
).reduce((map, key) => ({ ...map, [key]: key }), {} as LocalStorageKeyMap);

export const getLocalStorageItem = <Key extends LocalStorageKey>(key: Key) => {
  type Value = GetSchemaType<LocalStorageSchemas[Key]>;

  const value = localStorage.getItem(key);

  if (value) {
    try {
      const parsedValue = JSON.parse(value);

      return localStorageSchemas[key].validateSync(parsedValue, {
        strict: true,
      }) as Value;
    } catch (error) {
      console.error(`Failed to parse refCompanyData: ${error}`);

      localStorage.removeItem(key);

      return null;
    }
  } else {
    return null;
  }
};
