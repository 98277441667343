import React from 'react';
import { useVideo } from '../hooks';
import { useTranslation } from 'react-i18next';
import { BaseAssessmentProps, PracticeProps } from '../models';
import WhiteboxVideoAssessmentBase from './WhiteboxVideoAssessmentBase';

export interface WhiteboxVideoPracticeProps extends BaseAssessmentProps {
  practiceProps: PracticeProps;
}

const WhiteboxVideoPractice: React.RefForwardingComponent<
  MediaRecorder | null,
  WhiteboxVideoPracticeProps
> = (props, mediaRecorderRef) => {
  const mediaRecorder = mediaRecorderRef as React.MutableRefObject<MediaRecorder | null>;

  /**
   * allows for preserving the mediaStream state which safes getUserMedia calls
   * furthermore videoSrc has precedence over props.mediaStream
   */
  const [videoSrc, setVideoSrc] = React.useState<string | null>(null);

  const { t } = useTranslation();

  const {
    questionData,
    countdown,
    countdownS,
    isInitial,
    isRecording,
    isStopped,
    setCountdownS,
    setQuestionId,
  } = useVideo(props.questions);

  const handleStartRecording = React.useCallback(() => {
    if (mediaRecorder.current && questionData) {
      // * clear previous video
      setVideoSrc(null);

      const videoBlobs: Blob[] = [];
      mediaRecorder.current.ondataavailable = (event) =>
        videoBlobs.push(event.data);

      mediaRecorder.current.start();

      const interval = setInterval(() => {
        setCountdownS((seconds) => seconds && seconds - 1);
      }, 1000);

      const seconds =
        questionData.duration.minutes * 60 + questionData.duration.seconds;

      setCountdownS(seconds);

      const stopTimeout = setTimeout(() => {
        // * triggers final dataavailable event to be fired asynchronously
        mediaRecorder.current?.stop();
      }, seconds * 1000);

      mediaRecorder.current.onstop = () => {
        // * prevent double stopping
        clearTimeout(stopTimeout);
        clearInterval(interval);

        setCountdownS(0);

        if (questionData.nextQuestionId) {
          setQuestionId(questionData.nextQuestionId);
        }

        const videoBlob = new Blob(videoBlobs, { type: 'video/webm' });

        setVideoSrc(URL.createObjectURL(videoBlob));
      };

      mediaRecorder.current.onerror = (event) => {
        // TODO replace alert with a more user friendly approach
        alert(`${t('Error while recording')} ${event.error.message}`);
      };
    } else {
      console.error('Cannot start recording because `mediaRecorder` is null');
    }
    // eslint-disable-next-line
  }, [questionData, mediaRecorder, setCountdownS, setQuestionId]);

  const handleStopRecording = React.useCallback(() => {
    mediaRecorder.current?.stop();
  }, [mediaRecorder]);

  return (
    <WhiteboxVideoAssessmentBase
      {...props}
      src={videoSrc || props.mediaStream}
      questionData={questionData}
      isInitial={isInitial}
      isRecording={isRecording}
      isStopped={isStopped}
      countdown={countdown}
      countdownS={countdownS}
      onStartRecording={handleStartRecording}
      onStopRecording={handleStopRecording}
      practiceProps={props.practiceProps}
    />
  );
};

export default React.forwardRef(WhiteboxVideoPractice);
