import React from 'react';
import { useIsTablet } from '../../../hooks/useIsTablet';
import SurveyQuestionsDesktop from './SurveyQuestionsDesktop';
import SurveyQuestionsMobile from './SurveyQuestionsMobile';

export default function TableSurvey({ questions, startIndex, onChangeRadio }) {
  const isTablet = useIsTablet();

  return (
    <div className="personality-test-form">
      <div className="scrollingTable mb-3">
        {isTablet ? (
          <SurveyQuestionsMobile
            questions={questions}
            onChangeRadio={onChangeRadio}
            startIndex={startIndex}
          />
        ) : (
          <SurveyQuestionsDesktop
            questions={questions}
            onChangeRadio={onChangeRadio}
            startIndex={startIndex}
          />
        )}
      </div>
    </div>
  );
}
