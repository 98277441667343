import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../constants/contentDirection';

const footerLinks = [
  { label: 'Terms of Service', href: '/terms-of-service' },
  { label: 'Privacy Policy', href: '/privacy-policy' },
];

const Footer = (props) => {
  const { t } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  return (
    <footer
      className={clsx(
        props.isDashboardFooter ? 'footer dashboard-footer' : 'footer',
        props.className,
      )}
      style={props.style}
    >
      <p className="footer-copyright">
        &copy; {t('WhiteBox HR')} {new Date().getFullYear()}
      </p>

      <ul
        className={`footer-links ${
          direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
        }`}
      >
        {footerLinks.map((link, i) => (
          <li key={i}>
            <a
              className="footer-copyright"
              target="_blank"
              rel="noopener noreferrer"
              href={link.href}
            >
              {t(link.label)}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
