import axios from 'axios';
// import PromiseAll from "promises-all";
import {
  GET_AUTH_USER_FAILURE,
  GET_VACANCY_REQUEST,
  GET_VACANCY_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import unauthorized from '../unauthorized';

const getVacanciesRequest = (isLoading) => {
  return {
    type: GET_VACANCY_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const getVacanciesSuccess = (vacancies) => {
  return {
    type: GET_VACANCY_SUCCESS,
    payload: {
      vacancies,
    },
  };
};

const getVacanciesFailure = (error) => {
  return {
    type: GET_AUTH_USER_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchVacanciesData = (history = '') => {
  return async (dispatch) => {
    dispatch(getVacanciesRequest(true));
    await axios
      .get(`${BASE_URL}${PATH}applications/`)
      .then((res) => {
        if (!res.data.length) {
          history.push('/dashboard');
        }
        dispatch(
          getVacanciesSuccess({
            started: res.data.filter((application) => application.status === 0),
            pending: res.data.filter((application) => application.status === 1),
            completed: res.data.filter(
              (application) => application.status === 2,
            ),
            deleted: res.data.filter((application) => application.status === 3),
          }),
        );
        dispatch(getVacanciesRequest(false));
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(getVacanciesFailure(err));
        dispatch(getVacanciesRequest(false));
      });

    /*await axios(`${BASE_URL}${PATH}applications/?status=0`)
      .then(res => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(res => {
        // const { vacancies } = res.data;
        dispatch(
          getVacanciesSuccess({
            pending: res.data,
            completed: []
          })
        );
        dispatch(getVacanciesRequest(false));
      })
      .catch(err => {
        unauthorized(500);
        dispatch(getVacanciesFailure(err));
        dispatch(getVacanciesRequest(false));
      });*/
  };
};
