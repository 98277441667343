import urljoin from 'url-join';
import { BASE_URL, PATH, PATHAPI } from '../constants/variables';
import routes from './routes';

export const apiVersionOrigin = `${BASE_URL}${PATH}`;
export const apiOrigin = `${BASE_URL}${PATHAPI}`;

export const defaultPageSize = 10000;

export const requestPaths = {
  whiteboxVideo: 'whitebox_video/',
  questions: 'tests/:id/questions/',
  assessmentComplete: urljoin(routes.applications, 'complete/'),
};
