import axios from 'axios';
import { parse } from 'query-string';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import image1 from '../../assets/game9/screen1.png';
import image2 from '../../assets/game9/screen2.png';
import Loader from '../../components/Loader/Loader';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import PageWrapper from '../PageWrapper';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';

class Game9Info extends React.Component {
  componentDidMount() {
    const { match, history, fetchAssessmentData } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      fetchAssessmentData(match.params.id);
    } else if (!token) {
      history.push('/login');
    }
    this.loadImage(image1);
    this.loadImage(image2);
  }

  loadImage = (src, srcSet) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;
      if (srcSet) {
        image.srcset = srcSet;
      }

      if (image.complete) {
        return resolve();
      }
      image.onload = resolve;
      image.onerror = reject;
    });
  };

  testRound = () => {
    const { match, history } = this.props;
    axios
      .put(`${BASE_URL}${PATH}assessments/${this.props.match.params.id}/start/`)
      .then((res) => {
        if (res.data.success) {
          const testId = parse(this.props.location.search)['test'];

          history.replace(`/practice-run/9${match.params.id}/?test=${testId}`);
        }
      });
  };

  render() {
    const { assessment, t } = this.props;
    if (!assessment) {
      return <Loader />;
    }

    const nextIcon = (
      <i className="fas fa-chevron-right direction-icons direction-icons-big"></i>
    );
    const prevIcon = (
      <i className="fas fa-chevron-left direction-icons direction-icons-big"></i>
    );

    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <Helmet>
          <style>
            {`
              .carousel-indicators {
                bottom: 23px;
              }
            `}
          </style>
        </Helmet>
        <Link
          to={`/game-assessments/${assessment.parent_id}`}
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back to all game based assessments')}
        </Link>
        <div className="text-align-start">
          <div>
            <h1 className="content-title startTest">
              {t('You are about to start the game')}{' '}
              <b>{t(assessment.test.title)}</b>
            </h1>
            <p className="content-text-assessment game-info-text">
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Correctly sort as many items as possible in the time available',
                  ),
                }}
              />
              <br />
              <br />
              <b>{t('Instructions')}: </b>
              <br />•{' '}
              {t(
                'Use the left and right arrow on your keyboard to sort the item by matching to the left or right image',
              )}
              <br />• {t('Only use the arrows on your keyboard')}
              <br />• {t('This is a timed assessment')}
              <br />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: t('Time minutes', { minutes: 1 }),
                }}
              />
              <br />
              {t(
                'You should aim to get as many answers correct as possible in the time allocated',
              )}
            </p>
            <div className="infoContainer sliderWrapper">
              <Carousel nextIcon={nextIcon} prevIcon={prevIcon} interval={null}>
                <Carousel.Item>
                  <img
                    className="d-block sliderImage"
                    src={image1}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block sliderImage"
                    src={image2}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
          <div className="d-flex justify-content-end mb-5">
            <button
              onClick={this.testRound}
              className="test-result-block-button start-practice-btn"
            >
              {t('Start practice')}
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.authUser.isLoading,
    assessment: state.assessmentsReduces.assessment,
    error: state.gameTest.gameInfoError,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchAssessmentData })(Game9Info),
);
