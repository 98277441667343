import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AsideVacancy from '../../components/Aside/AsideVacancy';
import AsideCompany from '../../components/Aside/AsideCompany';

class Vacancy extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    this.props.history.push('/vacancies/1/questionnaire-assessment');
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-9 content">
            <div className="content-wrapper">
              <Header isDashboardHeader history={this.props.history} />

              <div className="main-content-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    <div className="vacancy-main">
                      <h1 className="content-title">
                        Web Developer (Front End)
                      </h1>

                      <div className="vacancy-features">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="vacancy-feature-item">
                              <div className="icon">
                                <img
                                  src="/assets/img/money.svg"
                                  alt="Money icon"
                                />
                              </div>
                              <div className="text">
                                <h5>Salary</h5>
                                <p>£40,000 \ year</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="vacancy-feature-item">
                              <div className="icon">
                                <img src="/assets/img/briefcase.svg" alt="" />
                              </div>
                              <div className="text">
                                <h5>Job Types</h5>
                                <p>Full-time, Part-time</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="vacancy-feature-item">
                              <div className="icon">
                                <img src="/assets/img/calendar.svg" alt="" />
                              </div>
                              <div className="text">
                                <h5>Date</h5>
                                <p>8 days ago</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h3 className="vacancy-subtitle">Summary</h3>
                      <p className="vacancy-text">
                        After customers purchase our products, you’re the one
                        who helps them get more out of their new Apple
                        technology. Your day in the Apple Store is filled with a
                        range of focused support and service tasks. Whether
                        you’re helping customers get started with the Mac or
                        finding answers to their questions about other Apple
                        devices, you’re ready to share knowledge and provide
                        exceptional assistance. You gain satisfaction from
                        bringing resolution and insight to each customer,
                        elevating his or her relationship with Apple to the next
                        level.
                      </p>
                      <h3 className="vacancy-subtitle">Description</h3>
                      <p className="vacancy-text">
                        As a Technical Specialist, you help new owners get
                        started and current ones get quick, efficient support —
                        developing strong, positive relationships with Apple.
                        When a customer needs assistance, you quickly assess
                        their situation. Sometimes you take care of customers
                        with advice or a solution on the spot, using your
                        knowledge of current Apple technology to help with iPod,
                        iPhone, and iPad devices. At other times, you refer
                        customers to support team members who get them up and
                        running again. You even provide personal training for
                        new customers, helping them acquire the basic skills
                        they need to get started on photo, video, and music
                        projects. The entire store team benefits from your
                        commitment to providing the best care for customers. By
                        helping Apple maintain strong relationships with
                        customers, you are instrumental to our success.
                      </p>
                      <h3 className="vacancy-subtitle">Key Qualifications</h3>
                      <p className="vacancy-text last">
                        After customers purchase our products, you’re the one
                        who helps them get more out of their new Apple
                        technology. Your day in the Apple Store is filled with a
                        range of focused support and service tasks. Whether
                        you’re helping customers get started with the Mac or
                        finding answers to their questions about other Apple
                        devices, you’re ready to share knowledge and provide
                        exceptional assistance.
                      </p>

                      <button
                        onClick={this.handleSubmit}
                        className="tr8s-button vacancy-btn"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Footer isDashboardFooter />
            </div>
          </div>

          <div className="col-lg-3 d-none d-lg-block">
            <div className="aside-wrapper">
              <button
                style={{ marginBottom: '46px' }}
                className="tr8s-button green"
                onClick={this.handleSubmit}
              >
                Apply
              </button>

              <AsideVacancy
                vacancyName="Web Developer (Front End)"
                vacancyLink="#"
              />

              <AsideCompany
                companyName="FedEx Company"
                companyLogo="/assets/img/fedex-logo.png"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Vacancy;
