import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Loader from '../Loader/Loader';

class Main extends Component {
  render() {
    const token = localStorage.getItem('token');
    if (!!token) {
      return <Redirect to="/dashboard" />;
    } else if (!token) {
      return <Redirect to="/login" />;
    }
    return <Loader />;
  }
}

export default Main;
