import {
  GET_AUTH_USER_REQUEST,
  GET_AUTH_USER_SUCCESS,
  GET_AUTH_USER_FAILURE,
  USER_PROFILE_INFO_REQUEST,
  USER_PROFILE_INFO_SUCCESS,
  USER_PROFILE_INFO_FAILURE,
  LOGIN_WITH_TOKEN_REQUEST,
  LOGIN_WITH_TOKEN_SUCCESS,
  LOGIN_WITH_TOKEN_FAILURE,
  CHANGE_USER_PROFILE_INFO_REQUEST,
  CHANGE_USER_PROFILE_INFO_SUCCESS,
  CHANGE_USER_PROFILE_INFO_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  LOGUOT_SUCCESS,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: false,
  signupError: null,
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case GET_AUTH_USER_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case GET_AUTH_USER_SUCCESS: {
      return {
        ...state,
        userId: payload.userId,
        error: null,
        GET_AUTH_USER_SUCCESS: null,
      };
    }
    case GET_AUTH_USER_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case USER_PROFILE_INFO_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case USER_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        userInfo: payload.userInfo,
      };
    }
    case USER_PROFILE_INFO_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case CHANGE_USER_PROFILE_INFO_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case CHANGE_USER_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...payload.userInfo,
          profile_updated: true,
        },
        errorUpdate: null,
        error: null,
        status: true,
      };
    }
    case CHANGE_USER_PROFILE_INFO_FAILURE: {
      return {
        ...state,
        errorUpdate: payload.errorUpdate,
      };
    }
    case LOGIN_WITH_TOKEN_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case LOGIN_WITH_TOKEN_SUCCESS: {
      return {
        ...state,
        userData: payload.userData,
      };
    }
    case LOGIN_WITH_TOKEN_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case SIGN_UP_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        userData: payload.userId,
      };
    }
    case SIGN_UP_FAILURE: {
      return {
        ...state,
        signupError: payload.error,
      };
    }
    case LOGUOT_SUCCESS: {
      return {};
    }
    default: {
      return state;
    }
  }
}
