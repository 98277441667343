import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchUserData } from '../../actions/userPersonalInfo';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import setToken from '../../setToken';
import { withTranslation } from 'react-i18next';

class PaymentPaypalSuccess extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { fetchUserData, history, userInfo } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      if (!userInfo) {
        fetchUserData();
      }
    } else if (!token) {
      history.push('/login');
    }
  }

  render() {
    const { userInfo, match, t } = this.props;
    if (!userInfo) {
      return <Loader />;
    }

    return (
      <PageWrapper history={this.props.history} isDashboardHeader match={match}>
        <div className="container m-auto">
          <div className="row my-4">
            <div className="col-12">
              <div className="text-center">
                <p className="auth-form-title" style={{ marginTop: 0 }}>
                  {t('Great You are now ready to start the Assessment')}
                </p>
                <p className="auth-form-text">
                  {t(
                    'You will receive further instructions on the next page before you begin',
                  )}
                </p>
                <Link className="tr8s-button green theme-background" to="/">
                  {t('Lets get started')}
                </Link>
                {/* <Link className="tr8s-button green theme-background" to="/jobs">Not ready, go back</Link> */}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.authUser.userInfo,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchUserData })(PaymentPaypalSuccess),
);
