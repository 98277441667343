import React from 'react';
import { useTranslation } from 'react-i18next';

const PersonalityFormMobile = ({
  questions,
  handleChangeRadio,
  isTie = false,
}) => {
  const { t } = useTranslation();
  const scalesLength = questions.results[0].scales.length;

  return (
    <>
      {questions.results[0].options.map((option, itemIndex) => {
        const isSomeChecked = questions.results[0].scales.some(
          (item, itemIdx) =>
            item.id === questions.results[0].options[itemIndex]['value'],
        );

        return (
          <div key={option.id} className="option-wrapper-mobile">
            <p className="option-title-mobile">{t(option.text)}</p>
            <div className="d-flex justify-content-between">
              {questions.results[0].scales.map((item, itemIdx) => {
                const isChecked =
                  item.id === questions.results[0].options[itemIndex].value;
                const isDisabled = questions.results[0].options.some(
                  (option) => option?.value === item.id,
                );

                return (
                  <div
                    key={item.id}
                    className="d-flex flex-column align-items-center flex-shrink-0 flex-grow-0"
                    style={{
                      width: `calc(100% / ${scalesLength})`,
                      maxWidth: `calc(100% / ${scalesLength})`,
                    }}
                  >
                    <label className="checkbox-container pl-0">
                      <input
                        type="checkbox"
                        id={`${option.id}+${item.id}`}
                        value={item.value}
                        onChange={() => handleChangeRadio(item, itemIndex)}
                        name={`${option.id}`}
                        checked={isChecked}
                        className={
                          isDisabled && isTie ? 'disabled-checkmark' : ''
                        }
                      />
                      <span className="checkmark radio">{itemIdx + 1}</span>
                    </label>
                    <p
                      className="option-scale-text-mobile"
                      style={{
                        display: `${
                          isChecked ||
                          (!isSomeChecked &&
                            (itemIdx === 0 || itemIdx === scalesLength - 1))
                            ? 'block'
                            : 'none'
                        }`,
                      }}
                    >
                      {t(item.label)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PersonalityFormMobile;
