import clsx from 'clsx';
import React from 'react';
import { StepProps } from '../../models';
import Stepper from '../Stepper';

export interface BaseLayoutProps
  extends Pick<StepProps, 'stepLabels' | 'stepIndex'> {
  back: React.ReactNode;
  className?: string;
}

const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  return (
    <div className="text-align-start">
      {props.back}
      <Stepper stepLabels={props.stepLabels} stepIndex={props.stepIndex} />
      <div className={clsx('flex-grow-1 d-flex flex-wrap', props.className)}>
        {props.children}
      </div>
    </div>
  );
};

export default BaseLayout;
