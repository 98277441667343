import React, { Component } from 'react';
import { connect } from 'react-redux';
import { REF_ID } from '../../constants/variables';
import { withRouter } from 'react-router-dom';
import { refCompanyAction } from '../../actions/refCompanyAction';
import SignupForm from '../../components/Auth/SignupForm';
import Loader from '../../components/Loader/Loader';
import ls from 'local-storage';
import PageWrapper from '../../components/PageWrapper';

class SignUp extends Component {
  state = {
    data: false,
  };
  async componentDidMount() {
    const { refCompanyAction } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    let ref_id = REF_ID;
    if (query.get('ref_id')) {
      ref_id = query.get('ref_id');
    } else if (ls.get('refCompanyData')) {
      ref_id = ls.get('refCompanyData').ref_id;
    } else {
      ref_id = REF_ID;
    }
    refCompanyAction(ref_id, '');
  }

  render() {
    if (typeof this.props.refCompData === 'undefined') {
      return <Loader />;
    }
    if (!this.props.refCompData.allow_signup) {
      this.props.history.push('/login');
    }
    return (
      <PageWrapper
        isDashboardHeader={false}
        logoURL={this.props.refCompData.logo}
        logoAlt={this.props.refCompData.name}
      >
        <div className="container m-auto">
          <div className="row">
            <div className="col-12">
              <SignupForm
                history={this.props.history}
                valid_domains={this.props.refCompData.valid_domains}
              />
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refCompLoading: state.refCompanyReducer.refCompLoading,
    refCompData: state.refCompanyReducer.refCompData,
  };
};

export default withRouter(
  connect(mapStateToProps, { refCompanyAction })(SignUp),
);
