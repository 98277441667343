import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../components/PageWrapper';
import { withTranslation } from 'react-i18next';

class TermsofService extends Component {
  render() {
    const { history, t } = this.props;
    return (
      <>
        <Helmet>
          <style>{`
            .header.dashboard-header .d-flex.align-items-center {
              visibility: hidden;
            }
            `}</style>
        </Helmet>
        <PageWrapper isDashboardHeader history={history} isDashboardFooter>
          <div className="main-content-wrapper w-100">
            <div className="row">
              <div className="col-md-12">
                <br />

                <div
                  dangerouslySetInnerHTML={{
                    __html: t('privacyPolicyDescription'),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </PageWrapper>
      </>
    );
  }
}

export default withTranslation()(TermsofService);
