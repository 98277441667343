import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPasswordSuccessForm = () => {
  const { t } = useTranslation();

  return (
    <div className="auth-form-container text-center">
      <p className="auth-form-title">{t('Reset Password Success')}</p>
      <p className="auth-form-text">
        {t('Thank You. Your password has been successfully updated')}
        <br />
        {t('Please use updated password to login')}
      </p>
      <Link className="tr8s-button green theme-background" to="/login">
        {t('Continue to Login')}
      </Link>
    </div>
  );
};

export default ResetPasswordSuccessForm;
