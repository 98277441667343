import axios from 'axios';
import {
  CAREER_DRIVERS,
  CAREER_DRIVERS_SUCCESS,
  CAREER_DRIVERS_ERROR,
  CHANGE_STORE,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const careerDriverRequest = (isLoading) => {
  return {
    type: CAREER_DRIVERS,
    payload: {
      isLoading,
    },
  };
};

// const careerDriverSuccess = (assessment, tasks, columns, columnOrder) => {
//     return {
//         type: CAREER_DRIVERS_SUCCESS,
//         payload: {
//             assessment,
//             tasks,
//             columns,
//             columnOrder
//         }
//     };
// };

const careerDriverSuccess = (data) => {
  return {
    type: CAREER_DRIVERS_SUCCESS,
    payload: data,
  };
};

const careerDriverFailure = (error) => {
  return {
    type: CAREER_DRIVERS_ERROR,
    payload: {
      error,
    },
  };
};

const cahngeStore = (newState) => {
  return {
    type: CHANGE_STORE,
    payload: {
      newState,
    },
  };
};

export const сareerDriverFetchData = (testID, callBack) => {
  return async (dispatch) => {
    dispatch(careerDriverRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios(`${BASE_URL}${PATH}tests/${testID}/questions/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(careerDriverSuccess(res.data));
        callBack(res.data);
        dispatch(careerDriverRequest(false));
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(careerDriverFailure(err));
        dispatch(careerDriverRequest(false));
      });
  };
};

export const changeDriversState = (newState) => {
  return async (dispatch) => {
    dispatch(cahngeStore(newState));
  };
};
