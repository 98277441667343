import axios from 'axios';
import { APPLY_JOB_FAILURE, APPLY_JOB_REQUEST } from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const applyToJobRequest = (isApplying) => {
  return {
    type: APPLY_JOB_REQUEST,
    payload: {
      isApplying,
    },
  };
};

const applyToJobFailure = (error) => {
  return {
    type: APPLY_JOB_FAILURE,
    payload: {
      error,
    },
  };
};

export const applyToJob = (jobId, transactionId, callBack, history = '') => {
  return async (dispatch) => {
    dispatch(applyToJobRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios
      .post(`${BASE_URL}${PATH}applications/`, {
        job_id: jobId,
        transaction_id: transactionId,
      })
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        callBack(res.data);
        dispatch(applyToJobRequest(false));
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(applyToJobFailure(err));
        dispatch(applyToJobRequest(false));
      });
  };
};
