import axios from 'axios';
import {
  GET_ASSESSMENT_CACHED_ANSWERS_FAILURE,
  GET_ASSESSMENT_CACHED_ANSWERS_REQUEST,
  GET_ASSESSMENT_CACHED_ANSWERS_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const getAssessmentCachedAnswersRequest = (loading) => {
  return {
    type: GET_ASSESSMENT_CACHED_ANSWERS_REQUEST,
    payload: {
      loading,
    },
  };
};

const getAssessmentCachedAnswersSuccess = (data) => {
  return {
    type: GET_ASSESSMENT_CACHED_ANSWERS_SUCCESS,
    payload: {
      data,
    },
  };
};

const getAssessmentCachedAnswersFailure = (error) => {
  return {
    type: GET_ASSESSMENT_CACHED_ANSWERS_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchAssessmentCachedAnswers = (assessmentId, callback) => {
  return async (dispatch) => {
    dispatch(getAssessmentCachedAnswersRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    axios(
      `${BASE_URL}${PATH}answers/retrieve_answers_cache/?assessment_id=${assessmentId}`,
    )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(getAssessmentCachedAnswersSuccess(res.data.data));
        dispatch(getAssessmentCachedAnswersRequest(false));
      })
      .catch((err) => {
        if (err.response !== undefined) {
          unauthorized(err.response.status);
        }
        dispatch(getAssessmentCachedAnswersFailure(err));
        dispatch(getAssessmentCachedAnswersRequest(false));
      });
  };
};
