import {
  GET_GAME_TEST_REQUEST,
  GET_GAME_TEST_SUCCESS,
  GET_GAME_TEST_FAILURE,
  GET_GAME_INFO_REQUEST,
  GET_GAME_INFO_SUCCESS,
  GET_GAME_INFO_FAILURE,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  games: [],
};

export default function gameInfoReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case GET_GAME_TEST_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case GET_GAME_TEST_SUCCESS: {
      return {
        ...state,
        games: payload.games,
      };
    }
    case GET_GAME_TEST_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case GET_GAME_INFO_REQUEST: {
      return {
        ...state,
        gameInfoLoading: payload.gameInfoLoading,
      };
    }
    case GET_GAME_INFO_SUCCESS: {
      return {
        ...state,
        gameInfoData: payload.gameInfoData,
      };
    }
    case GET_GAME_INFO_FAILURE: {
      return {
        ...state,
        gameInfoError: payload.gameInfoError,
      };
    }
    default: {
      return state;
    }
  }
}
