import axios from 'axios';
import React, { useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
// import PaypalExpressBtn from 'react-paypal-express-checkout';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { deleteVacancy } from '../../actions/deleteVacncyAction';
import {
  createFilterJob,
  fetchJobsByCompanyId,
} from '../../actions/getJobsByCompanyId';
import { reactivateData } from '../../actions/reactivateAction';
import { fetchUserData } from '../../actions/userPersonalInfo';
import { fetchVacanciesData } from '../../actions/vacancies';
import Loader from '../../components/Loader/Loader';
import ModalDelete from '../../components/ModalDeleteVacancy';
import PageWrapper from '../../components/PageWrapper';
import {
  BASE_URL,
  PATH,
  // PAYPAL_CURRENCY,
  // PAYPAL_ENV,
  // PAYPAL_PRODUCTION_KEY,
  // PAYPAL_SANDBOX_KEY,
} from '../../constants/variables';
import setToken from '../../setToken';
import './ProfileTestResults.scss';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { QuestionType } from '../../models/assessments';
import { applyToJob } from '../../actions/applyToJobAction';
import { setCurrentApplicationId } from '../../actions/assessmentAction';

function AssessmentActionButton({ id, label, action }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <button
      className="test-result-block-button"
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        dispatch(setCurrentApplicationId(id));
        await action();
        setLoading(false);
      }}
    >
      {loading ? <i class="fas fa-spinner fa-spin"></i> : label}
    </button>
  );
}

class ProfileTestResults extends React.Component {
  state = {
    isOpenedModal: false,
    isLoading: false,
  };

  componentDidMount() {
    const {
      fetchUserData,
      fetchVacanciesData,
      history,
      userInfo,
      fetchJobsByCompanyId,
    } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      if (!userInfo) {
        fetchUserData(history);
      } else if (userInfo && !userInfo.consent_provided) {
        history.push(`/personal-information`);
      } else if (!!userInfo) {
        fetchJobsByCompanyId(userInfo.id, (jobs) => {
          if (jobs.length > 0) {
            this.applyJob(jobs[0].id);
          }
        });
      }
      fetchVacanciesData(history);
    } else if (!token) {
      history.push('/login');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userInfo?.id !== this.props.userInfo?.id &&
      this.props.userInfo?.id
    ) {
      this.props.fetchJobsByCompanyId(this.props.userInfo.id, (jobs) => {
        if (jobs.length > 0) {
          this.applyJob(jobs[0].id);
        }
      });
    }
  }

  handleStartTest = async (id) => {
    this.props.history.push(`/assessments/${id}`);
  };

  handleStartTestWithStatusChange = async (id) => {
    return await axios
      .put(`${BASE_URL}${PATH}applications/${id}/start/`)
      .then((res) => {
        if (res.data.success) {
          this.props.history.push(`/assessments/${id}`);
        }
      })
      .catch(() => {});
  };

  deleteAssessment = (id) => {
    this.setState({ isOpenedModal: true, deletedId: id });
  };

  onCancel = () => {
    this.setState({ isOpenedModal: false });
  };

  onDelete = () => {
    this.setState({ isLoading: true });
    const { deleteVacancy } = this.props;
    const { deletedId } = this.state;
    deleteVacancy(
      deletedId,
      this.props.fetchVacanciesData,
      () =>
        this.setState({
          isLoading: false,
        }),
      this.props.history,
    );
    this.setState({ isOpenedModal: false });
  };

  viewDetaild = (vac) => {
    const { history } = this.props;
    history.push(`/assessments/${vac.id}`);
  };

  reactivateVacancy = (id) => {
    const { reactivateData } = this.props;
    reactivateData(
      id,
      this.props.fetchVacanciesData,
      () =>
        this.setState({
          isLoading: false,
        }),
      this.props.history,
    );
  };

  applyJob = (jobId, transactionId = '') => {
    const {
      applyToJob,
      fetchVacanciesData,
      history,
      fetchJobsByCompanyId,
      userInfo,
    } = this.props;
    applyToJob(
      jobId,
      transactionId,
      (application) => {
        fetchVacanciesData(history);
        fetchJobsByCompanyId(userInfo.id);
      },
      this.props.history,
    );
  };

  render() {
    const { isOpenedModal } = this.state;
    const {
      isLoading,
      isApplying,
      userInfo,
      isLoadingVacancies,
      vacancies,
      history,
      match,
      jobList,
      t,
    } = this.props;
    if (
      isLoading ||
      isApplying ||
      !userInfo ||
      isLoadingVacancies ||
      !vacancies ||
      !jobList ||
      this.state.isLoading
    ) {
      return <Loader />;
    }

    const direction = localStorage.getItem('contentDirection');

    // const onSuccess = (payment) => {
    //   let retUrl = payment.returnUrl.split('?');
    //   let jobId = retUrl[0].substr(retUrl[0].lastIndexOf('/') + 1);
    //   this.applyJob(jobId, payment.paymentID);
    // };

    // const onCancel = (data) => {
    //   console.log('The payment was cancelled!', data);
    // };

    // const onError = (err) => {
    //   console.log('Error!', err);
    // };

    // let env = PAYPAL_ENV; // you can set here to 'production' for production
    // let currency = PAYPAL_CURRENCY; // or you can set this value from your props or state
    //let total = 1; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout

    // const client = {
    //   sandbox: PAYPAL_SANDBOX_KEY,
    //   production: PAYPAL_PRODUCTION_KEY,
    // };

    return (
      <>
        {isOpenedModal && (
          <ModalDelete
            onCancel={this.onCancel}
            onDelete={this.onDelete}
            text={t(
              'Are you sure you want to archive this assessment? By clicking yes you will not be able to recover the data and the assessment will be permanently archived',
            )}
            bntCancel={t('Cancel')}
            btnSuccess={t('Yes please archive')}
          />
        )}
        <PageWrapper
          history={history}
          isDashboardHeader
          match={match}
          isDashboardFooter
        >
          <div className="main-content-wrapper w-100 text-align-start">
            <p className="test-result-title">
              {t('Welcome name', { name: userInfo.first_name })}
            </p>

            <h3 className="result-subtitle">{t('Jobs')}</h3>
            <div className="whitebox__card-row">
              {!!jobList?.length &&
                jobList.map((job) => (
                  <div
                    key={job.id}
                    className={`whitebox__card-wrapper ${
                      direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                    }`}
                  >
                    <div className="whitebox__card jobCard">
                      <p className="whitebox__card-title position">
                        {job.title}
                      </p>
                      <p className="companyName">{job.location}</p>
                      <div className="test-result-block-button-wrapper">
                        <button
                          className="test-result-block-button"
                          onClick={() => this.applyJob(job.id, '')}
                        >
                          {t('Apply')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              {!!jobList?.length ? null : (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                  }}
                />
              )}
            </div>

            {vacancies.pending.length > 0 || vacancies.started.length > 0 ? (
              <p className="test-result-subtitle">{t('Pending')}</p>
            ) : null}
            <div className="whitebox__card-row">
              {vacancies.pending.length > 0 &&
                vacancies.pending.map((vac, i) => {
                  return (
                    <div
                      key={vac.id}
                      className={`whitebox__card-wrapper ${
                        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                      }`}
                    >
                      <div className="whitebox__card jobCard">
                        <img
                          className="test-result-block-img"
                          src={`${vac.job.company.logo}`}
                          alt=""
                        />
                        <p className="whitebox__card-title position">
                          {vac.job.title}
                        </p>
                        <p className="companyName">{vac.job.location}</p>
                        <div className="progressBar">
                          <CircularProgressbar
                            // value={vac.progress[0]}
                            // maxValue={vac.progress[1]}
                            value={vac.completed_assessments}
                            maxValue={vac.total_assessments}
                            strokeWidth={25}
                            styles={{
                              root: {
                                width: '33px',
                              },
                              path: {
                                stroke: `#27A4A1`,
                              },
                            }}
                          />
                          <div
                            className={`${
                              direction === CONTENT_DIRECTION.RTL
                                ? 'mr-2'
                                : 'ml-2'
                            }`}
                          >
                            <p className="progressText">{t('Progress')}</p>
                            <p className="progressStatus text-align-start">
                              {vac.completed_assessments}/
                              {vac.total_assessments}
                            </p>
                          </div>
                        </div>
                        <div className="test-result-block-button-wrapper">
                          {vac.expired ? (
                            <span className="test-result-block-button">
                              {t('Expired')}
                            </span>
                          ) : (
                            <AssessmentActionButton
                              id={vac.id}
                              label={t('Continue')}
                              action={async () =>
                                await this.handleStartTest(vac.id)
                              }
                            />
                          )}
                          {/* {downloadFlag ? (
                                <button
                                  className="test-result-block-button-delete"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/assessment/${vac.job.id}/report`
                                    )
                                  }
                                >
                                  <i className="fas fa-download"></i>
                                </button>
                              ) : null} */}
                          <button
                            className="test-result-block-button-delete"
                            onClick={() => this.deleteAssessment(vac.id)}
                          >
                            <i className="fas fa-archive"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {vacancies.started.length > 0 &&
                vacancies.started.map((vac, i) => (
                  <div
                    key={vac.id}
                    className={`whitebox__card-wrapper ${
                      direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                    }`}
                  >
                    <div className="whitebox__card jobCard">
                      <img
                        className="test-result-block-img"
                        src={`${vac.job.company.logo}`}
                        alt=""
                      />
                      <p className="whitebox__card-title position">
                        {vac.job.title}
                      </p>
                      <p className="companyName">{vac.job.location}</p>
                      <div className="progressBar">
                        <CircularProgressbar
                          // value={vac.completed_assessments}
                          // maxValue={vac.total_assessments}
                          value={0}
                          maxValue={4}
                          strokeWidth={25}
                          style={{
                            root: {
                              width: '33px',
                            },
                            path: {
                              stroke: `#27A4A1`,
                            },
                          }}
                        />
                        <div
                          className={`${
                            direction === CONTENT_DIRECTION.RTL
                              ? 'mr-2'
                              : 'ml-2'
                          }`}
                        >
                          <p className="progressText">{t('Not Started')}</p>
                          <p className="progressStatus text-align-start">
                            {vac.completed_assessments}/{vac.total_assessments}
                          </p>
                        </div>
                      </div>
                      <div className="test-result-block-button-wrapper">
                        {vac.expired ? (
                          <span className="test-result-block-button">
                            {t('Expired')}
                          </span>
                        ) : (
                          <AssessmentActionButton
                            id={vac.id}
                            label={t('Start')}
                            action={async () =>
                              await this.handleStartTestWithStatusChange(vac.id)
                            }
                          />
                        )}
                        <button
                          className="test-result-block-button-delete"
                          onClick={() => this.deleteAssessment(vac.id)}
                        >
                          <i className="fas fa-archive"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {vacancies.completed.length > 0 ? (
              <p className="test-result-subtitle">{t('Completed')}</p>
            ) : null}
            <div className="whitebox__card-row">
              {vacancies.completed.length > 0 &&
                vacancies.completed.map((vac) => {
                  let downloadFlag = false;
                  vac.assessments.forEach((item) => {
                    if (
                      item.test.type.id === QuestionType.Personality &&
                      item.status === 2
                    ) {
                      downloadFlag = true;
                    }
                  });

                  return (
                    <div
                      key={vac.id}
                      className={`whitebox__card-wrapper ${
                        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                      }`}
                    >
                      <div className="completedCard whitebox__card jobCard shadow-none">
                        <img
                          className="test-result-block-img"
                          src={`${vac.job.company.logo}`}
                          alt=""
                        />
                        <p className="whitebox__card-title position">
                          {vac.job.title}
                        </p>
                        <p className="companyName">{vac.job.location}</p>
                        <div
                          style={{
                            justifyContent: 'flex-end',
                          }}
                          className="viewButton"
                        >
                          {(!userInfo.is_employee ||
                            vac.job.can_employee_view_results) &&
                          downloadFlag ? (
                            <button
                              className="test-result-block-button"
                              onClick={() =>
                                this.props.history.push(
                                  `/assessment/${vac.id}/report`,
                                )
                              }
                            >
                              {t('View Results')}
                              {/* <i className="fas fa-download"></i> */}
                            </button>
                          ) : null}

                          {/* <button
                                className="viewDetailButton"
                                onClick={() => this.viewDetaild(vac)}
                              >
                                View Details
                              </button> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {vacancies.deleted.length > 0 && vacancies.deleted.length > 0 ? (
              <p className="test-result-subtitle">{t('Archived')}</p>
            ) : null}
            <div className="whitebox__card-row">
              {vacancies.deleted.map((vac) => (
                <div
                  key={vac.id}
                  className={`whitebox__card-wrapper ${
                    direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                  }`}
                >
                  <div className="completedCard whitebox__card jobCard shadow-none">
                    <p className="deletedMark">{t('Archived')}</p>
                    <img
                      className="test-result-block-img"
                      src={`${vac.job.company.logo}`}
                      alt=""
                    />
                    <p className="whitebox__card-title position">
                      {vac.job.title}
                    </p>
                    <p className="companyName">{vac.job.location}</p>
                    <div className="viewButton">
                      <button
                        className="viewDetailButton"
                        onClick={() => this.reactivateVacancy(vac.id)}
                      >
                        {t('Reactivate')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-5" />
          </div>
        </PageWrapper>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    jobList: state.getJobsByCompanyId.jobs,
    userInfo: state.authUser.userInfo,
    isLoading: state.authUser.isLoading,
    isLoadingVacancies: state.vacanciesReducer.isLoading,
    isApplying: state.applyToJobReducer.isApplying,
    vacancies: state.vacanciesReducer.vacancies,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchUserData,
    fetchVacanciesData,
    deleteVacancy,
    reactivateData,
    fetchJobsByCompanyId,
    filterJob: createFilterJob,
    applyToJob,
  })(ProfileTestResults),
);
