export const Game9 = {
  is_timer_countdown: true,
  timer_seconds: 600,
  games: {
    right: { id: 1, image: '/assets/img/dim1.svg' },
    left: { id: 2, image: '/assets/img/dim2.svg' },
    options: [
      { id: 1, image: '/assets/img/dim1.svg', answer: 'l' },
      { id: 2, image: '/assets/img/dim2.svg', answer: 'r' },
      { id: 3, image: '/assets/img/dim1.svg', answer: 'l' },
      { id: 4, image: '/assets/img/dim2.svg', answer: 'r' },
      { id: 5, image: '/assets/img/dim1.svg', answer: 'l' },
      { id: 6, image: '/assets/img/dim2.svg', answer: 'r' },
      { id: 7, image: '/assets/img/dim1.svg', answer: 'l' },
      { id: 8, image: '/assets/img/dim2.svg', answer: 'r' },
      { id: 9, image: '/assets/img/dim1.svg', answer: 'l' },
      { id: 10, image: '/assets/img/dim2.svg', answer: 'r' },
      { id: 11, image: '/assets/img/dim1.svg', answer: 'l' },
      { id: 12, image: '/assets/img/dim2.svg', answer: 'r' },
    ],
  },
};
