import axios from 'axios';
import {
  SUBMIT_ROUND,
  SUBMIT_ROUND_ERROR,
  SUBMIT_ROUND_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import { fetchGetNextRoundData } from './getNextRoundAction';

function submitRound(loading) {
  return {
    type: SUBMIT_ROUND,
    payload: { loading },
  };
}

function submitRoundSuccess({ end }) {
  return {
    type: SUBMIT_ROUND_SUCCESS,
    payload: {
      end,
    },
  };
}

function submitRoundError(error) {
  return {
    type: SUBMIT_ROUND_ERROR,
    payload: { error },
  };
}

export const fetchSubmitRoundGameData = (
  game,
  data,
  history,
  assessmentsId,
  qId,
  end,
  round,
  testId,
) => {
  const dataToPost = [
    {
      assessment: game,
      question: qId,
      data: data,
    },
  ];
  // console.log('this is test for submit game3', dataToPost);

  return async (dispatch) => {
    dispatch(submitRound(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios
      .post(`${BASE_URL}${PATH}answers/`, dataToPost)
      .then((res) => {
        if (res.status !== 201) {
          throw Error(res.statusText);
        }
        dispatch(submitRound(false));
        return res;
      })
      .then((res) => {
        dispatch(submitRoundSuccess({ success: true }));
        if (end === 0) {
          dispatch(
            fetchGetNextRoundData(
              testId,
              {
                game_id: data.game_id,
                timestamp: data.timestamp,
              },
              history,
              round + 1,
              assessmentsId,
            ),
          );
        }
        if (end === 1) {
          localStorage.removeItem(`${data.game_id}`);
          axios
            .put(`${BASE_URL}${PATH}assessments/${game}/complete/`)
            .then((res) => {
              if (res.status === 200) {
                history.replace(`/assessments/${game}/games-completed`);
              }
            });
        }
      })
      .catch((err) => dispatch(submitRoundError(err)));
  };
};
