import axios from 'axios';
import {
  GET_QUESTION_ASSESSMENTS_FAILURE,
  GET_QUESTION_ASSESSMENTS_REQUEST,
  GET_QUESTION_ASSESSMENTS_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const getQuestionAssessmentsRequest = (isLoading) => {
  return {
    type: GET_QUESTION_ASSESSMENTS_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const getQuestionAssessmentsSuccess = (question) => {
  return {
    type: GET_QUESTION_ASSESSMENTS_SUCCESS,
    payload: {
      question,
    },
  };
};

const getQuestionAssessmentsFailure = (error) => {
  return {
    type: GET_QUESTION_ASSESSMENTS_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchQuestionData = (
  assessmentId,
  page,
  history,
  callBack,
  page_size = 0,
) => {
  return async (dispatch) => {
    dispatch(getQuestionAssessmentsRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    let query = `${BASE_URL}${PATH}tests/${assessmentId}/questions/?page=${
      page + 1
    }`;
    if (page_size) {
      query = query + '&page_size=' + page_size;
    }
    await axios(query)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        if (res.status === 400) {
          history.push(`/assessment-error/${assessmentId}`);
        }
        return res;
      })
      .then((res) => {
        callBack(res.data, page);
        dispatch(getQuestionAssessmentsSuccess(res.data));
        dispatch(getQuestionAssessmentsRequest(false));
      })
      .catch((err) => {
        if (err.response !== undefined) {
          unauthorized(err.response.status);
        }
        dispatch(getQuestionAssessmentsFailure(err));
        dispatch(getQuestionAssessmentsRequest(false));
      });
  };
};
