import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpPopUp = ({ status, game, type }) => {
  const { t } = useTranslation();

  let correct,
    incorrect = '';

  if (game === 'Path Finder') {
    correct = t('Well done you followed the path');
    incorrect = t(
      'Path was not followed the correct path is shown on the grid',
    );
  }
  if (game === 'Shapes') {
    correct = t('Well done you selected the right shape');
    incorrect = t('That is incorrect the right shape is shown in green');
  }
  if (game === 'Rotate It') {
    correct = t('Well done you selected the right rotated shape');
    incorrect = t('That is incorrect the right shape is shown in green');
  }
  if (game === 'Swiped') {
    correct = t('Well done you selected the right rotated pattern');
    incorrect = t('That is incorrect the right shape is shown in green');
  }
  if (game === 'Matched') {
    if (type === 'picture') {
      correct = t('Well done that is the correct answer');
      incorrect = t('That is incorrect the meaning does not match the picture');
    } else if (type === 'ink_color') {
      correct = t('Well done that is the correct answer');
      incorrect = t(
        'That is incorrect the meaning does not match the ink color',
      );
    }
  }

  return (
    <div className="help-popup text-align-start">
      <div className="help-popup-header text-center">
        <div className="triangle-topright" />
        {t('Help')}
      </div>
      <div className="help-popup-text">{status ? correct : incorrect}</div>
      <div className="help-popup-footer"></div>
    </div>
  );
};

export default HelpPopUp;
