import React, { Component, Fragment } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { parse } from 'query-string';
import Fade from '../../Fade';
import GameHeader from '../../GameHeader';
import { ReactComponent as Arrows } from '../../../assets/screen9/Arrows.svg';
import Loader from '../../Loader/Loader';
import Modal from '../../ModalDeleteVacancy';
import style from '../../game9/style.module.css';
import '../../game9/style.css';
import { Game9 } from './mock';
import PageWrapper from '../../PageWrapper';
import { withTranslation } from 'react-i18next';

class NineGame extends Component {
  state = {
    round: 0,
    tap: false,
    side: 'r',
    disabled: false,
    end: false,
  };

  componentDidMount() {
    this.setState({ data: Game9.games });
    window.addEventListener('keyup', this.sortedItemsKeyBoard);
  }

  sortedItemsKeyBoard = (e) => {
    const { data } = this.state;
    if (data.options.length > 0) {
      if (e.keyCode === 37) {
        this.sortedItems('r');
      }
      if (e.keyCode === 39) {
        this.sortedItems('l');
      }
    }
  };

  continueGameModal = () => {
    this.setState({ openStopModal: false });
  };

  stopGameHandler = () => {
    this.setState({ openStopModal: true });
  };

  onCloseGameModal = () => {
    const { history, match } = this.props;
    const testId = parse(this.props.location.search)['test'];

    history.push(`/game-info/9${match.params.id}?test=${testId}`);
  };

  sortedItems = (side) => {
    this.setState({ side, tap: true, disabled: true });
    const {
      data: { options },
      data,
    } = this.state;
    if (data.options.length > 0) {
      setTimeout(() => {
        options.splice(-1, 1);
        const newData = { ...data, options };
        this.setState({ data: newData, tap: false, disabled: false });
        if (newData.options.length === 0) {
          this.setState({ end: true, disabled: true });
        }
      }, 500);
    }
  };

  startGameNew = () => {
    const { history, match } = this.props;
    const testId = parse(this.props.location.search)['test'];

    history.replace(`/game/9${match.params.id}/?test=${testId}`);
  };

  componentWillUnmount() {
    window.removeEventListener('keyup', this.sortedItemsKeyBoard);
  }

  addStyle = (vector) => {
    const { tap, side, data } = this.state;
    if (data.options.length > 0) {
      if (
        tap &&
        side &&
        Game9.games.options[data.options.length - 1].answer === side &&
        side === vector
      ) {
        return {
          color: '#27A4A1',
        };
      }
      if (
        tap &&
        side &&
        Game9.games.options[data.options.length - 1].answer !== side &&
        side === vector
      ) {
        return { color: '#d85c5d' };
      }
    }
  };

  render() {
    const { openStopModal, data, round, side, tap, disabled, end } = this.state;
    const { history, t } = this.props;
    if (!data) {
      return <Loader />;
    }

    return (
      <Fragment>
        {openStopModal && (
          <Modal
            onCancel={this.continueGameModal}
            onDelete={this.onCloseGameModal}
            text={t(
              'Are you sure want to end game? Once you exit you cannot restart the game',
            )}
            bntCancel={t('Cancel')}
            btnSuccess={t('End game')}
          />
        )}
        <PageWrapper isDashboardHeader history={history} isDashboardFooter>
          <GameHeader
            stopGameHandler={this.stopGameHandler}
            currentTask={round + 1}
            allTask={1}
            timerSeconds={null}
            canForceEnd={false}
            gameType={t('Practice Run', { title: t('Swiped') })}
          />
          <div className={style.gameFieldWrapper}>
            <p className={style.gameDescription}>
              {t(
                'Use the left and right arrow on your keyboard to sort the item by matching to the left or right image',
              )}
            </p>
            <div className={style.gameFiled} dir="ltr">
              <div
                className={style.arrowL}
                onClick={!disabled ? () => this.sortedItems('r') : null}
              >
                <Arrows
                  className={`${style.arrowIcon} ${style.leftArrow}`}
                  style={this.addStyle('r')}
                />
              </div>
              <Fade delay={400}>
                <div>
                  <TransitionGroup className="itemWrapper">
                    {data.options.map((item, i) =>
                      i >= data.options.length - 4 ? (
                        <CSSTransition
                          key={i}
                          timeout={300}
                          classNames={`item${side}`}
                        >
                          <div className={style.testItems}>
                            <img
                              src={item.image}
                              className={style[`icon${3 - i}`]}
                              alt={'itemImage'}
                            />
                          </div>
                        </CSSTransition>
                      ) : null,
                    )}
                  </TransitionGroup>
                </div>
              </Fade>
              <div
                className={style.arrowR}
                onClick={!disabled ? () => this.sortedItems('l') : null}
              >
                <Arrows
                  className={`${style.arrowIcon} ${style.rightArrow}`}
                  style={this.addStyle('l')}
                />
              </div>
            </div>
            <div className={style.bottomIcons} dir="ltr">
              <Fade delay={350}>
                <div>
                  <div className={style.iconsWrapper}>
                    <img
                      alt="leftImg"
                      src={data.left.image}
                      className={style.bottomIcon}
                    />
                  </div>
                </div>
              </Fade>
              <div className="d-flex my-0 mx-auto">
                {tap &&
                  side &&
                  Game9.games.options[data.options.length - 1].answer ===
                    side && (
                    <div className={style.truth}>
                      <i className="fas fa-check"></i>
                    </div>
                  )}
                {tap &&
                  side &&
                  Game9.games.options[data.options.length - 1].answer !==
                    side && (
                    <div className={style.incorrect}>
                      <i className="fas fa-times"></i>
                    </div>
                  )}
              </div>
              <Fade delay={350}>
                <div>
                  <div className={style.iconsWrapper}>
                    <img
                      alt="rightImg"
                      src={data.right.image}
                      className={style.bottomIcon}
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-5">
            {
              <div
                disabled={!end}
                className={`${style.buttonNext} ${end ? 'd-flex' : 'd-none'}`}
                onClick={this.startGameNew}
              >
                {t('Start Assessment')}
              </div>
            }
          </div>
        </PageWrapper>
      </Fragment>
    );
  }
}

export default withTranslation()(NineGame);
