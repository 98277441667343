import clsx from 'clsx';
import React from 'react';
import { VideoProps } from '../models';
import { noVideoRefErrorMessage, resolution } from '../utils';

const videoWrapperStyle: React.CSSProperties = {
  width: resolution.width,
  maxWidth: '100%',
};
const videoStyle: React.CSSProperties = {
  // * depends on https://caniuse.com/?search=aspect-ratio
  // aspectRatio: resolution.width / resolution.height
  height: resolution.height,
};

function play(this: HTMLVideoElement) {
  this.play();
}

/**
 * hides imperative implementation details related to srcObject
 */
const Video: React.FC<VideoProps> = ({ src, ...props }) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  React.useEffect(() => {
    // * ensures that the reference is the same
    const video = videoRef.current;

    // * video will appear blank unless it is played
    video?.addEventListener('loadedmetadata', play);

    return () => {
      video?.removeEventListener('loadedmetadata', play);
    };
  }, [videoRef]);

  React.useEffect(() => {
    if (videoRef.current) {
      if (src) {
        if (typeof src === 'string') {
          videoRef.current.srcObject = null;
          videoRef.current.src = src;
        } else if (src instanceof MediaStream) {
          videoRef.current.srcObject = src;
          videoRef.current.src = '';
        }
      }
    } else {
      console.error(noVideoRefErrorMessage);
    }
  }, [src, videoRef]);

  return (
    <div className="d-flex flex-column" style={videoWrapperStyle}>
      <div className="position-relative">
        <video
          {...props}
          ref={videoRef}
          className={clsx('d-block bg-dark w-100', props.className)}
          style={videoStyle}
        />
        {props.overlayContent && (
          <div className="video-overlay">{props.overlayContent}</div>
        )}
      </div>
      {props.footer}
    </div>
  );
};

export default Video;
