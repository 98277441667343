import { WithMediaDeviceIds } from './models';
import { resolution } from './utils';

export const assertDeviceIds = (devices: WithMediaDeviceIds) => {
  if (devices.videoDeviceId && devices.audioDeviceId) {
    return Promise.resolve(
      devices as {
        [key in keyof WithMediaDeviceIds]: NonNullable<WithMediaDeviceIds[key]>;
      },
    );
  } else {
    if (!devices.videoDeviceId && !devices.audioDeviceId) {
      const message = 'Setup failed: No video and audio devices detected.';
      alert(message);
      return Promise.reject(message);
    } else if (!devices.videoDeviceId) {
      const message = 'Setup failed: No video device detected.';
      alert(message);
      return Promise.reject(message);
    } else {
      const message = 'Setup failed: No audio device detected.';
      alert(message);
      return Promise.reject(message);
    }
  }
};

export const getUserMedia = (devices: WithMediaDeviceIds) => {
  return navigator.mediaDevices
    .getUserMedia({
      video: { deviceId: devices.videoDeviceId ?? undefined, ...resolution },
      audio: { deviceId: devices.audioDeviceId ?? undefined },
    })
    .catch((e) => {
      // TODO replace alert with a more user friendly approach
      alert(`Setup failed: ${e.message}`);
      throw e;
    });
};
