import { get } from 'loadsh';
import { parse } from 'query-string';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import { fetchEndGameData } from '../../actions/endGameAction';
import { fetchGetNextRoundData } from '../../actions/getNextRoundAction';
import { sortedItem } from '../../actions/NineGame';
import { fetchSubmitRoundGameData } from '../../actions/submitRoundAction';
import { ReactComponent as Arrows } from '../../assets/screen9/Arrows.svg';
import Fade from '../Fade';
import GameHeader from '../GameHeader';
import Loader from '../Loader/Loader';
import Modal from '../ModalDeleteVacancy';
import PageWrapper from '../PageWrapper';
import { withTranslation } from 'react-i18next';
import './style.css';
import style from './style.module.css';

class NineGame extends Component {
  state = {
    data: [],
    pizzaAnswer: [],
    potatoAnswer: [],
    items: [1, 2, 3, 4, 5],
    side: 'r',
    page: 1,
  };

  async componentDidMount() {
    this.testId = parse(this.props.location.search)['test'];
    const { fetchGetNextRoundData, match, history } = this.props;
    await fetchGetNextRoundData(
      this.testId,
      {
        game_id: match.params.id,
        timestamp: Date.now(),
      },
      history,
      1,
      9,
    );
    window.addEventListener('keyup', this.sortedItemsKeyBoard);
    this.newTimer = setInterval(this.onUnanswered, 3000);
  }

  onUnanswered = () => {
    this.setState({ side: 'b' });
    const { data } = this.state;
    setTimeout(() => {
      const {
        options,
        sortedItem,
        round,
        fetchSubmitRoundGameData,
        match,
        history,
      } = this.props;
      if (options.length > 1) {
        const [newList] = options.splice(-1, 1);
        data.push(newList);
        newList['action'] = 'NULL';
        this.setState({ data });
        sortedItem(options);
      } else if (options.length === 1) {
        const newList = options.splice(-1, 1);
        data.push(newList);
        this.setState({ data });
        sortedItem(options);
        const sendData = {
          round,
          timestamp: Date.now(),
          result: {
            options: data,
          },
        };
        fetchSubmitRoundGameData(
          match.params.id,
          sendData,
          history,
          '9',
          this.props.game_id,
          this.props.end,
          this.props.round,
          this.testId,
        );
      }
    }, 0);
  };

  sortedItemsKeyBoard = (e) => {
    if (e.keyCode === 37) {
      this.sortedItems('r');
    }
    if (e.keyCode === 39) {
      this.sortedItems('l');
    }
  };

  sortedItems = (side) => {
    this.setState({ side });
    const { data } = this.state;
    const { game_id, timer_seconds } = this.props;
    setTimeout(() => {
      const {
        options,
        sortedItem,
        round,
        fetchSubmitRoundGameData,
        match,
        history,
      } = this.props;
      if (options.length > 1) {
        const [newList] = options.splice(-1, 1);
        data.push(newList);
        if (side === 'l') {
          newList['action'] = 'right';
        } else if (side === 'r') {
          newList['action'] = 'left';
        }
        this.setState({ data });
        sortedItem(options);
      } else if (options.length === 1) {
        const newList = options.splice(-1, 1);
        data.push(newList);
        this.setState({ data });
        sortedItem(options);
        const sendData = {
          round,
          timestamp: Date.now(),
          result: {
            options: data,
          },
        };
        fetchSubmitRoundGameData(
          match.params.id,
          sendData,
          history,
          '9',
          this.props.game_id,
          this.props.end,
          this.props.round,
          this.testId,
        );
      }
    }, 0);
    clearInterval(this.newTimer);
    const localTime = +localStorage.getItem(`${game_id}`);
    const lastTime = (localTime - Date.now()) / 1000;
    if (lastTime > timer_seconds / 2) {
      this.newTimer = setInterval(this.onUnanswered, 3000);
    } else {
      this.newTimer = setInterval(this.onUnanswered, 3000);
    }
  };

  onCloseGameModal = async () => {
    const {
      game_id,
      rounds,
      history,
      match,
      round,
      fetchSubmitRoundGameData,
    } = this.props;
    const { data } = this.state;
    // if (end !== 1) {
    const sendData = {
      round,
      timestamp: Date.now(),
      result: {
        options: data,
      },
    };
    await fetchSubmitRoundGameData(
      match.params.id,
      sendData,
      history,
      '9',
      game_id,
      this.props.end,
      this.props.round,
      this.testId,
    );

    this.setState({
      openStopModal: false,
      round: rounds,
    });
  };

  continueGameModal = () => {
    this.setState({ openStopModal: false });
  };

  stopGameHandler = () => {
    this.setState({ openStopModal: true });
  };

  componentWillUnmount() {
    window.removeEventListener('keyup', this.sortedItemsKeyBoard);
    clearInterval(this.newTimer);
  }

  render() {
    const { side, openStopModal } = this.state;
    const {
      options,
      rounds,
      round,
      timer_seconds,
      history,
      left,
      right,
      t,
    } = this.props;
    if (!options.length) {
      return <Loader />;
    }
    return (
      <Fragment>
        {openStopModal && (
          <Modal
            onCancel={this.continueGameModal}
            onDelete={this.onCloseGameModal}
            text={t(
              'Are you sure want to end game? Once you exit you cannot restart the game',
            )}
            bntCancel={t('Cancel')}
            btnSuccess={t('End game')}
          />
        )}
        <PageWrapper isDashboardHeader history={history} isDashboardFooter>
          <GameHeader
            timerProgress={true}
            stopGameHandler={this.stopGameHandler}
            onTimeEnd={this.onCloseGameModal}
            currentTask={round}
            allTask={rounds}
            timerSeconds={timer_seconds}
            canForceEnd={false}
            gameType={t('Swiped')}
          />
          <div className={style.gameFieldWrapper}>
            <p className={style.gameDescription}>
              {t(
                'Use the left and right arrow on your keyboard to sort the item by matching to the left or right image',
              )}
            </p>
            <div className={style.gameFiled} dir="ltr">
              <div
                className={style.arrowL}
                onClick={() => this.sortedItems('r')}
              >
                <Arrows className={`${style.arrowIcon} ${style.leftArrow}`} />
              </div>
              <Fade delay={400}>
                <div>
                  <TransitionGroup className="itemWrapper">
                    {options.map((item, i) =>
                      i >= options.length - 4 ? (
                        <CSSTransition
                          key={i}
                          timeout={300}
                          classNames={`item${side}`}
                        >
                          <div className={style.testItems}>
                            <img
                              src={item.image}
                              className={style[`icon${3 - i}`]}
                              alt={'itemImage'}
                            />
                          </div>
                        </CSSTransition>
                      ) : null,
                    )}
                  </TransitionGroup>
                </div>
              </Fade>
              <div
                className={style.arrowR}
                onClick={() => this.sortedItems('l')}
              >
                <Arrows className={`${style.arrowIcon} ${style.rightArrow}`} />
              </div>
            </div>
            <div className={style.bottomIcons} dir="ltr">
              <Fade delay={350}>
                <div className={style.variantWrapper}>
                  <div className={style.iconsWrapper}>
                    <img
                      alt="leftImg"
                      src={left.image}
                      className={style.bottomIcon}
                    />
                  </div>
                </div>
              </Fade>
              <div className="d-flex">
                {side === 'b' && (
                  <div className="m-0">
                    <div className={style.incorrect}>
                      <i className="fas fa-times"></i>
                    </div>
                    <p className={style.lateText}>{t('Too late')}</p>
                  </div>
                )}
              </div>
              <Fade delay={350}>
                <div className={style.variantWrapper}>
                  <div className={style.iconsWrapper}>
                    <img
                      alt="rightImg"
                      src={right.image}
                      className={style.bottomIcon}
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </PageWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('state.startGameReducer',state.startGameReducer );
  return {
    loading: state.startGameReducer.loading,
    error: state.startGameReducer.error,
    game_id: state.startGameReducer.game_id,
    rounds: state.startGameReducer.rounds,
    timer_seconds: state.startGameReducer.timer_seconds,
    round: state.startGameReducer.round,
    options: get(state, ['startGameReducer', 'options'], []),
    end: state.startGameReducer.end,
    left: get(state, ['startGameReducer', 'left'], {}),
    right: get(state, ['startGameReducer', 'right'], {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGetNextRoundData,
      fetchSubmitRoundGameData,
      fetchEndGameData,
      sortedItem,
    },
    dispatch,
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NineGame),
);
