import axios from 'axios';
import {
  END_GAME,
  END_GAME_ERROR,
  END_GAME_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

function endGame(loading) {
  return {
    type: END_GAME,
    payload: { loading },
  };
}

function endGameSuccess({ end }) {
  return {
    type: END_GAME_SUCCESS,
    payload: {
      end,
    },
  };
}

function endGameError(error) {
  return {
    type: END_GAME_ERROR,
    payload: { error },
  };
}

export const fetchEndGameData = (game, data, history, assessmentId) => {
  return async (dispatch) => {
    dispatch(endGame(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios
      .put(`${BASE_URL}${PATH}assessments/${data.game_id}/complete/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        dispatch(endGame(false));
        return res;
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem(`${data.game_id}`);
          dispatch(endGameSuccess(res.data));
          history.push(`/assessments/${data.game_id}/games-completed`);
        }
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(endGameError(err));
      });
  };
};
