import React from 'react';
import { connect } from 'react-redux';
import { AcceptPrivacyPolicy } from '../../actions/acceptPrivacyPolicyAction';
import { LogoutUser } from '../../actions/LogOut';
import PageWrapper from '../../components/PageWrapper';
import { withTranslation } from 'react-i18next';

class ConfirmationOfGlobalPolicy extends React.Component {
  acceptPrivacy = () => {
    const { AcceptPrivacyPolicy, history } = this.props;
    AcceptPrivacyPolicy(history);
  };

  cancelAndExit = () => {
    const { history, LogoutUser } = this.props;
    LogoutUser(history);
  };
  render() {
    const { t } = this.props;

    return (
      <PageWrapper hideAccountDropdown={true} history={this.props.history}>
        <div className="container m-auto">
          <div className="row">
            <div className="col-12">
              <div className="auth-form-container text-center">
                <h1 className="mt-6 mb-0">
                  {t('Confirmation of Global Privacy Policy')}
                </h1>

                <p
                  className="mt-5 mb-0"
                  dangerouslySetInnerHTML={{
                    __html: t('globalPolicyDescription', {
                      url: process.env.PUBLIC_URL + '/privacy-policy',
                    }),
                  }}
                ></p>
                <p className="mt-4 mb-0">
                  <strong>
                    {t(
                      'By selecting Accept and Continue you agree to these terms',
                    )}
                  </strong>
                </p>
                <div className="d-flex flex-column align-items-center">
                  <button
                    onClick={this.acceptPrivacy}
                    className="mt-5 tr8s-button"
                    type="submit"
                  >
                    {t('Accept and Continue')}
                  </button>

                  <button
                    onClick={this.cancelAndExit}
                    className="tr8s-button mt-3 theme-color cancel-button"
                    type="submit"
                  >
                    {t('Cancel and Exit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default withTranslation()(
  connect(null, { AcceptPrivacyPolicy, LogoutUser })(
    ConfirmationOfGlobalPolicy,
  ),
);
