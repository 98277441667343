import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  createFetchVideoQuestionsThunk,
  createVideoUploadAndCompleteAssessmentThunk,
  createVideoUploadThunk,
} from '../../actions/videoAssessment';
import { WithId } from '../../models/assessments';
import { selectVideoQuestionsState } from '../../reducers/videoAssessment';
import Button from './components/Button';
import Setup from './components/Setup';
import WhiteboxVideoAssessment from './components/WhiteboxVideoAssessment';
import WhiteboxVideoPractice from './components/WhiteboxVideoPractice';
import { AssessmentProps, OnVideoStop, PracticeProps } from './models';
import { practiceQuestions } from './utils';
import PageWrapper from '../../components/PageWrapper';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import DOMPurify from 'dompurify';

const stepLabels = ['Setup', 'Practice', 'Assessment'];

export interface WhiteboxVideoProps extends RouteComponentProps<WithId> {}

const WhiteboxVideo: React.FC<WhiteboxVideoProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  const questionState = useSelector(selectVideoQuestionsState);

  const [mediaStream, _setMediaStream] = React.useState<MediaStream | null>(
    null,
  );
  const mediaRecorder = React.useRef<MediaRecorder | null>(null);
  const setMediaStream = React.useCallback((stream: MediaStream) => {
    _setMediaStream(stream);

    const config: MediaRecorderOptions = {};
    if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
      config.mimeType = 'video/webm;codecs=vp9';
    }
    mediaRecorder.current = new MediaRecorder(stream, config);
  }, []);

  const { testId, applications } = React.useMemo(() => {
    const search = queryString.parse(props.location.search) as {
      test: string;
      application: string[];
    };

    return { testId: search.test, applications: search.application };
  }, [props.location.search]);
  const assessmentId = props.match.params.id!;
  React.useEffect(() => {
    // * the answers cannot be submitted without the following params
    if (!assessmentId || !testId || !applications) {
      props.history.push('/');
    }
  }, [props.history, testId, assessmentId, applications]);

  React.useEffect(() => {
    dispatch(
      createFetchVideoQuestionsThunk({ testId: DOMPurify.sanitize(testId) }),
    );
  }, [testId, dispatch]);

  const [videoDeviceId, setVideoDeviceId] = React.useState<
    MediaDeviceInfo['deviceId'] | null
  >(null);
  const [audioDeviceId, setAudioDeviceId] = React.useState<
    MediaDeviceInfo['deviceId'] | null
  >(null);

  const [stepIndex, setStepIndex] = React.useState(0);

  const goBack = React.useCallback(() => {
    setStepIndex((index) => index - 1);
  }, []);
  const goForward = React.useCallback(() => {
    setStepIndex((index) => index + 1);
  }, []);

  const handleVideoStop: OnVideoStop = React.useCallback(
    ({ blob, questionId }) => {
      dispatch(
        createVideoUploadThunk({
          assessment_id: parseInt(assessmentId),
          question_id: questionId,
          file: blob,
          file_extension: 'webm',
        }),
      );
    },
    [assessmentId, dispatch],
  );

  const handleComplete: OnVideoStop = React.useCallback(
    ({ blob, questionId }) => {
      const videoUploadAndCompletePromise = dispatch(
        createVideoUploadAndCompleteAssessmentThunk({
          assessment_id: parseInt(assessmentId),
          question_id: questionId,
          file: blob,
          file_extension: 'webm',
        }),
      );

      // * the return type of `dispatch` is actually the return type of the thunk action
      // * given that thunk middleware has been applied
      ((videoUploadAndCompletePromise as unknown) as ReturnType<
        typeof videoUploadAndCompletePromise
        // * go to last step upon completing assessment
      >).then(() => {
        goForward();

        // * stop all tracks to revoke control over media devices
        mediaStream?.getTracks().forEach((track) => track.stop());
      });
    },
    [assessmentId, dispatch, goForward, mediaStream],
  );

  const practiceProps: NonNullable<PracticeProps> = React.useMemo(() => {
    return {
      text: (
        <span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'Feel free to practice as many times as you like by pressing Ready again',
              ),
            }}
          ></span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'Upon completing your practice round please press Start Assessment to begin',
              ),
            }}
          ></span>
        </span>
      ),
      Button: ({ countdownS }) => (
        <Button
          onClick={goForward}
          disabled={countdownS === null || !!countdownS}
        >
          {t('Start Assessment')}
        </Button>
      ),
    };
    // eslint-disable-next-line
  }, [goForward, direction]);

  const backToSetup = React.useMemo(() => {
    return (
      <div
        className="goVacancy cursor-pointer text-align-start"
        tabIndex={0}
        role="button"
        onClick={goBack}
      >
        <img
          src="/assets/img/arrow-right.svg"
          className={`arrowRight ${
            direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
          }`}
          alt="arrow"
        />
        {t('Back to assessment Setup')}
      </div>
    );
    // eslint-disable-next-line
  }, [goBack, direction]);

  const assessmentQuestions = React.useMemo(() => {
    // TODO handle other states
    return questionState.status === 'success' ? questionState.value : [];
  }, [questionState]);

  const assessmentProps: AssessmentProps = React.useMemo(() => {
    return {
      onStop: handleVideoStop,
      onComplete: handleComplete,
    };
  }, [handleVideoStop, handleComplete]);

  return (
    <PageWrapper isDashboardHeader history={props.history} match={props.match}>
      <div
        className={`flex-grow-1 d-flex flex-column mb-5 ${
          direction === CONTENT_DIRECTION.RTL ? 'video-rtl' : ''
        }`}
      >
        {(() => {
          const props = {
            stepIndex,
            stepLabels,
          };
          switch (stepIndex) {
            case 0:
              return (
                <Setup
                  {...props}
                  mediaStream={mediaStream}
                  setMediaStream={setMediaStream}
                  applications={applications}
                  title={t('Video Interview Setup')}
                  videoDeviceId={videoDeviceId}
                  audioDeviceId={audioDeviceId}
                  onSelectVideoDevice={setVideoDeviceId}
                  onSelectAudioDevice={setAudioDeviceId}
                  goForward={goForward}
                />
              );
            case 1:
              return (
                <WhiteboxVideoPractice
                  ref={mediaRecorder}
                  {...props}
                  practiceProps={practiceProps}
                  mediaStream={mediaStream}
                  title={t('Video Interview Practice')}
                  videoDeviceId={videoDeviceId}
                  audioDeviceId={audioDeviceId}
                  back={backToSetup}
                  goBack={goBack}
                  questions={practiceQuestions}
                />
              );

            case 2:
              return (
                <WhiteboxVideoAssessment
                  ref={mediaRecorder}
                  {...props}
                  {...assessmentProps}
                  mediaStream={mediaStream}
                  title={t('Video Interview Assessment')}
                  videoDeviceId={videoDeviceId}
                  audioDeviceId={audioDeviceId}
                  back={<div className="invisible">{backToSetup}</div>}
                  goBack={goBack}
                  questions={assessmentQuestions}
                />
              );
            default:
              return (
                <Redirect
                  to={`/assessments/${assessmentId}/tests-completed/`}
                />
              );
          }
        })()}
      </div>
    </PageWrapper>
  );
};

export default WhiteboxVideo;
