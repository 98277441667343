import {
  GET_PERSONALITY_TEST_QUESTION_SUCCESS,
  START_PERSONALITY_TEST,
} from '../constants/ActionTypes';

const initialState = {
  testQuestions: [],
  isLoading: false,
  error: null,
  testTimer: null,
};

export default function personalityTestReducer(state = initialState, action) {
  switch (action.type) {
    // GET TEST QUESTIONS
    case GET_PERSONALITY_TEST_QUESTION_SUCCESS: {
      return {
        ...state,
        testQuestions: action.payload.testQuestions,
        isLoading: false,
        error: null,
      };
    }
    // START TEST
    case START_PERSONALITY_TEST: {
      return {
        ...state,
        testTimer: Date.now() + 10000,
      };
    }
    // DEFAULT
    default: {
      return state;
    }
  }
}
