import axios from 'axios';
import {
  GET_APPLICATIONS,
  GET_SIPMLE_ASSESSMENTS_FAILURE,
  GET_SIPMLE_ASSESSMENTS_REQUEST,
  GET_SIPMLE_ASSESSMENTS_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const getSimpleAssesstmentsRequest = (assessmentsLoading) => {
  return {
    type: GET_SIPMLE_ASSESSMENTS_REQUEST,
    payload: {
      assessmentsLoading,
    },
  };
};

const getSimpleAssesstmentsSuccess = (assessment) => {
  return {
    type: GET_SIPMLE_ASSESSMENTS_SUCCESS,
    payload: {
      assessment,
    },
  };
};

const getApplications = (assessment) => {
  return {
    type: GET_APPLICATIONS,
    payload: {
      assessment,
    },
  };
};

const getSimpleAssesstmentsFailure = (error) => {
  return {
    type: GET_SIPMLE_ASSESSMENTS_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchAssessmentData = (assessmentId) => {
  return async (dispatch) => {
    dispatch(getSimpleAssesstmentsRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    axios(`${BASE_URL}${PATH}assessments/${assessmentId}/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(getApplications(res.data));
        dispatch(getSimpleAssesstmentsSuccess(res.data));
        dispatch(getSimpleAssesstmentsRequest(false));
      })
      .catch((err) => {
        if (err.response !== undefined) {
          unauthorized(err.response.status);
        }
        dispatch(getSimpleAssesstmentsFailure(err));
        dispatch(getSimpleAssesstmentsRequest(false));
      });
  };
};
