import React from 'react';
import Footer from '../Footer';
import Header, { HeaderProps } from '../Header';

interface Props extends Partial<HeaderProps> {
  isDashboardFooter?: boolean;
  children?: React.ReactNode;
}

const PageWrapper = ({
  children,
  isDashboardFooter,
  ...headerProps
}: Props) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="content-wrapper">
            <Header {...headerProps} />
            {children}
            <Footer isDashboardFooter={isDashboardFooter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
