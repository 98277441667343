import axios from 'axios';
import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchQuestionData } from '../../actions/personalityTestActions';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import Progress from '../../components/TestProgressBar';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import unauthorized from '../../unauthorized';
import { withTranslation } from 'react-i18next';

class SituationalJudgmentTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      pages: [],
      currentPage: 0,
      answerList: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const { match, history, fetchAssessmentData } = this.props;
    const token = localStorage.getItem('token');
    const queryObject = parse(this.props.location.search);
    if (token) {
      setToken(token);
      fetchAssessmentData(match.params.id);
      await axios
        .get(
          `${BASE_URL}${PATH}tests/${queryObject.test}/questions/?page_size=1000`,
        )
        .then((res) => {
          if (200 === res.status) {
            this.setState({
              pages: res.data.results,
              answerList: res.data.results,
            });
            if (res.data.results.length === 1) {
              // setEndPage(true);
            }
          }
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log('=======test', err);

          unauthorized(err.response.status);
        });
    } else if (!token) {
      history.push('/login');
    }
  }

  collectData = () => {
    const collectedData = this.state.pages.map((questionData) => {
      const data = questionData.options
        .filter((item) => !!item.data)
        .map((item) => {
          return {
            option_id: item.id,
            scale_id: item.data.value,
          };
        });
      return {
        assessment: this.props.match.params.id,
        question: questionData.id,
        data,
      };
    });
    return collectedData;
  };

  onSubmitHandler = (event) => {
    event.preventDefault();

    this.setState({ disabledButton: true, loading: true });
    const collectedData = this.collectData();

    axios
      .post(`${BASE_URL}${PATH}answers/`, collectedData)
      .then((res) => {
        if (res.status === 201) {
          axios
            .put(
              `${BASE_URL}${PATH}assessments/${this.props.match.params.id}/complete/`,
            )
            .then((res) => {
              if (res.status === 200) {
                this.setState({ loading: false });
                this.props.history.replace(
                  `/assessments/${this.props.match.params.id}/tests-completed`,
                );
              }
            });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  nextPage = (event) => {
    event.preventDefault();
    const { currentPage, pages } = this.state;

    if (pages.length >= 2) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  previousPage = (event) => {
    event.preventDefault();
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  isCheck = (e) => {
    const { value, name } = e.target;
    const { currentPage, pages } = this.state;

    const answerListNew = pages[currentPage].options
      .map((option) => {
        if (option.data?.value === +value) {
          delete option.data;
        }
        return option;
      })
      .map((option) => {
        if (option.id === +name) {
          return {
            ...option,
            data: {
              optionid: parseInt(name),
              value: parseInt(value),
            },
          };
        }
        return option;
      });

    const newPages = [...pages];
    newPages[currentPage].options = answerListNew;

    this.setState({
      answerList: answerListNew,
      pages: newPages,
    });
  };

  checkDisabledButton = () => {
    const { currentPage, pages } = this.state;
    const data = pages;

    const isMostSelected = data[currentPage].options.some(
      (option) => option.data?.value === 1,
    );
    const isLeastSelected = data[currentPage].options.some(
      (option) => option.data?.value === 0,
    );

    return isMostSelected && isLeastSelected;
  };

  render() {
    const { match, history, t } = this.props;
    const { disabledButton, pages, currentPage, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <PageWrapper
        isDashboardHeader
        test={false}
        history={history}
        match={match}
        testType={'SjtData'}
        isDashboardFooter
      >
        <div className="main-content-wrapper w-100 text-align-start">
          <h1 className="content-title test-example">
            {t('Situational Judgment Test')}
          </h1>
          <div className="mb-35">
            <Progress current={currentPage + 1} all={pages.length} />
          </div>
          <p
            className="test-question-title"
            dangerouslySetInnerHTML={{ __html: t(pages[currentPage].text) }}
          ></p>
          <div className="personality-test-form">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">
                    <p>{t('Most')}</p>
                  </th>
                  <th scope="col">
                    <p>{t('Least')}</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!!pages.length
                  ? pages[currentPage].options.map((option) => {
                      const isThumbUp = option.data?.value === 1;
                      const isThumbDown = option.data?.value === 0;

                      return (
                        <tr key={currentPage + ' ' + option.text}>
                          <th scope="row" className="title sjt-option-title">
                            {t(option.text)}
                          </th>
                          <td>
                            <label className="radiobox-container sjt-thumb-container">
                              <input
                                className="l"
                                type="radio"
                                name={`${option.id}`}
                                value="1"
                                checked={isThumbUp}
                                onChange={this.isCheck}
                              />
                              <img
                                className="thumbUp"
                                src="/assets/img/thumb.svg"
                                alt="thumb"
                              />
                              <span className="checkmarkRadio radio thum " />
                            </label>
                          </td>

                          <td>
                            <label className="radiobox-container sjt-thumb-container">
                              <input
                                className="r"
                                type="radio"
                                name={`${option.id}`}
                                value="0"
                                onChange={this.isCheck}
                                checked={isThumbDown}
                              />
                              <img
                                className="thumbDown"
                                src="/assets/img/thumb.svg"
                                alt="thumb"
                              />
                              <span className="checkmarkRadio radio" />
                            </label>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            <div
              className={`d-flex mb-4 ${
                currentPage >= 1
                  ? 'justify-content-between'
                  : 'justify-content-end'
              }`}
            >
              <button
                disabled={currentPage + 1 === 1 ? true : false}
                className={`tr8s-button mb-4 mt-0 green assessmentBtnNext ${
                  currentPage >= 1 ? '' : 'd-none'
                }`}
                onClick={this.previousPage}
              >
                {t('Back')}
              </button>

              <div className="d-flex flex-column">
                {currentPage !== pages.length - 1 && (
                  <React.Fragment>
                    <button
                      disabled={!this.checkDisabledButton()}
                      className={`tr8s-button assessmentBtnNext mt-0 mb-4
                        ${
                          this.checkDisabledButton()
                            ? 'green'
                            : 'cursor-not-allowed gray'
                        }
                      `}
                      onClick={this.nextPage}
                    >
                      {t('Next')}
                    </button>
                  </React.Fragment>
                )}
                {currentPage === pages.length - 1 && (
                  <button
                    disabled={!this.checkDisabledButton() || disabledButton}
                    className={`tr8s-button assessmentBtnNext mt-0 mb-4
                      ${
                        this.checkDisabledButton()
                          ? 'green'
                          : 'cursor-not-allowed gray'
                      }
                    `}
                    onClick={this.onSubmitHandler}
                  >
                    {t('Submit')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assessment: state.assessmentsReduces.assessment,
    assessmentLoading: state.assessmentsReduces.isLoading,
    questionLoading: state.getQuestion.isLoading,
    question: state.getQuestion.question,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchQuestionData,
    fetchAssessmentData,
  })(SituationalJudgmentTest),
);
