import React from 'react';
import { useTranslation } from 'react-i18next';

const DriversListMobile = ({
  drivers,
  categoryId,
  categories,
  handleCheckedChange,
  catIdx,
}) => {
  const { t } = useTranslation();

  const checkCheckbox = (driverId) => {
    const isChecked = categories
      .find((category) => category.id === categoryId)
      .driverIds.some((driver) => driver === driverId);
    return isChecked;
  };

  const checkCheckboxDisable = (driverId) => {
    const catIndexForCheck = categories.findIndex((category) =>
      category.driverIds.includes(driverId),
    );
    return catIndexForCheck > -1
      ? catIndexForCheck !== catIdx
      : categories[catIdx].driverIds.length > 4;
  };

  return (
    <div className="cardWrapperMobile">
      {drivers.map((driver) => {
        const isDisabled = checkCheckboxDisable(driver.id);

        return (
          <div
            key={`${categoryId}-${driver.id}`}
            className="d-flex justify-content-between align-items-center"
          >
            <div className={`mb-3 mr-2 ${isDisabled ? 'disabledDriver' : ''}`}>
              <p className="mb-0">{t(driver.name)}</p>
              <span>{t(driver.description)}</span>
            </div>

            <label
              className={`checkbox-container driver-checkbox-container ${
                isDisabled ? 'checkbox-container-disabled' : ''
              }`}
            >
              <input
                type="checkbox"
                onChange={(e) => handleCheckedChange(e, categoryId, driver.id)}
                checked={checkCheckbox(driver.id)}
                disabled={isDisabled}
                className={`${isDisabled ? 'disabled-checkmark' : ''}`}
              />

              <span className="checkmark" />
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default DriversListMobile;
