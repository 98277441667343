export const nativeLanguages = [
  'af',
  'sq',
  'ar',
  'hy',
  'eu',
  'bn',
  'bg',
  'ca',
  'km',
  'yue',
  'zh',
  'hr',
  'cs',
  'da',
  'nl',
  'en',
  'et',
  'fj',
  'fi',
  'fr',
  'ka',
  'de',
  'el',
  'gu',
  'he',
  'hi',
  'hu',
  'is',
  'id',
  'ga',
  'it',
  'ja',
  'jv',
  'ko',
  'la',
  'lv',
  'lt',
  'mk',
  'ms',
  'ml',
  'mt',
  'mi',
  'mr',
  'mn',
  'ne',
  'no',
  'fa',
  'pl',
  'pt',
  'pa',
  'qu',
  'ro',
  'ru',
  'sm',
  'sr',
  'sk',
  'sl',
  'es',
  'sw',
  'sv',
  'ta',
  'tt',
  'te',
  'th',
  'bo',
  'to',
  'tr',
  'uk',
  'ur',
  'uz',
  'vi',
  'cy',
  'xh',
];

export default { nativeLanguages };
