// export const BASE_URL =
//   "https://api.whiteboxhr.net";
// export const BASE_URL = "http://95.216.211.125:8000";
// export const BASE_URL = "http://13.81.99.84";    //for new server
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const PATHAPI = process.env.REACT_APP_PATHAPI;
export const PATH = process.env.REACT_APP_PATH;
// export const SITE_URL = "http://whiteboxhr.thesparxitsolutions.com/";
export const SITE_URL = process.env.REACT_APP_SITE_URL;
// export const REF_ID = "e546b76b-f227-4603-9791-f94a37df4537"; // Staging
export const REF_ID = process.env.REACT_APP_REF_ID;

export const PAYPAL_ENV = process.env.REACT_APP_PAYPAL_ENV;
export const PAYPAL_SANDBOX_KEY = process.env.REACT_APP_PAYPAL_SANDBOX_KEY;
export const PAYPAL_PRODUCTION_KEY =
  process.env.REACT_APP_PAYPAL_PRODUCTION_KEY;
export const PAYPAL_CURRENCY = process.env.REACT_APP_PAYPAL_CURRENCY;

export const START_GAME_URL = process.env.REACT_APP_START_GAME_URL;
export const NEXT_ROUND_URL = process.env.REACT_APP_NEXT_ROUND_URL;
export const SUBMIT_ROUND_URL = process.env.REACT_APP_SUBMIT_ROUND_URL;
export const END_GAME_URL = process.env.REACT_APP_END_GAME_URL;
