import { APPLY_JOB_FAILURE, APPLY_JOB_REQUEST } from '../constants/ActionTypes';

const initialState = {
  error: null,
  isApplying: false,
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case APPLY_JOB_REQUEST: {
      return {
        ...state,
        isApplying: payload.isApplying,
      };
    }
    case APPLY_JOB_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
