import React from 'react';
import ResetPasswordSuccessForm from '../../components/Auth/ResetPasswordSuccessForm';
import PageWrapper from '../../components/PageWrapper';

const ResetPasswordSuccess = (props) => {
  return (
    <PageWrapper history={props.history}>
      <div className="container m-auto">
        <div className="row">
          <div className="col-12">
            <ResetPasswordSuccessForm />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ResetPasswordSuccess;
