import { GET_ALL_IMAGES } from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  vacancies: [],
};

export default function getImages(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_IMAGES: {
      return {
        ...state,
        imagesList: payload.imagesList,
      };
    }
    default: {
      return state;
    }
  }
}
