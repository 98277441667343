import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import assessmentsReduces from './assessmentsReduces';
import authUser from './authUser';
import careerDriver from './careerDriverReducer';
import changePasswordReduces from './changePasswordReduces';
import startGameReducer from './gameReducer';
import gameTest from './gameTest';
import getAssessmentByVacancyId from './getAssessmentByVacancyId';
import getImages from './getImages';
import getJobsByCompanyId from './getJobsByCompanyId';
import applyToJobReducer from './applyToJobReducer';
import getQuestion from './getQuestion';
import personalityTest from './personalityTest';
import profileDictionary from './profileDictionary';
import refCompanyReducer from './refCompanyReducer';
import situationalJudgmentTestPartOne from './situationalJudgmentTestPartOne';
import situationalJudgmentTestPartTwo from './situationalJudgmentTestPartTwo';
import vacanciesReducer from './vacanciesReducer';
import videoAssessment from './videoAssessment';
import videoInterview from './videoInterview';

export default combineReducers({
  authUser,
  personalityTest,
  situationalJudgmentTestPartOne,
  situationalJudgmentTestPartTwo,
  vacanciesReducer,
  profileDictionary,
  assessmentsReduces,
  getQuestion,
  getAssessmentByVacancyId,
  getJobsByCompanyId,
  applyToJobReducer,
  gameTest,
  startGameReducer,
  changePasswordReduces,
  videoInterview,
  videoAssessment,
  careerDriver,
  getImages,
  refCompanyReducer,
  form: reduxFormReducer,
});
