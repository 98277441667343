import React from 'react';
import { useTranslation } from 'react-i18next';

const PersonalityFormDesktop = ({
  questions,
  handleChangeRadio,
  isTie = false,
}) => {
  const { t } = useTranslation();

  return (
    <table className="table mb-0">
      <thead>
        <tr>
          <th scope="col fex" />
          {questions.results[0].scales.map((item) => {
            return (
              <th key={item.id} scope="col flex">
                <p>{t(item.label)}</p>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {questions.results[0].options.map((option, itemIndex) => (
          <tr key={option.id}>
            <th scope="row" className="title">
              {t(option.text)}
            </th>
            {questions.results[0].scales.map((item) => {
              const isChecked =
                item.id === questions.results[0].options[itemIndex].value;
              const isDisabled = questions.results[0].options.some(
                (option) => option?.value === item.id,
              );

              return (
                <td key={`${option.id}+${item.id}+${Math.random()}`}>
                  <label
                    className={`checkbox-container ${
                      isDisabled && isTie ? 'disabled-checkbox-container' : ''
                    }`}
                  >
                    <input
                      type="checkbox"
                      id={`${option.id}+${item.id}`}
                      value={item.value}
                      onChange={() => handleChangeRadio(item, itemIndex)}
                      name={`${option.id}`}
                      checked={isChecked}
                    />
                    <span className="checkmark radio" />
                  </label>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PersonalityFormDesktop;
