import React from 'react';

const DeleteModal = ({ onCancel, onDelete, text, bntCancel, btnSuccess }) => (
  <div className="modalWrapper">
    <div className="modalContent">
      <span onClick={onCancel} className="timesClosed">
        &times;
      </span>
      <p>{text}</p>
      <div className="modalButtonsGroup">
        <button
          className="modalButton onDeleteBtn"
          type="button"
          onClick={onDelete}
        >
          {btnSuccess}
        </button>
        <button
          className="modalButton onCancelBtn"
          type="button"
          onClick={onCancel}
        >
          {bntCancel}
        </button>
      </div>
    </div>
  </div>
);
export default DeleteModal;
