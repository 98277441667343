export enum LANGUAGES {
  EN = 'en',
  AR = 'ar',
}

export function getLanguageLabel(lang: LANGUAGES | null) {
  switch (lang) {
    case LANGUAGES.AR:
      return 'العربية';
    case LANGUAGES.EN:
    default:
      return 'English';
  }
}
