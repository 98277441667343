import axios from 'axios';
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from '../constants/ActionTypes';
import { BASE_URL, PATHAPI } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const changePasswordRequest = (isLoading) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const changePasswordSuccess = (status) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: {
      status,
    },
  };
};

const changePasswordFailure = (error) => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: {
      error,
    },
  };
};

export const changeUserPassword = (passwordData, history) => {
  return async (dispatch) => {
    dispatch(changePasswordRequest(true));
    setToken(localStorage.getItem('token'));
    await axios
      .post(`${BASE_URL}${PATHAPI}auth/users/set_password/`, passwordData)
      // .then(res => {
      //   console.log('RESET', res.data);
      //   if (res.status !== 201) {
      //     throw Error(res.statusText);
      //   }
      //   return res;
      // })
      .then((res) => {
        dispatch(changePasswordSuccess(passwordData.message));
        dispatch(changePasswordFailure(''));
        dispatch(changePasswordRequest(false));
        // if (res.data.status === "OK") {
        //     history.push("/dashboard");
        // }
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(changePasswordSuccess(''));
        dispatch(
          changePasswordFailure(
            err.response.data.current_password
              ? err.response.data.current_password
              : err.response.data.new_password
              ? err.response.data.new_password
              : '',
          ),
        );
        dispatch(changePasswordRequest(false));
      });
  };
};

const forgetPasswordRequest = (isLoading) => {
  return {
    type: FORGET_PASSWORD_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const forgetPasswordSuccess = (status) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: {
      status,
    },
  };
};

const forgetPasswordFailure = (error) => {
  return {
    type: FORGET_PASSWORD_FAILURE,
    payload: {
      error,
    },
  };
};

export const forgetUserPassword = (passwordData, history) => {
  return async (dispatch) => {
    dispatch(forgetPasswordRequest(true));
    setToken(localStorage.getItem('token'));
    await axios
      .post(`${BASE_URL}${PATHAPI}auth/users/reset_password/`, passwordData)
      .then((res) => {
        // console.log('RES0', res);
        if (res.status !== 204) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(forgetPasswordSuccess(res.data.status));
        dispatch(forgetPasswordRequest(false));
        history.push('/forgot-password-success');
        // if (res.data.status === "OK") {
        //     history.push("/login");
        // }
      })
      .catch((err) => {
        //console.log('ERROR', err.response.data);
        dispatch(forgetPasswordFailure(err.response.data));
        dispatch(forgetPasswordRequest(false));
        // history.push("/forgot-password-success");
      });
  };
};

const resetPasswordRequest = (isLoading) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const resetPasswordSuccess = (status) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: {
      status,
    },
  };
};

const resetPasswordFailure = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: {
      error,
    },
  };
};

export const resetUserPassword = (passwordData, history) => {
  return async (dispatch) => {
    dispatch(resetPasswordRequest(true));
    setToken(localStorage.getItem('token'));
    await axios
      .post(
        `${BASE_URL}${PATHAPI}auth/users/reset_password_confirm/`,
        passwordData,
      )
      .then((res) => {
        if (res.data !== '') {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(resetPasswordSuccess(passwordData.message));
        dispatch(resetPasswordRequest(false));
        history.push('/reset-password-success');
        // if (res.data.status === "OK") {
        //     history.push("/login");
        // }
      })
      .catch((err) => {
        let errorMsg = err.response.data.token
          ? err.response.data.token
          : err.response.data.password
          ? err.response.data.password
          : 'There is some problem in change password, please try again.';
        dispatch(resetPasswordFailure(errorMsg));
        dispatch(resetPasswordRequest(false));
        // history.push("/forgot-password-success");
      });
  };
};
