import axios from 'axios';
import { REACTIVATE, REACTIVATE_ERROR } from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';

const reactivateRequest = (isLoading) => {
  return {
    type: REACTIVATE,
    payload: {
      isLoading,
    },
  };
};

const reactivateFailure = (error) => {
  return {
    type: REACTIVATE_ERROR,
    payload: {
      error,
    },
  };
};

export const reactivateData = (
  id,
  fetchVacanciesData,
  callBack,
  history = '',
) => {
  return async (dispatch) => {
    dispatch(reactivateRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios
      .put(`${BASE_URL}${PATH}applications/${id}/unarchive/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.status);
        }
        return res;
      })
      .then((res) => {
        callBack();
        if (res.data.success) {
          fetchVacanciesData(history);
          // dispatch(reactivateSuccess(vacancy));
          dispatch(reactivateRequest(false));
        }
      })
      .catch((err) => {
        dispatch(reactivateFailure(err));
        dispatch(reactivateRequest(false));
      });
  };
};
