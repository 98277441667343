import React from 'react';
import PageWrapper from '../PageWrapper';
import { useTranslation } from 'react-i18next';

const Page404 = (props) => {
  const { t } = useTranslation();

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <PageWrapper history={props.history}>
      <div className="container m-auto">
        <div className="row">
          <div className="col-12">
            <div className="auth-form-container text-center">
              <p className="auth-form-title">{t('Page not found')}</p>
              <p className="auth-form-text my-4">
                {t('Unfortunately the page you are looking for is not here')}
              </p>
              <button className="tr8s-button green" onClick={goBack}>
                {t('Go back')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Page404;
