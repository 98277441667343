import axios from 'axios';
import pathToRegexp from 'path-to-regexp';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import { fetchUserData } from '../../actions/userPersonalInfo';
import { fetchVideoAssessmentData } from '../../actions/videoAssessmentsAction';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import { BASE_URL, PATH } from '../../constants/variables';
import { QuestionType } from '../../models/assessments';
import setToken from '../../setToken';
import routes from '../../utils/routes';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

class AssessmentsInfo extends React.Component {
  state = {
    click: false,
  };

  componentDidMount() {
    const {
      match,
      history,
      fetchAssessmentData,
      fetchUserData,
      userInfo,
    } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      if (!userInfo) {
        fetchUserData();
      }
      fetchAssessmentData(match.params.id);
      // fetchAssessmentByIdData(match.params.id)
    } else if (!token) {
      history.push('/login');
    }
  }

  goToProfile = () => {
    this.props.history.push('/profile');
  };

  personalityTestExample = () => {
    const { assessment } = this.props;

    switch (assessment.test.type.id) {
      case QuestionType.Personality:
        this.props.history.replace(
          `/personality-example/${this.props.match.params.id}?test=${assessment.test.id}&uuid=${this.props.userInfo.id}`,
        );
        break;
      default:
        axios
          .put(
            `${BASE_URL}${PATH}assessments/${this.props.match.params.id}/start/`,
          )
          .then((res) => {
            switch (assessment.test.type.id) {
              case QuestionType.SituationalJudgement:
                this.props.history.replace(
                  `/sjt-example/${this.props.match.params.id}?test=${assessment.test.id}`,
                );
                break;
              case QuestionType.CareerDrivers:
                this.props.history.replace(
                  `/career-drivers/${this.props.match.params.id}?test=${assessment.test.id}`,
                );
                break;
              case QuestionType.ProfileQuestionnaire:
              case QuestionType.SurveyAssessment:
              case QuestionType.TechnicalAssessment:
                this.props.history.replace(
                  `/questionnaire-assessment/${this.props.match.params.id}?test=${assessment.test.id}`,
                );
                break;
              case QuestionType.VideoAssessment:
                this.props.history.replace({
                  pathname: pathToRegexp.compile(routes.videoAssessment)({
                    id: this.props.match.params.id,
                  }),
                  search: queryString.stringify({
                    test: assessment.test.id,
                    application: store.getState().assessmentsReduces
                      .currentApplicationId,
                  }),
                });
                break;
              default:
                break;
            }
          });
        break;
    }
  };

  handleRedirect = async () => {
    const { assessment, history, fetchVideoAssessmentData } = this.props;
    await fetchVideoAssessmentData(assessment.id, history);
    this.setState({ click: true });
    history.push(`/assessments/${assessment.id}`);
  };

  openNewTab = (videoAssessment) => {
    // const { videoAssessment } = this.props;
    window.open(videoAssessment, '_blank');
    window.location.href = '/dashboard';
  };

  render() {
    const {
      userInfo,
      isLoading,
      assessment,
      assessmentIsLoading,
      t,
    } = this.props;

    if (isLoading || !assessment || !userInfo || assessmentIsLoading) {
      return <Loader />;
    }

    const isPersonality = assessment.test.type.id === QuestionType.Personality;
    const durationInMin = parseInt(assessment.test.duration.split(':')[1]);
    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <Link
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back to assessment dashboard')}
        </Link>
        <div className="text-align-start">
          <h1 className="content-title startTest">
            {t('Welcome name', { name: userInfo.first_name })}
          </h1>
          <p
            style={{ marginBottom: '15px', fontSize: '15px' }}
            className="content-text-assessment"
            dangerouslySetInnerHTML={{
              __html: isPersonality
                ? t('personalityAssessmentDescription', { time: durationInMin })
                : t(assessment.test.description) ||
                  (assessment.test.id === 2
                    ? t(
                        'This questionnaire is designed to provide us with greater insight into your background and preferences',
                      )
                    : ''),
            }}
          ></p>
          <div className="infoContainer">
            <div
              className={`infoWrapper ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
            >
              <img src="/assets/img/clock.svg" alt="" className="icon" />
              <div
                className={`textInfo ${
                  direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                }`}
              >
                <p className="Infotype text-uppercase">{t('Estimated time')}</p>
                <p>
                  {typeof durationInMin != 'undefined'
                    ? t('minutes', { time: durationInMin })
                    : ''}
                </p>
              </div>
            </div>
            {[QuestionType.Video, QuestionType.VideoAssessment].includes(
              assessment.test.type.id,
            ) ? null : (
              <div
                className={`infoWrapper ${
                  direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                }`}
              >
                <img src="/assets/img/questions.svg" alt="" className="icon" />
                <div
                  className={`textInfo ${
                    direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                  }`}
                >
                  <p className="Infotype">
                    {[
                      QuestionType.ProfileQuestionnaire,
                      QuestionType.TechnicalAssessment,
                      QuestionType.SurveyAssessment,
                    ].includes(assessment.test.type.id)
                      ? t('Questions')
                      : assessment.test.type.id ===
                        QuestionType.SituationalJudgement
                      ? t('Scenarios')
                      : t('Statements')}
                  </p>
                  <p>
                    {QuestionType.Personality === assessment.test.type.id
                      ? assessment.total_traits * 7
                      : assessment.test.questions.length}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="buttonNext">
            <button
              style={{
                marginTop: '39px',
                width:
                  assessment.test.type.id === QuestionType.Personality
                    ? 'auto'
                    : '164px',
              }}
              onClick={this.personalityTestExample}
              className="tr8s-button mb green assessmentBtnNext"
            >
              {assessment.test.type.id === QuestionType.Personality
                ? t('Next')
                : t('Start')}
            </button>
          </div>
          <div style={{ marginBottom: '100px' }} />
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.authUser.userInfo,
    isLoading: state.authUser.isLoading,
    assessment: state.assessmentsReduces.assessment,
    assessmentIsLoading: state.assessmentsReduces.assessmentsLoading,
    assessmentError: state.assessmentsReduces.error,
    videoAssessment: state.assessmentsReduces.videoAssessment,
    videoassessmentsLoading: state.assessmentsReduces.videoassessmentsLoading,
    videoError: state.assessmentsReduces.videoError,
    vacancy: state.getAssessmentByVacancyId.vacancy,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchAssessmentData,
    fetchUserData,
    fetchAssessmentByIdData,
    fetchVideoAssessmentData,
  })(AssessmentsInfo),
);
