import React from 'react';
import Footer from '../Footer';
import './StaticFooter.css';

export interface StaticFooterProps
  extends React.ComponentProps<typeof Footer> {}

const StaticFooter: React.FC<StaticFooterProps> = (props) => {
  return (
    <Footer
      {...props}
      className={'static-footer mt-4 position-static mw-100'.concat(
        props.className ? ' '.concat(props.className) : '',
      )}
    />
  );
};

export default StaticFooter;
