import {
  REF_COMPANY_FAILURE,
  REF_COMPANY_REQUEST,
  REF_COMPANY_SUCCESS,
} from '../constants/ActionTypes';
import { mainColor } from '../utils/theme';

const initialState = {
  error: null,
  refCompLoading: false,
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case REF_COMPANY_REQUEST: {
      return {
        ...state,
        refCompLoading: payload.isLoading,
      };
    }
    case REF_COMPANY_SUCCESS: {
      return {
        ...state,
        refCompData: payload.responseData,
      };
    }
    case REF_COMPANY_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}

export const selectCompanyTheme = (state) =>
  state.refCompanyReducer.refCompData?.theme ?? mainColor;
