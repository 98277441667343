import React from 'react';
import { StepProps } from '../../models';
import BaseLayout, { BaseLayoutProps } from './BaseLayout';

export interface LayoutProps extends BaseLayoutProps, Pick<StepProps, 'title'> {
  left: React.ReactNode;
  right: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  title,
  left,
  right,
  ...layoutProps
}) => {
  return (
    <BaseLayout {...layoutProps}>
      <div className="video-layout-left d-flex flex-column">
        <h1 className="mt-0 content-title">{title}</h1>
        {left}
      </div>
      <div className="video-layout-right pl-xl-5 pt-5 d-flex flex-column align-items-center justify-content-center">
        {right}
      </div>
    </BaseLayout>
  );
};

export default Layout;
