import axios from 'axios';
import {
  LOGUOT_FAILURE,
  LOGUOT_REQUEST,
  LOGUOT_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL } from '../constants/variables';

const logOutRequest = (isLoading) => {
  return {
    type: LOGUOT_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const logOutSuccess = (vacancies) => {
  return {
    type: LOGUOT_SUCCESS,
    payload: {
      vacancies,
    },
  };
};

const Failure = (error) => {
  return {
    type: LOGUOT_FAILURE,
    payload: {
      error,
    },
  };
};

export const LogoutUser = (history) => {
  return async (dispatch) => {
    dispatch(logOutRequest(true));
    await axios
      .post(`${BASE_URL}/api/auth/token/logout/`, {})
      .then((res) => {
        if (res.status !== 204) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(logOutSuccess(200));
        dispatch(logOutRequest(false));
        localStorage.removeItem('token');
        window.location.href = '/login';
        // history.push("/login");
      })
      .catch((err) => {
        dispatch(Failure(err));
        dispatch(logOutRequest(false));
      });
  };
};
