import React from 'react';
import { connect } from 'react-redux';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchUserData } from '../../actions/userPersonalInfo';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import Steps from '../../components/UI/Steps';
import setToken from '../../setToken';
import { withTranslation } from 'react-i18next';

class TestCompleted extends React.Component {
  componentDidMount() {
    const {
      history,
      fetchUserData,
      userInfo,
      fetchAssessmentData,
      match,
    } = this.props;
    const token = localStorage.getItem('token');
    if (token && !userInfo) {
      setToken(token);
      fetchUserData();
      fetchAssessmentData(match.params.id, history);
    } else if (token && userInfo) {
      fetchAssessmentData(match.params.id, history);
    } else if (!token) {
      history.push('/login');
    }
  }

  handleSubmit = () => {
    const { assessment, history } = this.props;
    history.push(`/game-assessments/${assessment.parent_id}`);
  };

  render() {
    const { assessment, t } = this.props;
    if (!assessment) {
      return <Loader />;
    }
    const steps = [
      {
        status: 'active',
        label: t('You have just completed this section'),
      },
    ];

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <div className="w-100 text-align-start">
          <h1 className="content-title completeTitle">{t('Well done')}</h1>
          <Steps steps={steps} />
          <button
            onClick={this.handleSubmit}
            className="tr8s-button mb green goToDashboard mt-4"
          >
            {t('Return to games dashboard')}
          </button>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.authUser.userInfo,
    assessment: state.assessmentsReduces.assessment,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchUserData,
    fetchAssessmentData,
  })(TestCompleted),
);
