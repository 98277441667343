import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import userImage from '../../assets/img/user-img-report.png';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/user';

interface TeamFitDiversityProps {
  redEmployeeData: User[];
  greenEmployeeData: User[];
  candidateData: User;
}

interface IEmp extends User {
  name: string;
  img: string | null;
  color: string;
}

const mainEmpAdditional = {
  name: 'johnk',
  gender: 1,
  img: null,
  color: 'rgb(37, 164, 161)',
};

const TeamFitDiversity = ({
  redEmployeeData,
  greenEmployeeData,
  candidateData,
}: TeamFitDiversityProps) => {
  const { t } = useTranslation();

  const [mainEmp, setMainEmp] = useState<IEmp | null>(null);
  const [empList, setEmpList] = useState<IEmp[] | null>(null);

  const getModifiedEmployeeList = (empData: User[], isRed = false): IEmp[] => {
    return empData.map((emp) => {
      let tempObj = {
        name: `${emp.first_name} ${emp.last_name}`,
        img: null,
        color: isRed ? '#ff0000' : 'rgb(37, 164, 161)',
      };
      return { ...emp, ...tempObj };
    });
  };

  useEffect(() => {
    const mainEmpModified = { ...mainEmpAdditional, ...candidateData };

    const tempGreenEmployee = getModifiedEmployeeList(greenEmployeeData);
    const tempRedEmployee = getModifiedEmployeeList(redEmployeeData, true);

    setEmpList([...tempGreenEmployee, ...tempRedEmployee]);
    setMainEmp(mainEmpModified);
  }, [redEmployeeData, greenEmployeeData, candidateData]);

  let fullName = mainEmp?.first_name + ' ' + mainEmp?.last_name;
  const elementCount = empList?.length || 0;

  return (
    <section className="career-sec teamFitDiversity assessment-sec">
      <h2>{t('Team Fit & Diversity')}</h2>
      <div className="chart-wrapper">
        {!!mainEmp && (
          <div style={{ borderColor: mainEmp.color }} className="main-emp">
            <img className="user-img" src={userImage} alt="" />
            <span className="main-emp-firstName">{mainEmp.first_name}</span>
          </div>
        )}
        {(empList || []).map((item, index) => {
          return (
            <div
              style={{ background: item.color }}
              className={'emp emp-' + index}
              key={item.name + index}
            >
              <Helmet>
                <style>
                  {`.emp-${index}:after {
                        border-left-color: ${item.color};
                      }
                      
                      .emp-${index} {
                        transform: rotate(${
                          (360 / elementCount) * index - 90
                        }deg);
                      }
                      
                      .emp-${index} .emp-block {
                        border-color: ${item.color};
                        transform: rotate(${
                          0 - ((360 / elementCount) * index - 90)
                        }deg);
                      }
                   `}
                </style>
              </Helmet>
              <div className="emp-block">
                <img className="user-img" src={userImage} alt="" />
                <span className="emp-block-name">{item.name}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div className="chart-descr-wrapper">
          <span className="teamFitDiversity-dot teamFitDiversity-dot-green"></span>{' '}
          <label className="chart">
            &nbsp;&nbsp;&nbsp; {t('Great Collaboration Potential')}
          </label>
          &nbsp; &nbsp; &nbsp;
          <span className="teamFitDiversity-dot teamFitDiversity-dot-red"></span>
          <label className="chart">
            &nbsp;&nbsp;&nbsp; {t('Potential Friction')}
          </label>
          &nbsp; &nbsp; &nbsp;
        </div>
      </div>
      {!!mainEmp &&
        (redEmployeeData.length > 0 || greenEmployeeData.length > 0) && (
          <div>
            <h3 className="team-fit-title">{t('Team fit')}</h3>
            <p>
              {redEmployeeData.length > greenEmployeeData.length
                ? t('teamFitDiversityConcersDescr', {
                    fullName,
                    candidateAction:
                      mainEmp.gender === 3
                        ? t('they demonstrate')
                        : mainEmp.gender === 1
                        ? t('he demonstrates')
                        : t('she demonstrates'),
                  })
                : redEmployeeData.length === greenEmployeeData.length
                ? t('teamFitDiversityGoodDescr', {
                    fullName,
                    candidateAction:
                      mainEmp.gender === 3
                        ? t('they demonstrate')
                        : mainEmp.gender === 1
                        ? t('he demonstrates')
                        : t('she demonstrates'),
                    gender:
                      mainEmp.gender === 3
                        ? t('they')
                        : mainEmp.gender === 1
                        ? t('he')
                        : t('she'),
                  })
                : greenEmployeeData.length > redEmployeeData.length &&
                  redEmployeeData.length > 0
                ? t('teamFitDiversityExcellentDescr', {
                    fullName,
                    candidateAction:
                      mainEmp.gender === 3
                        ? t('they demonstrate')
                        : mainEmp.gender === 1
                        ? t('he demonstrates')
                        : t('she demonstrates'),
                    gender:
                      mainEmp.gender === 3
                        ? t('they')
                        : mainEmp.gender === 1
                        ? t('he')
                        : t('she'),
                  })
                : greenEmployeeData.length > redEmployeeData.length &&
                  redEmployeeData.length === 0
                ? t('teamFitDiversityAllGreenDescr', {
                    fullName,
                    candidateAction:
                      mainEmp.gender === 3
                        ? t('they demonstrate')
                        : mainEmp.gender === 1
                        ? t('he demonstrates')
                        : t('she demonstrates'),
                    gender:
                      mainEmp.gender === 3
                        ? t('they')
                        : mainEmp.gender === 1
                        ? t('he')
                        : t('she'),
                  })
                : ''}
            </p>
          </div>
        )}
    </section>
  );
};

export default TeamFitDiversity;
