import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../components/PageWrapper';
import { withTranslation } from 'react-i18next';

class TermsofService extends Component {
  render() {
    const { history, t } = this.props;
    return (
      <>
        <Helmet>
          <style>{`
            .header.dashboard-header .d-flex.align-items-center {
              visibility: hidden;
            }
            `}</style>
        </Helmet>

        <PageWrapper isDashboardHeader history={history} isDashboardFooter>
          <div className="main-content-wrapper w-100">
            <div className="row">
              <div className="col-md-12">
                <br />
                <h1>{t('Terms of Service')}</h1>
                <p>{t('Last updated terms of servise')}</p>
                <p>
                  {t('These Terms of Service govern your relationship with')}{' '}
                  <a
                    href="https://whiteboxhr.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://whiteboxhr.com/
                  </a>{' '}
                  {t('website and platform operated by WhiteBox HR')}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('termsOfServiceDescription'),
                  }}
                />
                <br />
                <br />
              </div>
            </div>
          </div>
        </PageWrapper>
      </>
    );
  }
}

export default withTranslation()(TermsofService);
