import axios from 'axios';
import { createPath } from 'history';
import { omit } from 'lodash';
import pathToRegexp from 'path-to-regexp';
import queryString from 'query-string';
import { ThunkAction } from 'redux-thunk';
import urljoin from 'url-join';
import {
  VideoUploadParamsWithBlob,
  WithAssessmentId,
} from '../containers/VideoAssessment/models';
import { VideoQuestion, VideoQuestions } from '../models/questions';
import { ThunkReturnType } from '../models/state';
import {
  createAssessmentStatus,
  createFetchVideoQuestions,
  createVideoUpload,
  createVideoUploadAndCompleteAssessment,
  VideoAssessmentAction,
  VideoAssessmentState,
} from '../reducers/videoAssessment';
import {
  apiVersionOrigin,
  defaultPageSize,
  requestPaths,
} from '../utils/requests';

// * Thunk actions

export const createFetchVideoQuestionsThunk = (
  payload: Parameters<typeof createFetchVideoQuestions['request']>[0],
): ThunkAction<
  Promise<VideoQuestions>,
  { videoAssessment: VideoAssessmentState },
  void,
  VideoAssessmentAction
> => (dispatch) => {
  dispatch(createFetchVideoQuestions.request(payload));

  return axios
    .get<VideoQuestions>(
      createPath({
        pathname: apiVersionOrigin.concat(
          pathToRegexp.compile(requestPaths.questions)({
            id: payload.testId,
          }),
        ),
        search: '?'.concat(
          queryString.stringify({ page: 1, page_size: defaultPageSize }),
        ),
      }),
    )
    .then((res) => {
      dispatch(
        createFetchVideoQuestions.success({
          questions: (res.data.results as unknown) as VideoQuestion[],
        }),
      );

      return res.data;
    })
    .catch((error) => {
      dispatch(
        createFetchVideoQuestions.failure({
          errorMessage:
            'message' in error
              ? error.message
              : `Failed to fetch questions for test id: ${payload.testId}`,
        }),
      );

      throw error;
    });
};

export const createVideoUploadThunk = (
  payload: VideoUploadParamsWithBlob,
): ThunkAction<
  Promise<ReturnType<typeof createVideoUpload['success']>>,
  { videoAssessment: VideoAssessmentState },
  void,
  VideoAssessmentAction
> => (dispatch) => {
  dispatch(createVideoUpload.request(payload));

  const formData = new FormData();

  formData.append('assessment_id', payload.assessment_id.toString());
  formData.append('question_id', payload.question_id.toString());
  formData.append('file', payload.file);
  formData.append('file_extension', payload.file_extension);

  return axios
    .post(
      createPath({
        pathname: urljoin(apiVersionOrigin, requestPaths.whiteboxVideo),
      }),
      formData,
    )
    .then(() => {
      return dispatch(createVideoUpload.success(omit(payload, 'file')));
    })
    .catch((error) => {
      dispatch(
        createVideoUpload.failure({
          errorMessage:
            'message' in error ? error.message : `Failed to upload video.`,
        }),
      );

      throw error;
    });
};

const createAssessmentStatusThunk = (
  payload: WithAssessmentId,
): ThunkAction<
  Promise<ReturnType<typeof createAssessmentStatus['success']>>,
  { videoAssessment: VideoAssessmentState },
  void,
  VideoAssessmentAction
> => (dispatch) => {
  dispatch(createAssessmentStatus.request(payload));

  return axios
    .put(
      createPath({
        pathname: urljoin(
          apiVersionOrigin,
          pathToRegexp.compile(requestPaths.assessmentComplete)({
            id: payload.assessmentId,
          }),
        ),
      }),
    )
    .then(() => {
      return dispatch(createAssessmentStatus.success('complete'));
    })
    .catch((error) => {
      dispatch(
        createAssessmentStatus.failure({
          errorMessage:
            'message' in error ? error.message : `Failed to upload video.`,
        }),
      );

      throw error;
    });
};
export const createVideoUploadAndCompleteAssessmentThunk = (
  payload: VideoUploadParamsWithBlob,
): ThunkAction<
  Promise<
    [
      ThunkReturnType<typeof createVideoUploadThunk>,
      ThunkReturnType<typeof createAssessmentStatusThunk>,
    ]
  >,
  { videoAssessment: VideoAssessmentState },
  void,
  VideoAssessmentAction
> => (...params) => {
  const [dispatch] = params;
  dispatch(createVideoUploadAndCompleteAssessment());

  return createVideoUploadThunk(payload)(...params).then(
    (videoUploadSuccess) => {
      return createAssessmentStatusThunk({
        assessmentId: payload.assessment_id.toString(),
      })(...params).then((assessmentStatusSuccess) => {
        return [videoUploadSuccess, assessmentStatusSuccess];
      });
    },
  );
};
