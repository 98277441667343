import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import { fetchUserData } from '../../actions/userPersonalInfo';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import SvgImage from '../../components/SvgImage';
import setToken from '../../setToken';
import { QuestionType } from '../../models/assessments';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

class GameAssessments extends React.Component {
  state = {};
  componentDidMount() {
    const {
      fetchUserData,
      fetchAssessmentByIdData,
      history,
      match,
    } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      fetchUserData();
      // gameTestData(match.params.id);
      fetchAssessmentByIdData(match.params.id);
    } else if (!token) {
      history.push('/login');
    }
  }

  getGameType = (cat) => {
    if (cat === 7) {
      return 9;
    }
    if (cat === 8) {
      return 10;
    }
    if (cat === 9) {
      return 7;
    }
    if (cat === 10) {
      return 8;
    }
    return 0;
  };

  handleStartTest = (vacancy, notStarted = false) => {
    if (!notStarted) {
      this.props.history.push(
        `/game/${this.getGameType(vacancy.test.category)}${vacancy.id}?test=${
          vacancy.test.id
        }`,
      );
    } else {
      this.props.history.push(
        `/game-info/${this.getGameType(vacancy.test.category)}${
          vacancy.id
        }?test=${vacancy.test.id}`,
      );
    }
  };

  setIconTest = (type) => {
    if (type === 10) {
      return '/assets/img/matchesgameicon.svg';
    }
    if (type === 7) {
      return '/assets/img/swiped.svg';
    }
    if (type === 8) {
      return '/assets/img/shapes.svg';
    }
    if (type === 9) {
      return '/assets/img/rotate-it.svg';
    }
  };

  getAssessmentTime = (time) => {
    const { t } = this.props;

    const minOne =
      time != null && typeof time.split(':')[1] != 'undefined'
        ? parseInt(time.split(':')[1])
        : 0;
    const minTwo =
      time != null && typeof time.split(':')[1] != 'undefined'
        ? parseInt(time.split(':')[0])
        : 0;
    // return minOne + minTwo * 60 + ' minutes';
    return t('minutes', { time: minOne + minTwo * 60 });
  };

  render() {
    const {
      isLoading,
      userInfo,
      assessmentsLoading,
      assessments,
      t,
    } = this.props;

    if (isLoading || !userInfo || assessmentsLoading || !assessments) {
      return <Loader />;
    }

    const direction = localStorage.getItem('contentDirection');

    const notStarted = assessments.children.filter(
      (assess) => assess.status === 0,
    );
    const pending = assessments.children.filter(
      (assess) => assess.status === 1,
    );
    const completed = assessments.children.filter(
      (assess) => assess.status === 2,
    );

    if (
      completed.length === assessments.children.length &&
      assessments.test.type.id === QuestionType.Games
    ) {
      return (
        <Redirect to={`/assessments/${assessments.id}/tests-completed/`} />
      );
    }

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        isDashboardFooter
      >
        <div className="d-flex flex-column">
          <Link
            to={`/assessments/${
              store.getState().assessmentsReduces.currentApplicationId
            }`}
            className="goVacancy text-align-start"
          >
            <img
              src="/assets/img/arrow-right.svg"
              className={`arrowRight ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
              alt="arrow"
            />
            {t('Back to assessment dashboard')}
          </Link>
          <div className="main-content-wrapper w-100">
            <p className="test-result-title-assessments text-align-start">
              {t(assessments.test.title)}
            </p>
            {/* <div className="progressStatus">
                    <Progress
                      current={completed.length}
                      all={completed.length + pending.length + notStarted.length}
                    />
                  </div> */}

            {notStarted.length > 0 ? (
              <p className="test-result-subtitle text-align-start">
                {t('Not Started')}
              </p>
            ) : null}
            <div className="whitebox__card-row mb-0">
              {notStarted
                // .sort((a, b) => +a.order - +b.order)
                .map((vac) => {
                  return (
                    <div
                      key={vac.id}
                      className={`whitebox__card-wrapper ${
                        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                      }`}
                    >
                      <div className="whitebox__card jobCard">
                        <div className="text-align-start">
                          <SvgImage
                            src={`${this.setIconTest(vac.test.category)}`}
                            alt="test"
                            className="iconCard whitelabel"
                          />
                          <span
                            className={`titleForWebDev ${
                              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                            }`}
                          >
                            {t(vac.test.title)}
                          </span>
                        </div>
                        <div className="bottmWrapper">
                          <div className="bottmWrapperStatus">
                            <div className="text-align-start">
                              <p className="titleAssessments">{t('Time')}</p>
                              <p className="game-card-small-text">
                                {this.getAssessmentTime(vac.test.duration)}
                              </p>
                            </div>
                            <div className="text-align-start">
                              <p className="titleAssessments">
                                {t('Progress Status')}
                              </p>
                              <p className="vacancyStatus game-card-status-not-started">
                                {t('Not Started')}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <button
                            className="test-result-block-button game-card-btn"
                            onClick={() => this.handleStartTest(vac, true)}
                          >
                            {t('Start')}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {pending.length > 0 ? (
              <p className="test-result-subtitle text-align-start">
                {t('Pending')}
              </p>
            ) : null}
            <div className="whitebox__card-row mb-0">
              {pending.map((vac) => {
                return (
                  <div
                    key={vac.id}
                    className={`whitebox__card-wrapper ${
                      direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                    }`}
                  >
                    <div className="whitebox__card jobCard">
                      <div className="text-align-start">
                        <SvgImage
                          src={`${this.setIconTest(vac.test.category)}`}
                          alt="test"
                          className="iconCard whitelabel"
                        />
                        <span
                          className={`titleForWebDev ${
                            direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                          }`}
                        >
                          {t(vac.test.title)}
                        </span>
                      </div>
                      <div className="bottmWrapper">
                        <div className="bottmWrapperStatus">
                          <div className="text-align-start">
                            <p className="titleAssessments">{t('Time')}</p>
                            <p className="game-card-small-text">
                              {this.getAssessmentTime(vac.test.duration)}
                            </p>
                          </div>
                          <div className="text-align-start">
                            <p className="titleAssessments">
                              {t('Progress Status')}
                            </p>
                            <p className="vacancyStatus game-card-status-pending">
                              {t('Pending')}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <button
                          style={{
                            margin: '0 0 0 3px',
                            fontSize: '10px',
                            padding: '1px 28px',
                          }}
                          className="test-result-block-button"
                          onClick={() => this.handleStartTest(vac)}
                        >
                          {t('Continue')}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {completed.length > 0 ? (
              <p className="test-result-subtitle text-align-start">
                {t('Completed')}
              </p>
            ) : null}
            <div className="whitebox__card-row mb-5">
              {completed.map((vac) => (
                <div
                  key={vac.id}
                  className={`whitebox__card-wrapper ${
                    direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                  }`}
                >
                  <div className="whitebox__card completedCard">
                    <div className="text-align-start">
                      <SvgImage
                        src={`${this.setIconTest(vac.test.category)}`}
                        alt="test"
                        className="iconCard whitelabel"
                      />
                      <span
                        className={`titleForWebDev ${
                          direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                        }`}
                      >
                        {t(vac.test.title)}
                      </span>
                    </div>
                    <div className="bottmWrapper">
                      <div className="bottmWrapperStatus">
                        <div className="text-align-start">
                          <p className="titleAssessments">{t('Time')}</p>
                          <p className="game-card-small-text">
                            {this.getAssessmentTime(vac.test.duration)}
                          </p>
                        </div>
                        <div className="text-align-start">
                          <p className="titleAssessments">
                            {t('Progress Status')}
                          </p>
                          <p className="game-card-small-text">
                            {t('Completed')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.authUser.userInfo,
    isLoading: state.authUser.isLoading,
    isLoadingVacancies: state.vacanciesReducer.isLoading,
    vacancies: state.vacanciesReducer.vacancies,
    assessments: state.getAssessmentByVacancyId.assessment,
    assessmentsLoading: state.getAssessmentByVacancyId.isLoading,
    games: state.gameTest.games,
    gamesLoading: state.gameTest.isLoading,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchUserData,
    fetchAssessmentByIdData,
  })(GameAssessments),
);
