import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import pathToRegexp from 'path-to-regexp';
import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../utils/routes';
import {
  StepProps,
  WithApplications,
  WithMaybeMediaStream,
  WithMediaDeviceIds,
  WithMediaDeviceSelectionHandlers,
} from '../models';
import { assertDeviceIds, getUserMedia } from '../services';
import { groupDevices } from '../utils';
import Button from './Button';
import Layout from './Layout/Layout';
import Video from './Video';
import { CONTENT_DIRECTION } from '../../../constants/contentDirection';

export interface SetupProps
  extends StepProps,
    WithMediaDeviceIds,
    WithMediaDeviceSelectionHandlers,
    WithApplications,
    WithMaybeMediaStream {
  goForward: () => void;
  setMediaStream: (stream: MediaStream) => void;
}

const Setup: React.FC<SetupProps> = (props) => {
  const { t } = useTranslation();

  const { onSelectVideoDevice, onSelectAudioDevice, setMediaStream } = props;

  const [devicesInfo, setDevicesInfo] = React.useState<MediaDeviceInfo[]>([]);

  const devices = React.useMemo(() => {
    return groupDevices(devicesInfo);
  }, [devicesInfo]);

  const enumerateDevices = React.useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then((devicesInfo) => {
      setDevicesInfo(devicesInfo);

      const groupedDevices = groupDevices(devicesInfo);

      const videoDeviceId = groupedDevices.video[0]?.deviceId;
      if (videoDeviceId) {
        onSelectVideoDevice(videoDeviceId);
      }
      const audioDeviceId = groupedDevices.audio[0]?.deviceId;
      if (audioDeviceId) {
        onSelectAudioDevice(audioDeviceId);
      }

      assertDeviceIds({ videoDeviceId, audioDeviceId });
    });
  }, [onSelectVideoDevice, onSelectAudioDevice]);

  React.useEffect(() => {
    getUserMedia({ videoDeviceId: null, audioDeviceId: null }).then(
      (stream) => {
        setMediaStream(stream);

        enumerateDevices();
      },
    );
  }, [setMediaStream, setDevicesInfo, enumerateDevices]);

  const handleSelectVideoDevice: SelectProps['onChange'] = React.useCallback(
    (e) => {
      const videoDeviceId = e.target.value as string;

      props.onSelectVideoDevice(videoDeviceId);

      getUserMedia({ videoDeviceId, audioDeviceId: props.audioDeviceId });
    },
    [props],
  );
  const handleSelectAudioDevice: SelectProps['onChange'] = React.useCallback(
    (e) => {
      props.onSelectAudioDevice(e.target.value as string);
    },
    [props],
  );

  const direction = localStorage.getItem('contentDirection');

  return (
    <Layout
      stepIndex={props.stepIndex}
      stepLabels={props.stepLabels}
      title={props.title}
      back={
        <Link
          className="goVacancy d-flex align-items-center text-align-start"
          to={pathToRegexp.compile(routes.applications)({
            id: props.applications,
          })}
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back to assessment dashboard')}
        </Link>
      }
      left={
        <div className="flex-grow-1 d-flex flex-column">
          <FormControl className="mt-4" fullWidth>
            <InputLabel>{t('Video Device')}</InputLabel>
            {/* the Select component clones it's children, injects a refs and various other props */}
            {/* * so be wary if you device to create an abstraction over the child component (MenuItem) */}
            <Select
              value={props.videoDeviceId ?? ''}
              onChange={handleSelectVideoDevice}
            >
              {devices.video.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label ?? device.deviceId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="mt-5" fullWidth>
            <InputLabel>{t('Audio Device')}</InputLabel>
            <Select
              value={props.audioDeviceId ?? ''}
              onChange={handleSelectAudioDevice}
            >
              {devices.audio.map((device) => {
                return (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label ?? device.deviceId}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div className="mt-auto">
            <div className="mt-5 d-flex">
              <Button
                className="w-50"
                color="default"
                onClick={enumerateDevices}
              >
                {t('Refresh devices')}
              </Button>
              {/* * the devices cannot be enumerated, without the users permission */}
              {/* <Button className="ml-3 w-50" onClick={getUserMedia}>
                Test video
              </Button> */}
            </div>
          </div>
        </div>
      }
      right={
        <Video
          src={props.mediaStream}
          muted
          footer={
            <div className="mt-5 d-flex flex-wrap justify-content-between align-items-center align-items-lg-start">
              <p
                className="mb-0 mr-sm-4"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'When you are fully set-up a practice run is available here',
                  ),
                }}
              ></p>
              <Button
                className="mt-3 mt-md-0 align-self-lg-end"
                onClick={props.goForward}
                disabled={!props.mediaStream}
              >
                {t('Start practice')}
              </Button>
            </div>
          }
        />
      }
    />
  );
};

export default Setup;
