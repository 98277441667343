import axios from 'axios';
import ls from 'local-storage';
import {
  REF_COMPANY_REQUEST,
  REF_COMPANY_SUCCESS,
  REF_COMPANY_FAILURE,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';

const refCompRequest = (isLoading) => {
  return {
    type: REF_COMPANY_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const refCompSuccess = (responseData) => {
  return {
    type: REF_COMPANY_SUCCESS,
    payload: {
      responseData,
    },
  };
};

const refCompFailure = (error) => {
  return {
    type: REF_COMPANY_FAILURE,
    payload: {
      error,
    },
  };
};

export const refCompanyAction = (refId, history = '') => {
  return async (dispatch) => {
    dispatch(refCompRequest(true));
    if (ls.get('refCompanyData') && ls.get('refCompanyData').refId === refId) {
      dispatch(refCompSuccess(ls.get('refCompanyData')));
      dispatch(refCompRequest(false));
    } else {
      await axios
        .get(`${BASE_URL}${PATH}company-ref/${refId}/`)
        .then((res) => {
          if (res.status !== 200) {
            throw Error(res.statusText);
          }
          return res;
        })
        .then((res) => {
          dispatch(refCompSuccess(res.data));
          ls.set('refCompanyData', res.data);
          dispatch(refCompFailure(false));
          dispatch(refCompRequest(false));
        })
        .catch((err) => {
          dispatch(refCompFailure(err));
          dispatch(refCompRequest(false));
          //history.push("/page-not-found");
        });
    }
  };
};
