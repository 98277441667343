import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import { LogoutUser } from '../../actions/LogOut';
import { fetchUserData } from '../../actions/userPersonalInfo';
import {
  Assessment,
  FetchAssessmentData,
  FetchUserData,
  WithId,
} from '../../models/assessments';
import { User } from '../../models/user';
import {
  getLocalStorageItem,
  localStorageKey,
} from '../../services/localStorage';
import setToken from '../../setToken';
import i18next from 'i18next';
import { getLanguageLabel, LANGUAGES } from '../../models/language';
import { bindActionCreators } from 'redux';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import DOMPurify from 'dompurify';

type RouteProps = RouteComponentProps<WithId>;

export interface HeaderProps extends RouteProps {
  userInfo?: User;
  vacancy?: any;
  assessment?: Assessment;
  LogoutUser: (history: RouteProps['history']) => void;
  fetchUserData: FetchUserData;
  fetchAssessmentByIdData: (assessmentId: Assessment['id']) => void;
  fetchAssessmentData: FetchAssessmentData;
  isDashboardHeader?: boolean;
  game?: boolean;
  test?: boolean;
  link?: string;
  logo?: string;
  position?: React.ReactNode;
  logoURL?: string;
  logoAlt?: string;
  hideAccountDropdown?: boolean;
  testType?: string;
  companylogo?: string;
  i18n: any;
}

export interface HeaderState {
  accountMenuOpen: boolean;
  languageMenuOpen: boolean;
  pageFound: boolean;
  consentMenu: boolean;
  activeLanguage: LANGUAGES | null;
}

class Header extends Component<HeaderProps, HeaderState> {
  state = {
    accountMenuOpen: false,
    languageMenuOpen: false,
    pageFound: true,
    consentMenu: true,
    activeLanguage: null,
  };

  accountDropdownNode: React.MutableRefObject<HTMLDivElement | null> = React.createRef();
  languageDropdownNode: React.MutableRefObject<HTMLDivElement | null> = React.createRef();

  componentDidMount() {
    const token = localStorage.getItem('token');
    const {
      fetchUserData,
      fetchAssessmentByIdData,
      userInfo,
      match,
      vacancy,
      test,
      assessment,
      fetchAssessmentData,
      testType,
    } = this.props;

    if (token && !userInfo) {
      setToken(token);
      fetchUserData();
    }
    if (test && token && !!vacancy && match.params.id) {
      fetchAssessmentByIdData(Number(match.params.id));
    }
    if (token && !assessment && testType) {
      fetchAssessmentData(vacancy.id);
    }

    const { i18n } = this.props;
    document.body.dir = i18n.dir();

    if (i18next.language.slice(0, 2) === LANGUAGES.EN) {
      this.setState({ activeLanguage: LANGUAGES.EN });
      localStorage.setItem('contentDirection', CONTENT_DIRECTION.LTR);
    } else {
      const isArabic = i18next.language === LANGUAGES.AR;
      this.setState({
        activeLanguage: isArabic ? LANGUAGES.AR : LANGUAGES.EN,
      });
      localStorage.setItem(
        'contentDirection',
        isArabic ? CONTENT_DIRECTION.RTL : CONTENT_DIRECTION.LTR,
      );
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mouseup',
      this.handleAccountDropdownClick,
      false,
    );
  }

  handleAccountDropdownClick = (e: MouseEvent) => {
    if (
      this.accountDropdownNode.current &&
      !this.accountDropdownNode.current.contains(e.target as Node)
    ) {
      this.setState({ accountMenuOpen: false });
    }
  };

  handleLanguageDropdownClick = (e: MouseEvent) => {
    if (
      this.languageDropdownNode.current &&
      !this.languageDropdownNode.current.contains(e.target as Node)
    ) {
      this.setState({ languageMenuOpen: false });
    }
  };

  onOpenAccountMenu = () => {
    const { accountMenuOpen } = this.state;
    this.setState({ accountMenuOpen: !accountMenuOpen });
    document.addEventListener(
      'mouseup',
      this.handleAccountDropdownClick,
      false,
    );
  };

  onOpenLanguageMenu = () => {
    const { languageMenuOpen } = this.state;
    this.setState({ languageMenuOpen: !languageMenuOpen });
    document.addEventListener(
      'mouseup',
      this.handleLanguageDropdownClick,
      false,
    );
  };

  changeLanguage = (lng: any) => {
    // @ts-ignore
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
    this.setState({
      activeLanguage: lng,
      languageMenuOpen: false,
    });
    document.body.dir = i18n.dir();

    if (i18n.dir() === CONTENT_DIRECTION.RTL) {
      localStorage.setItem('contentDirection', CONTENT_DIRECTION.RTL);
    } else {
      localStorage.setItem('contentDirection', CONTENT_DIRECTION.LTR);
    }
  };

  render() {
    const refCompanyData = getLocalStorageItem(localStorageKey.refCompanyData);
    const { props } = this;
    const { accountMenuOpen, languageMenuOpen } = this.state;
    const token = localStorage.getItem('token');
    // @ts-ignore
    const { t } = props;
    const direction = localStorage.getItem('contentDirection') as string;

    const headerLogo = this.props.logoURL ? (
      <img
        className="header-logo"
        src={this.props.logoURL}
        alt={this.props.logoAlt}
      />
    ) : refCompanyData && refCompanyData.logo ? (
      <img
        className="header-logo"
        src={DOMPurify.sanitize(refCompanyData.logo)}
        alt={refCompanyData.name}
      />
    ) : (
      <img
        className="header-logo"
        src="/assets/img/logo.png"
        alt="WhiteboxHR"
      />
    );

    let logoHREF = '';
    let consentHeaderMenu = true;
    if (props.userInfo && token) {
      if (!props.userInfo.consent_provided) {
        logoHREF = '#';
        consentHeaderMenu = false;
      } else {
        logoHREF = '/dashboard';
      }
    } else {
      logoHREF = '/';
    }
    return (
      <>
        <header
          className={`${
            props.isDashboardHeader ? 'header dashboard-header' : 'header'
          }`}
        >
          {props.companylogo ? (
            <Link to={logoHREF} className="header-logo-link">
              <img
                className="header-logo"
                src={props.companylogo}
                alt="WhiteboxHR"
              />
            </Link>
          ) : props.userInfo ? (
            <Link to={logoHREF} className="header-logo-link">
              {headerLogo}
            </Link>
          ) : (
            <Link to={logoHREF} className="header-logo-link"></Link>
          )}
          {!props.game && props.logo && (
            <div
              style={{
                position: 'absolute',
                right: '225px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              className="content"
            >
              <Link to={props.link || '/profile'} style={{ fontSize: '12px' }}>
                {props.position}
              </Link>
              <img src={props.logo} alt="company" className="companyLogo" />
            </div>
          )}

          <div className="d-flex no-print">
            <div
              className={`${
                direction === CONTENT_DIRECTION.RTL ? 'mr-4' : 'ml-4'
              } mlr0s`}
            >
              <div
                className="d-flex align-items-center dropDownMenus"
                ref={this.languageDropdownNode}
                onClick={this.onOpenLanguageMenu}
                style={{ cursor: 'pointer' }}
              >
                <p className="language-control">
                  <i className="fas fa-globe"></i>
                  <span className="px-2">
                    {getLanguageLabel(this.state.activeLanguage)}
                  </span>
                </p>
                <span>
                  {languageMenuOpen ? (
                    <i
                      className="fas fa-chevron-up"
                      style={{ fontSize: ' 10px' }}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-chevron-down"
                      style={{ fontSize: ' 10px' }}
                    ></i>
                  )}
                </span>

                <div
                  className={`${languageMenuOpen ? 'block' : 'none'} ${
                    direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                  }`}
                >
                  <ul
                    style={{
                      padding: '0',
                      listStyle: 'none',
                      margin: '0',
                      width: '100%',
                    }}
                  >
                    <li
                      className="listItem"
                      onClick={() => this.changeLanguage('en')}
                    >
                      <span className="headerLinks">
                        <span className="spanItem dropDownItem">
                          <span className="text-align-start">
                            {getLanguageLabel(LANGUAGES.EN)}
                          </span>
                        </span>
                      </span>
                    </li>

                    <li
                      className="listItem"
                      onClick={() => this.changeLanguage('ar')}
                    >
                      <span className="headerLinks">
                        <span className="spanItem dropDownItem">
                          <span className="text-align-start">
                            {getLanguageLabel(LANGUAGES.AR)}
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {props.userInfo &&
              token &&
              (this.props.hideAccountDropdown ? null : (
                <div
                  className={` ${
                    direction === CONTENT_DIRECTION.RTL ? 'mr-4' : 'ml-4'
                  }`}
                >
                  <div
                    className="d-flex align-items-center dropDownMenus"
                    ref={this.accountDropdownNode}
                    onClick={this.onOpenAccountMenu}
                    style={{ cursor: 'pointer' }}
                  >
                    <p className="profile-user-name">
                      <i className="far fa-user-circle"></i>
                      <span className="px-2">{props.userInfo.first_name}</span>
                    </p>
                    <span>
                      {accountMenuOpen ? (
                        <i
                          className="fas fa-chevron-up"
                          style={{ fontSize: ' 10px' }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-chevron-down"
                          style={{ fontSize: ' 10px' }}
                        ></i>
                      )}
                    </span>

                    <div
                      className={`${accountMenuOpen ? 'block' : 'none'} ${
                        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                      }`}
                    >
                      <ul
                        style={{
                          padding: '0',
                          listStyle: 'none',
                          margin: '0',
                          width: '100%',
                        }}
                      >
                        <li className="listItem">
                          <Link to="/profile" className="headerLinks">
                            <span className="spanItem dropDownItem">
                              <i className="fas fa-cog dropDownIcon"></i>
                              <span className="text-align-start">
                                {t('My Profile')}
                              </span>
                            </span>
                          </Link>
                        </li>

                        {consentHeaderMenu ? (
                          <li className="listItem">
                            <Link to="/dashboard" className="headerLinks">
                              <span className="spanItem dropDownItem">
                                <i className="fas  fa-tachometer-alt dropDownIcon"></i>
                                <span className="text-align-start">
                                  {t('Dashboard')}
                                </span>
                              </span>
                            </Link>
                          </li>
                        ) : null}
                        {consentHeaderMenu ? (
                          <li className="listItem">
                            <Link to="/change-password" className="headerLinks">
                              <span className="spanItem dropDownItem">
                                <i className="fas fa-lock dropDownIcon"></i>
                                <span className="text-align-start">
                                  {t('Change Password')}
                                </span>
                              </span>
                            </Link>
                          </li>
                        ) : null}

                        <li
                          className="listItem"
                          onClick={() => props.LogoutUser(props.history)}
                        >
                          <span className="headerLinks">
                            <span className="spanItem dropDownItem">
                              <i className="fas fa-sign-out-alt dropDownIcon"></i>
                              <span className="text-align-start">
                                {t('Logout')}
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </header>

        <div className="header-border"></div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.authUser.userInfo,
    vacancy: state.getAssessmentByVacancyId.vacancy,
    assessment: state.assessmentsReduces.assessment,
  };
};

// @ts-ignore
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      LogoutUser,
      fetchUserData,
      fetchAssessmentByIdData,
      fetchAssessmentData,
    },
    dispatch,
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)),
);
