import axios from 'axios';
import {
  VIDEO_ASSESSMENTS_REQUEST,
  VIDEO_ASSESSMENTS_SUCCESS,
  VIDEO_ASSESSMENTS_FAILURE,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const getVideoAssessmentsRequest = (videoassessmentsLoading) => {
  return {
    type: VIDEO_ASSESSMENTS_REQUEST,
    payload: {
      videoassessmentsLoading,
    },
  };
};

const getVideoAssessmentsSuccess = (videoAssessment) => {
  return {
    type: VIDEO_ASSESSMENTS_SUCCESS,
    payload: {
      videoAssessment,
    },
  };
};

const getVideoAssessmentsFailure = (videoError) => {
  return {
    type: VIDEO_ASSESSMENTS_FAILURE,
    payload: {
      videoError,
    },
  };
};

export const fetchVideoAssessmentData = (assessmentId, history) => {
  return async (dispatch) => {
    dispatch(getVideoAssessmentsRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios(`${BASE_URL}${PATH}assessment/${assessmentId}/video/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        if (res.status === 400) {
          history.push(`/assessment-error/${assessmentId}`);
        }
        return res;
      })
      .then((res) => {
        dispatch(getVideoAssessmentsSuccess(res.data.link));
        dispatch(getVideoAssessmentsRequest(false));
      })
      .catch((err) => {
        if (err.response.status === 400) {
          history.push(`/assessment-error/${assessmentId}`);
        }
        unauthorized(err.response.status);
        dispatch(getVideoAssessmentsFailure(err));
        dispatch(getVideoAssessmentsRequest(false));
      });
  };
};

export const fetchVideoAssessmentDataEnd = (assessmentId, history) => {
  return async (dispatch) => {
    dispatch(getVideoAssessmentsRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios(`${BASE_URL}${PATH}assessment/${assessmentId}/video/end/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        if (res.status === 400) {
          history.push(`/assessment-error/${assessmentId}`);
        }
        return res;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          history.push(`/assessment-error/${assessmentId}`);
        }
        unauthorized(err.response.status);
      });
  };
};
