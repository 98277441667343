export const Game8 = {
  is_timer_countdown: true,
  timer_seconds: 600,
  games: [
    {
      task: 'ink_color',
      first: 'green',
      second: 'yellow',
      secondColor: '#f04f4f',
      answer: false,
    },
    {
      task: 'ink_color',
      first: 'orange',
      second: 'violet',
      secondColor: 'orange',
      answer: true,
    },
    {
      task: 'picture',
      first: 'color pencils',
      second: '',
      src: '/assets/img/8/penciles.png',
      answer: true,
    },
  ],
};
