import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { gameInfoData } from '../../actions/gameInfo';
import image1 from '../../assets/game8/screen1-en.png';
import image2 from '../../assets/game8/screen2-en.png';
import Loader from '../Loader/Loader';
import PageWrapper from '../PageWrapper';
import { withTranslation } from 'react-i18next';

class GameStart extends React.Component {
  componentDidMount() {
    const { match, gameInfoData, history } = this.props;
    gameInfoData(match.params.id, history);
  }

  startGame = () => {
    const { history } = this.props;
    history.push(`/game/8${1035}`);
  };

  render() {
    const { game, isLoading, t } = this.props;

    if (!game || isLoading) {
      return <Loader />;
    }
    const nextIcon = (
      <i className="fas fa-chevron-right" style={{ color: '#565656' }}></i>
    );
    const prevIcon = (
      <i className="fas fa-chevron-left" style={{ color: '#565656' }}></i>
    );
    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <Link
          to={`/game-assessments/${game.assessment_id}`}
          className="goVacancy"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className="arrowRight"
            alt="arrow"
          />
          {t('Back to all game based assessments')}
        </Link>
        <div className="text-align-start">
          <h1 className="content-title startTest">
            {t('You are about to start the game')} <b>{t(game.title)}</b>
          </h1>
          <p
            style={{ marginBottom: '15px', fontSize: '15px' }}
            className="content-text-assessment"
          >
            {t('Please go through the instructions below')}
          </p>
          <div className="infoContainer sliderWrapper">
            <Carousel nextIcon={nextIcon} prevIcon={prevIcon}>
              <Carousel.Item>
                <img
                  className="d-block sliderImage"
                  src={image1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block sliderImage"
                  src={image2}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="gameInfoBottomWrapper">
          <div className="gameInfoButtons">
            <div />
            <div />
            <div className="infoContainer">
              <div className="infoWrapper">
                <img src="/assets/img/clock.svg" alt="" className="icon" />
                <div className="textInfo">
                  <p className="Infotype text-uppercase">
                    {t('Estimated time')}
                  </p>
                  <p>{game.estimated_time}</p>
                </div>
              </div>
            </div>
            <button
              style={{
                margin: '50px 0',
                fontSize: '10px',
                padding: '1px 28px',
              }}
              className="test-result-block-button"
              onClick={this.startGame}
            >
              {t('Start the game now')}
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.gameTest.gameInfoLoading,
    game: state.gameTest.gameInfoData,
    error: state.gameTest.gameInfoError,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { gameInfoData })(GameStart),
);
