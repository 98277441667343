import React from 'react';
import style from './style.module.css';

const Fade = ({ children, delay }) => (
  <div className={style.fadeAnimation} style={{ animationDelay: `${delay}ms` }}>
    {children}
  </div>
);

export default Fade;
