import {
  GET_ASSESSMENT_BY_ID_REQUEST,
  GET_ASSESSMENT_BY_ID_SUCCESS,
  GET_ASSESSMENT_BY_ID_FAILURE,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  vacancy: {},
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case GET_ASSESSMENT_BY_ID_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case GET_ASSESSMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_ASSESSMENT_BY_ID_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
