import React, { Component, Fragment } from 'react';
import { parse } from 'query-string';
import { withTranslation } from 'react-i18next';

import style from '../../game7/style.module.scss';
import GameHeader from '../../GameHeader';
import Modal from '../../ModalDeleteVacancy';
import { Game7 } from './mock';
import Toolpit from '../../HelpPopUp';
import PageWrapper from '../../PageWrapper';

class GameSeven extends Component {
  state = {
    round: 0,
    disabled: false,
  };

  componentDidMount() {
    this.setState({ data: Game7.games });
  }

  stopGameHandler = () => {
    this.setState({ openStopModal: true });
  };

  continueGameModal = () => {
    this.setState({ openStopModal: false });
  };

  onCloseGameModal = () => {
    const { history, match } = this.props;
    history.push(`/game-info/7${match.params.id}`);
  };

  getNextRound = () => {
    const { round, data, isChecked } = this.state;
    if (round < Object.keys(data).length - 1 && isChecked) {
      this.setState({ round: round + 1, tap: false, isChecked: false });
    } else if (round === Object.keys(data).length - 1) {
      this.setState({ tap: false, isChecked: false });
    }
  };

  startGameNew = () => {
    const { history, match } = this.props;
    const testId = parse(this.props.location.search)['test'];

    history.replace(`/game/7${match.params.id}?test=${testId}`);
  };

  addCheckedStyle = (index) => {
    const { isChecked } = this.state;
    if (index === isChecked) {
      return `${style.isChecked} ${style.active} ${style.isCheckedExample}`;
    } else {
      return `${style.isChecked} ${style.isCheckedExample}`;
    }
  };

  checkItem = (index, selectedItem) => {
    const { isChecked, end, round, data } = this.state;
    if (round === Object.keys(data).length - 1) {
      this.setState({ end: true });
    }
    if (!isChecked && !end) {
      const newFlag = isChecked === index ? false : index;
      this.setState({ isChecked: newFlag, selectedItem, tap: true });
    }
  };

  render() {
    const {
      data,
      round,
      openStopModal,
      tap,
      selectedItem,
      end,
      isChecked,
    } = this.state;
    const { history, t } = this.props;
    if (!data) {
      return <h1>{t('Loading')}</h1>;
    }

    return (
      <Fragment>
        {openStopModal && (
          <Modal
            onCancel={this.continueGameModal}
            onDelete={this.onCloseGameModal}
            text={t(
              'Are you sure want to end game? Once you exit you cannot restart the gam',
            )}
            bntCancel={t('Cancel')}
            btnSuccess={t('End game')}
          />
        )}
        <PageWrapper isDashboardHeader history={history} isDashboardFooter>
          <div className="w-100 mb-5">
            <GameHeader
              stopGameHandler={this.stopGameHandler}
              currentTask={round + 1}
              allTask={Object.keys(data).length}
              timerSeconds={null}
              canForceEnd={false}
              gameType={t('Practice Run', { title: t('Rotate It') })}
            />
            <div className={style.gameTaskWrapper}>
              <div className={style.gameTask}>
                <div className={style.gameMainImage}>
                  <img
                    className={style.mainImage}
                    src={data[round].main_image.path}
                    alt=""
                  />
                </div>
                <p className={style.gameDescriptionTwo}>
                  {t('Select the correct shape when above image is rotated')}
                </p>
              </div>
              <div className={style.answerList}>
                {data[round].options.map((el, index) => (
                  <div
                    key={index}
                    onClick={() => this.checkItem(index + 1, el)}
                    className={style.answerItem}
                  >
                    <img src={el.path} alt="" />
                    <div className={this.addCheckedStyle(index + 1)}>
                      <div className="selectedItemTestGames rotateIt">
                        <i className="fas fa-check"></i>
                      </div>
                    </div>
                    {tap &&
                      selectedItem.id === el.id &&
                      data[round].answer !== selectedItem.id && (
                        <div
                          className={`selectedItemTestGames rotateIt ${
                            data[round].answer === selectedItem.id
                              ? ''
                              : 'incorrect'
                          }`}
                        >
                          {data[round].answer === selectedItem.id &&
                          data[round].answer !== selectedItem.id ? (
                            <i className="fas fa-check"></i>
                          ) : (
                            <i className="fas fa-times"></i>
                          )}
                        </div>
                      )}
                    {tap &&
                      data[round].answer !== selectedItem.id &&
                      el.id === data[round].answer && (
                        <div className="selectedItemTestGames rotateIt">
                          <i className="fas fa-check"></i>
                        </div>
                      )}
                  </div>
                ))}
                {isChecked ? (
                  <Toolpit
                    status={data[round].answer === selectedItem.id}
                    game={'Rotate It'}
                  />
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                disabled={end}
                className={`${
                  !isChecked || end
                    ? `${style.buttonNext} ${style.disabledBtn}`
                    : style.buttonNext
                }
                  ${
                    round !== Object.keys(data).length - 1
                      ? 'd-block'
                      : 'd-none'
                  }`}
                onClick={this.getNextRound}
              >
                {t('Next')}
              </button>
              {
                <button
                  disabled={!end}
                  className={`${style.buttonNext} ${
                    end ? 'd-block' : 'd-none'
                  }`}
                  onClick={this.startGameNew}
                >
                  {t('Start Assessment')}
                </button>
              }
            </div>
          </div>
        </PageWrapper>
      </Fragment>
    );
  }
}

export default withTranslation()(GameSeven);
