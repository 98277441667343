import axios from 'axios';
import { parse } from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  changeDriversState,
  сareerDriverFetchData,
} from '../../actions/careerDriversAction';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import { BASE_URL, PATH } from '../../constants/variables';
import CareerDriversMobile from './CareerDriversMobile';
import CareerDriversDesktop from './CareerDriversDesktop';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

const insert = (index, item, array) =>
  array.slice(0, index).concat(item).concat(array.slice(index));

class CareerDrivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drivers: {},
      driverIds: [],
      categories: [],
      isWarningMessage: false,
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();

    const {
      сareerDriverFetchData,
      fetchAssessmentByIdData,
      match,
    } = this.props;
    const queryObj = parse(this.props.location.search);
    сareerDriverFetchData(queryObj.test, this.callBack);
    fetchAssessmentByIdData(match.params.id);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const mediaQueryList = matchMedia('screen and (max-width: 767px)');
    const { matches } = mediaQueryList;
    const { isTablet } = this.state;

    if (isTablet !== matches) {
      this.setState({ isTablet: matches });
    }
  };

  callBack = (data) => {
    this.setState({
      drivers: Object.fromEntries(
        data.results[0].drivers.map((driver) => [driver.id, driver]),
      ),
      driverIds: data.results[0].drivers.map((driver) => driver.id),
      categories: data.results[0].categories.map((category) => ({
        ...category,
        driverIds: [],
      })),
    });
  };

  updateStateOnDragEnd = (result) => {
    this.setState((state) => {
      // * rearranged inside the same category
      let newCategories;
      if (result.source.droppableId === result.destination.droppableId) {
        newCategories = state.categories.map((category) =>
          category.id === +result.destination.droppableId
            ? {
                ...category,
                driverIds: insert(
                  result.destination.index,
                  +result.draggableId,
                  category.driverIds.filter((id) => id !== +result.draggableId),
                ),
              }
            : category,
        );
      } else {
        // * moved for the first time
        if (result.source.droppableId === 'rows') {
          newCategories = state.categories.map((category) =>
            category.id === +result.destination.droppableId
              ? {
                  ...category,
                  driverIds: insert(
                    result.destination.index,
                    +result.draggableId,
                    category.driverIds,
                  ),
                }
              : category,
          );
        } else {
          // * moved between categories
          newCategories = state.categories.map((category) =>
            category.id === +result.destination.droppableId
              ? {
                  ...category,
                  driverIds: insert(
                    result.destination.index,
                    +result.draggableId,
                    category.driverIds,
                  ),
                }
              : category.id === +result.source.droppableId
              ? {
                  ...category,
                  driverIds: category.driverIds.filter(
                    (id) => id !== +result.draggableId,
                  ),
                }
              : category,
          );
        }

        const isWarningMessage = newCategories.some(
          (category) => category.driverIds.length > 5,
        );
        const isForbidden = newCategories.some(
          (category) => category.driverIds.length > 6,
        );

        return {
          categories: isForbidden ? [...state.categories] : [...newCategories],
          isWarningMessage,
        };
      }
    });
  };

  handleCheckedChange = (e, categoryId, driverId) => {
    this.setState((state) => ({
      categories: state.categories.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            driverIds: [
              ...(category.driverIds.some((driver) => driver === driverId)
                ? [
                    ...category.driverIds.filter(
                      (driver) => driver !== driverId,
                    ),
                  ]
                : [...category.driverIds, driverId]),
            ],
          };
        } else {
          return category;
        }
      }),
    }));
  };

  handleSubmit = () => {
    const { t } = this.props;

    try {
      let domElement = document.getElementsByClassName('sbmt')[0];
      domElement.disabled = true;
      domElement.style.cursor = 'not-allowed';
      domElement.style.padding = '10px';
      domElement.textContent = t('Please wait Submitting');
    } catch (e) {}

    const data = this.state.categories.map((category) => ({
      category_id: category.id,
      drivers: category.driverIds.map((id) => this.state.drivers[id]),
    }));

    const id = this.props.careerDriversData.results[0].id;
    axios
      .post(`${BASE_URL}${PATH}answers/`, {
        assessment: this.props.match.params.id,
        question: id,
        data,
      })
      .then((res) => {
        if (res.status) {
          axios
            .put(
              `${BASE_URL}${PATH}assessments/${this.props.match.params.id}/complete/`,
            )
            .then((res) => {
              if (res.status === 200) {
                // this.props.history.push(`/assessments/${application_id}`);
                this.props.history.replace(
                  `/assessments/${this.props.match.params.id}/tests-completed/`,
                );
              }
            });
        }
      });
  };

  canSubmit = () => {
    return (
      // * business rule
      this.state.categories.reduce(
        (canSubmit, category) => canSubmit && category.driverIds.length >= 5,
        true,
      )
    );
  };

  render() {
    const { history, isLoading, assessment, t } = this.props;
    const { drivers, driverIds, categories, isTablet } = this.state;

    if (isLoading || !assessment) {
      return <Loader />;
    }
    if (assessment.status === 2) {
      return (
        <Redirect
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
        />
      );
    }

    const isDisabled = !this.canSubmit();
    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper isDashboardHeader history={history} isDashboardFooter>
        <Link
          to="#"
          onClick={() =>
            history.push(
              `/assessments/${
                store.getState().assessmentsReduces.currentApplicationId
              }`,
            )
          }
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back to assessment dashboard')}
        </Link>
        <div className="text-align-start">
          <h1 className="content-title test-example">
            {t(assessment.test.title)}
          </h1>
          <p
            className="content-text content-text-preparation"
            dangerouslySetInnerHTML={{
              __html: t('driversAssessmentDescription', {
                action: isTablet
                  ? t('Drivers select action')
                  : t('Drivers drag and drop action'),
              }),
            }}
          ></p>

          {isTablet ? (
            <CareerDriversMobile
              drivers={drivers}
              driverIds={driverIds}
              categories={categories}
              handleCheckedChange={this.handleCheckedChange}
            />
          ) : (
            <CareerDriversDesktop
              drivers={drivers}
              driverIds={driverIds}
              categories={categories}
              updateStateOnDragEnd={this.updateStateOnDragEnd}
              isWarningMessage={this.state.isWarningMessage}
            />
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              disabled={isDisabled}
              onClick={this.handleSubmit}
              className={'tr8s-button mb green assessmentBtnNext sbmt '}
              style={{
                margin: '25px auto',
                opacity: isDisabled ? 0.5 : 1,
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
            >
              {t('Submit')}
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.careerDriver.isLoading,
    assessment: state.careerDriver.assessment,
    careerDriversData: state.careerDriver.careerDriversData,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    сareerDriverFetchData,
    changeDriversState,
    fetchAssessmentByIdData,
  })(CareerDrivers),
);
