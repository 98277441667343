import {
  GET_VACANCY_REQUEST,
  GET_VACANCY_SUCCESS,
  GET_VACANCY_FAILURE,
  DELETE_VACANCY_REQUEST_SUCCESS,
  REACTIVATE,
  REACTIVATE_SUCCESS,
  REACTIVATE_ERROR,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  vacancies: [],
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case GET_VACANCY_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case REACTIVATE: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case REACTIVATE_SUCCESS: {
      state.vacancies.pending.push(payload.vacancy);
      const index = state.vacancies.deleted.indexOf(
        (el) => +el.id !== +payload.vacancy.id,
      );
      state.vacancies.deleted.splice(index, 1);

      return {
        ...state,
        vacancies: [...state.vacancies],
      };
    }
    case REACTIVATE_ERROR: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case GET_VACANCY_SUCCESS: {
      return {
        ...state,
        vacancies: payload.vacancies,
      };
    }
    case GET_VACANCY_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case DELETE_VACANCY_REQUEST_SUCCESS: {
      return {
        ...state,
        vacancies: payload.vacancies,
      };
    }
    default: {
      return state;
    }
  }
}
