import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { LANGUAGES } from '../models/language';

export const useLanguage = () => {
  const [language, setLanguage] = useState(null);
  const selectedLang = i18next.language;

  useEffect(() => {
    if (selectedLang.slice(0, 2) === LANGUAGES.EN) {
      setLanguage(LANGUAGES.EN);
    } else {
      setLanguage(selectedLang === LANGUAGES.AR ? LANGUAGES.AR : LANGUAGES.EN);
    }
  }, [selectedLang]);

  return language;
};
