import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface CircularProgressProps {
  percentage: number;
  texttoshow: string;
  width?: number;
  height?: number;
  progressColor?: string;
  textColor?: string;
}

const CircularProgress = ({
  percentage,
  texttoshow,
  width,
  height,
  progressColor,
  textColor,
}: CircularProgressProps) => {
  return (
    <CircularProgressbar
      value={percentage}
      text={texttoshow}
      background={false}
      styles={{
        // Customize the root svg element
        root:
          width && height
            ? {
                width: width,
                height: height,
              }
            : {},
        // Customize the path, i.e. the "completed progress"
        path: {
          // Path color
          // circleRatio: 0,
          stroke: progressColor || '#25a4a1',
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'round',
          // Customize transition animation
          transition: 'stroke-dashoffset 0.2s ease 2s',
          // Rotate the path
          transformOrigin: 'center center',
        },
        // Customize the circle behind the path, i.e. the "total progress"
        trail: {
          // circleRatio: 0,
          // Trail color
          stroke: '#d5d6d5',
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'round',
          // Rotate the trail
          transformOrigin: 'center center',
          transition: 'stroke-dashoffset 0.2s ease 2s',
        },
        // Customize the text
        text: {
          // Text color
          fill: textColor || progressColor || '#25a4a1',
          fontSize: '30px',
          fontWeight: 'bold',
        },
        // Customize background - only used when the `background` prop is true
        background: {
          fill: '',
        },
      }}
    />
  );
};

export default CircularProgress;
