import axios from 'axios';
import {
  GET_NEXT_ROUND,
  GET_NEXT_ROUND_ERROR,
  GET_NEXT_ROUND_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';

function getNextRound(loading) {
  return {
    type: GET_NEXT_ROUND,
    payload: { loading },
  };
}

function getNextRoundSuccess(obj, type) {
  let payload;
  if (type === 9) {
    payload = game9Data(obj);
  } else if (type === 10) {
    payload = game10Data(obj);
  }

  return {
    type: GET_NEXT_ROUND_SUCCESS,
    payload: payload,
  };
}

function getNextRoundError(error) {
  return {
    type: GET_NEXT_ROUND_ERROR,
    payload: { error },
  };
}

export const fetchGetNextRoundData = (
  game,
  data,
  history,
  page = 1,
  gameType,
) => {
  return async (dispatch) => {
    await dispatch(getNextRound(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios

      .get(`${BASE_URL}${PATH}tests/${game}/questions/?page=${page}`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        dispatch(getNextRound(false));
        return res;
      })
      .then((res) => dispatch(getNextRoundSuccess(res.data, gameType)))
      .catch((err) => {
        history.push(`/game-error/${data.game_id}`);
        dispatch(getNextRoundError(err));
      });
  };
};

const game9Data = (data) => {
  const round = () => {
    if (data.next === null && data.previous === null) {
      return 1;
    }
    if (data.next !== null) {
      const next = data.next.split('=')[1];
      return +next - 1;
    }
    if (data.previous !== null) {
      let previous = data.previous.split('=')[1];
      if (typeof previous === 'undefined') {
        previous = 1;
      }
      return +previous + 1;
    }
  };
  return {
    game_id: data.results[0].id,
    rounds: data.count,
    timer_seconds: data.results[0].timer_seconds,
    round: round(),
    options: data.results[0].options,
    end: data.next === null ? 1 : 0,
    left: {
      id: data.results[0].id + '1',
      image: data.results[0].left_image,
    },
    right: {
      id: data.results[0].id + '2',
      image: data.results[0].right_image,
    },
  };
};

const game10Data = (data) => {
  const round = () => {
    if (data.next === null && data.previous === null) {
      return 1;
    }
    if (data.next !== null) {
      const next = data.next.split('=')[1];
      return +next - 1;
    }
    if (data.previous !== null) {
      let previous = data.previous.split('=')[1];
      if (typeof previous === 'undefined') {
        previous = 1;
      }
      return +previous + 1;
    }
  };
  return {
    game_id: data.results[0].id,
    rounds: data.count,
    timer_seconds: 120, //data.results[0].timer_seconds,
    round: round(),
    options: data.results[0].options,
    end: data.next === null ? 1 : 0,
    main_image_left: {
      id: data.results[0].id + '1',
      path: data.results[0].left_image,
    },
    main_image_right: {
      id: data.results[0].id + '2',
      path: data.results[0].right_image,
    },

    operation: data.results[0].operation,
  };
};
