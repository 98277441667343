import React from 'react';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';

export interface PrintFooterProps {
  name: string | null;
}

const PrintFooter: React.FC<PrintFooterProps> = (props) => {
  const date = new Date();
  const { t } = useTranslation();

  return (
    <footer className="print-footer mt-4 d-none justify-content-between position-fixed print-footer">
      {t('Report generated by WhiteBox HR with date', {
        name: props.name,
        date: dateFormat(date, 'dS mmm yyyy'),
      })}
    </footer>
  );
};

export default PrintFooter;
