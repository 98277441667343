import { SET_CURRENT_APPLICATION_ID } from '../constants/ActionTypes';

export const setCurrentApplicationId = (currentApplicationId) => {
  return {
    type: SET_CURRENT_APPLICATION_ID,
    payload: {
      currentApplicationId,
    },
  };
};
