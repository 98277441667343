import { SORTED_ITEM } from '../constants/ActionTypes';

const sorted = (options) => {
  return {
    type: SORTED_ITEM,
    payload: {
      options,
    },
  };
};

export const sortedItem = (items) => {
  return async (dispatch) => {
    dispatch(sorted(items));
  };
};
