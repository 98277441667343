import {
  START_GAME,
  START_GAME_SUCCESS,
  START_GAME_ERROR,
  SUBMIT_ROUND,
  SUBMIT_ROUND_SUCCESS,
  SUBMIT_ROUND_ERROR,
  GET_NEXT_ROUND,
  GET_NEXT_ROUND_SUCCESS,
  GET_NEXT_ROUND_ERROR,
  END_GAME,
  END_GAME_SUCCESS,
  END_GAME_ERROR,
  SORTED_ITEM,
} from '../constants/ActionTypes';

const InitialState = {
  loading: true,
};

const StartGameReducer = (state = InitialState, { type, payload }) => {
  switch (type) {
    case START_GAME:
      return {
        ...state,
        loading: payload.loading,
        end: 0,
        error: null,
      };
    case START_GAME_SUCCESS:
      return {
        ...state,
        game_id: payload.game_id,
        has_timer: payload.has_timer,
        is_timer_countdown: payload.is_timer_countdown,
        rounds: payload.rounds,
        timer_seconds: payload.timer_seconds,
        error: null,
      };
    case START_GAME_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case SUBMIT_ROUND:
      return {
        ...state,
        loading: payload.loading,
        error: null,
      };
    case SUBMIT_ROUND_SUCCESS:
      return {
        ...state,
        end: payload.end,
        error: null,
      };
    case SUBMIT_ROUND_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case GET_NEXT_ROUND:
      return {
        ...state,
        loading: payload.loading,
        error: null,
      };
    case GET_NEXT_ROUND_SUCCESS:
      return {
        ...state,
        game_id: payload.game_id,
        round: payload.round,
        main_image: payload.main_image,
        options: payload.options,
        main_image_left: payload.main_image_left,
        main_image_right: payload.main_image_right,
        operation: payload.operation,
        actions: payload.actions,
        grid: payload.grid,
        start: payload.start,
        left: payload.left,
        right: payload.right,
        error: null,
        type: payload.type,
        question: payload.question,
        top: payload.top,
        bottom: payload.bottom,
        rounds: payload.rounds,
        is_timer_countdown: payload.is_timer_countdown,
        timer_seconds: payload.timer_seconds,
        end: payload.end,
      };
    case GET_NEXT_ROUND_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case END_GAME:
      return {
        ...state,
        loading: payload.loading,
        error: null,
      };
    case END_GAME_SUCCESS:
      return {
        end: payload.end,
        round: state.rounds,
        error: null,
      };
    case END_GAME_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case SORTED_ITEM: {
      return {
        ...state,
        options: payload.options,
      };
    }
    default:
      return state;
  }
};

export default StartGameReducer;
