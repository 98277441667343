import axios from 'axios';
import { get, isArray } from 'lodash';
import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchQuestionData } from '../../actions/personalityTestActions';
import LikertScale from '../../components/LikertScale/LikertScale';
import Loader from '../../components/Loader/Loader';
import Progress from '../../components/TestProgressBar';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import { WithId } from '../../models/assessments';
import PageWrapper from '../../components/PageWrapper';
import TableSurvey from './components/TableSurvey';
import { withTranslation } from 'react-i18next';
import { Scope, TranslateOptions } from 'i18n-js';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

type RouteProps = RouteComponentProps<WithId>;

export interface Page {
  count: number;
  next: null;
  previous: null;
  results: Array<Result & { value?: any }>;
}

enum DisplayFormat {
  DROPDOWN = 1,
  MULTIPLE_SELECTION = 2,
  LIKERT_SCALE = 3,
  FREE_TEXT = 4,
  SURVEY = 5,
}

export interface Result {
  id: number;
  text: string;
  allow_multiple_choices: boolean;
  display_format: DisplayFormat;
  options: Option[];
  type: string;
  expected_knockout_answers: any[];
}

export interface Option {
  id: number;
  text: string;
  value: string;
}

export type SetQuestionsCallback = (data: Page, currentPage: number) => void;

export interface Assessment {
  application_id: number;
  status: number;
  test: {
    title: string;
  };
}

export interface QuestionnaireAssessmentProps extends RouteProps {
  fetchAssessmentData: (id: WithId['id']) => void;
  fetchQuestionData: (
    test: string | string[] | null,
    currentPage: number,
    history: RouteProps['history'],
    setQuestionsCallback: SetQuestionsCallback,
    number: number,
  ) => void;
  assessment: Assessment;
  questionLoading: boolean;
  t: (scope: Scope, options?: TranslateOptions) => string;
}

export interface QuestionnaireAssessmentState {
  pages: Page[];
  currentPage: number;
  isSubmitting: boolean;
}

class QuestionnaireAssessment extends React.Component<
  QuestionnaireAssessmentProps,
  QuestionnaireAssessmentState
> {
  constructor(props: QuestionnaireAssessmentProps) {
    super(props);
    this.state = {
      pages: [],
      currentPage: 0,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    const {
      match,
      history,
      fetchAssessmentData,
      fetchQuestionData,
    } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      fetchAssessmentData(match.params.id);

      const queryString = parse(this.props.location.search);

      const test = 'test' in queryString ? queryString.test ?? null : null;

      fetchQuestionData(
        test,
        this.state.currentPage,
        history,
        this.CallbackSetQuestions,
        10000,
      );
    } else if (!token) {
      history.push('/login');
    }
  }

  CallbackSetQuestions: SetQuestionsCallback = (data) => {
    const sortedQuestions = this.getSortedQuestions(data.results);

    this.setState({
      pages: [
        ...this.state.pages,
        {
          ...data,
          results: sortedQuestions.map((result) =>
            result.display_format === DisplayFormat.LIKERT_SCALE
              ? { ...result, value: 0 }
              : result,
          ),
        },
      ],
      currentPage: this.state.currentPage + 1,
    });
  };

  collectData = () => {
    const collectedData = this.state.pages[0].results.map((questionData) => {
      return {
        assessment: this.props.match.params.id,
        question: questionData.id,
        value: questionData.value,
      };
    });
    return collectedData;
  };

  onSubmitHandler: React.MouseEventHandler = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    const collectedData = this.collectData();

    const newData = collectedData.map((item) => {
      return {
        assessment: item.assessment,
        question: item.question,
        data: { option_id: item.value },
      };
    });

    axios.post(`${BASE_URL}${PATH}answers/`, newData).then((res) => {
      if (res.status === 201) {
        axios
          .put(
            `${BASE_URL}${PATH}assessments/${this.props.match.params.id}/complete/`,
          )
          .then((res) => {
            if (res.status === 200) {
              localStorage.removeItem('personalAssessment');
              this.props.history.push(
                `/assessments/${this.props.match.params.id}/tests-completed/`,
              );
            }
          });
      }
    });
  };

  isDisabled = () => {
    const collectedData = this.collectData();
    let disabled = false;
    collectedData.forEach((element) => {
      const val = get(element, ['value'], false);
      if (!val) {
        disabled = true;
      }
      if (isArray(val) && val.length < 1) {
        disabled = true;
      }
    });

    return disabled;
  };

  isShowSubmit = () => {
    let disabled = false;

    const questions = this.state.pages[this.state.currentPage - 1];
    if (this.state.pages.length < questions.count) {
      disabled = true;
    }
    return disabled;
  };

  stoppers = (n: number) => {
    const arr = [];
    for (let i = 0; i < n; i++) {
      arr.push(i);
    }
    return arr;
  };

  handleChangeSimple = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const pagesCopy = [...this.state.pages];

    pagesCopy[this.state.currentPage - 1].results[index].value = e.target.value;

    this.setState({
      pages: pagesCopy,
    });
  };

  handleChangeMultipleSelection = (
    option: Option,
    index: number,
    isMultiple: boolean,
  ) => {
    const pagesCopy = [...this.state.pages];

    const valueCopy = get(
      pagesCopy,
      [this.state.currentPage - 1, 'results', index, 'value'],
      [],
    );

    if (isMultiple && valueCopy.includes(option.value)) {
      const indexValue = valueCopy.indexOf(option.value);
      valueCopy.splice(indexValue, 1);
    } else if (isMultiple) {
      valueCopy.push(option.value);
    }

    pagesCopy[this.state.currentPage - 1].results[index].value = isMultiple
      ? valueCopy
      : option.value;

    this.setState({
      pages: pagesCopy,
    });
  };

  getSortedQuestions = (data: Result[]): Result[] => {
    let questionMap = new Map<DisplayFormat, Result[]>([
      [DisplayFormat.DROPDOWN, []],
      [DisplayFormat.MULTIPLE_SELECTION, []],
      [DisplayFormat.LIKERT_SCALE, []],
      [DisplayFormat.SURVEY, []],
      [DisplayFormat.FREE_TEXT, []],
    ]);

    data.forEach((question: Result) => {
      const { display_format } = question;
      const oldValues = questionMap.get(display_format);
      if (oldValues) {
        oldValues.push(question);
      }
    });

    const sortedQuestions = Array.from(questionMap.values()).flat();

    return sortedQuestions;
  };

  render() {
    const { assessment, questionLoading, t } = this.props;
    const questions = this.state.pages[this.state.currentPage - 1];
    const { isSubmitting } = this.state;

    if (questionLoading || !assessment || !questions) {
      return <Loader />;
    }
    if (assessment.status === 2) {
      return (
        <Redirect
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
        />
      );
    }

    const sortedSurveyQuestions: Result[] = questions.results.filter(
      (question) => question.display_format === DisplayFormat.SURVEY,
    );
    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        isDashboardHeader
        testType={'personalityData'}
        isDashboardFooter
      >
        <div className="w-100 text-align-start">
          <form>
            <h1
              className="content-title test-example"
              dangerouslySetInnerHTML={{
                __html: `${t(assessment.test.title)}`,
              }}
            ></h1>
            <div className="progressStatus">
              <Progress current={this.state.currentPage} all={1} />
            </div>

            <div className="personality-test-form personality-test-form-position">
              <div className="overflow-auto">
                <div className="questionnaire-assessment">
                  <ul>
                    {questions.results.map((question, index) => {
                      const handleSliderChange: React.ChangeEventHandler<HTMLInputElement> = (
                        e,
                      ) => {
                        const value = Number(e.target.value);
                        this.setState(({ pages, currentPage }) => ({
                          pages: pages.map((page, i) => {
                            const { results, ...rest } = page;

                            return currentPage - 1 === i
                              ? {
                                  ...rest,
                                  results: results.map((result, j) => {
                                    return j === index
                                      ? {
                                          ...result,
                                          value,
                                        }
                                      : result;
                                  }),
                                }
                              : page;
                          }),
                        }));
                      };

                      return (
                        <li key={question.id}>
                          <div className="w-100">
                            {question.display_format !==
                              DisplayFormat.SURVEY && (
                              <p className="test-question-title">
                                <span className="questionnaire-assessment__question-number">
                                  {index + 1}.
                                </span>{' '}
                                {question.text}
                              </p>
                            )}
                            {question.display_format === DisplayFormat.SURVEY &&
                              question.id === sortedSurveyQuestions[0].id && (
                                <TableSurvey
                                  questions={sortedSurveyQuestions}
                                  startIndex={index}
                                  onChangeRadio={this.handleChangeSimple}
                                />
                              )}

                            {question.display_format ===
                              DisplayFormat.DROPDOWN && (
                              <div>
                                <select
                                  value={
                                    this.state.pages[this.state.currentPage - 1]
                                      .results[index]?.value || ''
                                  }
                                  onChange={(e) =>
                                    this.handleChangeSimple(e, index)
                                  }
                                  required
                                  className="form-control questionnaire-assessment__select-question"
                                >
                                  <option value="" disabled hidden>
                                    {t('Select answer')}
                                  </option>
                                  {question.options.map((option, i) => (
                                    <option
                                      key={option.id}
                                      value={option.value}
                                    >
                                      {option.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}

                            {question.display_format ===
                              DisplayFormat.MULTIPLE_SELECTION &&
                              !question.allow_multiple_choices && (
                                <div className="row">
                                  {question.options.map((option, i) => (
                                    <div
                                      key={option.id}
                                      className="col-12 col-md-6"
                                    >
                                      <div className="form-group mb-2">
                                        <label className="checkbox-container">
                                          {option.text}
                                          <input
                                            type="radio"
                                            id={`${option.id}`}
                                            value={option.value}
                                            onChange={() =>
                                              this.handleChangeMultipleSelection(
                                                option,
                                                index,
                                                false,
                                              )
                                            }
                                            name={`${option.id}`}
                                            checked={
                                              option.value ===
                                              questions.results[index]['value']
                                            }
                                          />
                                          <span className="checkmark radio" />
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            {question.display_format ===
                              DisplayFormat.MULTIPLE_SELECTION &&
                              question.allow_multiple_choices && (
                                <div className="row">
                                  {question.options.map((option, i) => (
                                    <div key={i} className="col-12 col-md-6">
                                      <div className="form-group mb-2">
                                        <label
                                          className={`checkbox-container ${
                                            direction === CONTENT_DIRECTION.RTL
                                              ? 'rtl'
                                              : ''
                                          }`}
                                        >
                                          {option.text}
                                          <input
                                            type="checkbox"
                                            id={`${option.id}`}
                                            value={option.value}
                                            onChange={() =>
                                              this.handleChangeMultipleSelection(
                                                option,
                                                index,
                                                true,
                                              )
                                            }
                                            name={`${option.id}`}
                                            checked={get(
                                              this.state.pages,
                                              [
                                                this.state.currentPage - 1,
                                                'results',
                                                index,
                                                'value',
                                              ],
                                              [],
                                            ).includes(option.value)}
                                          />
                                          <span className="checkmark" />
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}

                            {question.display_format ===
                              DisplayFormat.LIKERT_SCALE && (
                              <div className="row">
                                <div className="col mb-3">
                                  <LikertScale
                                    value={
                                      this.state.pages[
                                        this.state.currentPage - 1
                                      ]?.results[index]?.value
                                    }
                                    onChange={handleSliderChange}
                                  />
                                </div>
                              </div>
                            )}

                            {question.display_format ===
                              DisplayFormat.FREE_TEXT && (
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <textarea
                                      onChange={(e) =>
                                        this.handleChangeSimple(e, index)
                                      }
                                      className="questionnaire-assessment__text-question"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-4">
              <div />

              <div className="d-flex flex-column">
                <button
                  disabled={this.isDisabled() || isSubmitting}
                  style={{
                    cursor: this.isDisabled() ? 'not-allowed' : 'pointer',
                  }}
                  className="tr8s-button mb mt-0 green assessmentBtnNext submit assessment-submit-btn"
                  onClick={this.onSubmitHandler}
                >
                  {isSubmitting ? t('Please wait Submitting') : t('Submit')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    assessment: state.assessmentsReduces.assessment,
    assessmentLoading: state.assessmentsReduces.isLoading,
    questionLoading: state.getQuestion.isLoading,
    questions: state.getQuestion.question,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchQuestionData,
    fetchAssessmentData,
  })(QuestionnaireAssessment),
);
