import setToken from './setToken';
import axios from 'axios';

export default function unauthorized(status) {
  if (status === 401) {
    setToken();
    delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
    window.location.pathname = '/login';
    return;
  }
}
