import React from 'react';
import axios from 'axios';

class SvgImage extends React.Component {
  state = {
    data: null,
  };
  componentDidMount() {
    axios
      .get(this.props.src)
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch(() => {
        // TODO add error handling
        return;
      });
  }
  render() {
    if (this.props.code) {
      return (
        <span
          style={{ display: 'inline-block' }}
          {...this.props}
          dangerouslySetInnerHTML={{ __html: this.props.code }}
        />
      );
    }
    return (
      <span
        style={{ display: 'inline-block' }}
        {...this.props}
        dangerouslySetInnerHTML={{ __html: this.state.data }}
      />
    );
  }
}

export default SvgImage;
