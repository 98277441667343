import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import setToken from '../../setToken';
import { testQuestions } from './mock/mockQuestions';
import PersonalityFormMobile from './components/PersonalityFormMobile';
import PersonalityFormDesktop from './components/PersonalityFormDesktop';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

class PersonalityTestExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: false,
      pages: [testQuestions],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();

    const { history, fetchAssessmentData, assessment, match } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      if (!assessment) {
        fetchAssessmentData(match.params.id);
        // fetchAssessmentByIdData(match.params.id);
      }
    } else if (!token) {
      history.push('/login');
    }
  }

  updateDimensions = () => {
    const mediaQueryList = matchMedia('screen and (max-width: 767px)');
    const { matches } = mediaQueryList;
    const { isTablet } = this.state;

    if (isTablet !== matches) {
      this.setState({ isTablet: matches });
    }
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    const { match, history } = this.props;
    const queryString = parse(this.props.location.search);
    const isAllSelected = this.getIsAllSelected();

    if (isAllSelected) {
      history.replace(
        `/personality-start/${match.params.id}?test=${queryString.test}&uuid=${queryString.uuid}`,
      );
    }
  };

  handleChangeRadio = (item, itemIndex) => {
    const pagesCopy = [...this.state.pages];

    pagesCopy[0].results[0].options[itemIndex].value = item.id;

    this.setState({ pages: pagesCopy }, this.isNextDisabled);
  };

  getIsAllSelected = () => {
    const { pages } = this.state;
    return pages[0].results[0].options.every((element) => !!element.value);
  };

  render() {
    const { assessment, assessmentIsLoading, t } = this.props;
    const { isTablet } = this.state;
    if (assessmentIsLoading || !assessment) {
      return <Loader />;
    }

    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <Link
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back')}
        </Link>
        <div className="w-100 text-align-start">
          <form className="example-popup-form">
            <h1
              className="content-title test-example"
              dangerouslySetInnerHTML={{
                __html: `${t('Practice Run', {
                  title: t(assessment.test.title),
                })}`,
              }}
            ></h1>

            <div className="personality-test-form personality-test-form-position">
              <p className="test-question-title">
                {t(testQuestions.results[0].text)}
              </p>
              <div className="scrollingTable mb-3">
                {isTablet ? (
                  <PersonalityFormMobile
                    questions={testQuestions}
                    handleChangeRadio={this.handleChangeRadio}
                  />
                ) : (
                  <PersonalityFormDesktop
                    questions={testQuestions}
                    handleChangeRadio={this.handleChangeRadio}
                  />
                )}
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button
                disabled={!this.getIsAllSelected()}
                className={`tr8s-button mb assessmentBtnNext assessment-submit-btn-example ${
                  this.getIsAllSelected() ? 'green' : 'gray'
                }`}
                onClick={this.onSubmitHandler}
              >
                {t('Submit')}
              </button>
            </div>
          </form>
        </div>
      </PageWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    assessment: state.assessmentsReduces.assessment,
    assessmentIsLoading: state.assessmentsReduces.assessmentsLoading,
    assessmentError: state.assessmentsReduces.error,
    vacancy: state.getAssessmentByVacancyId.vacancy,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchAssessmentData,
    fetchAssessmentByIdData,
  })(PersonalityTestExample),
);
