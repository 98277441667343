import {
  GET_QUESTION_ASSESSMENTS_REQUEST,
  GET_QUESTION_ASSESSMENTS_SUCCESS,
  GET_QUESTION_ASSESSMENTS_FAILURE,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  vacancies: [],
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case GET_QUESTION_ASSESSMENTS_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case GET_QUESTION_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        question: payload.question,
      };
    }
    case GET_QUESTION_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
