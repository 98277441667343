import React, { Component, Fragment } from 'react';
import style from '../../game8/style.module.scss';
import Title from '../../Title';
import Card from '../../Card';
import GameHeader from '../../GameHeader';
import Modal from '../../ModalDeleteVacancy';
import { Game8 } from './mock';
import Toolpit from '../../HelpPopUp';
import { parse } from 'query-string';
import PageWrapper from '../../PageWrapper';
import { withTranslation } from 'react-i18next';

class GameEight extends Component {
  // Game Matched
  state = {
    data: null,
    round: 0,
    stopGame: false,
    tap: false,
    disabled: true,
  };

  componentDidMount() {
    this.setState({ data: Game8.games });
  }

  stopGameHandler = () => {
    this.setState({ openStopModal: true });
  };

  continueGameModal = () => {
    this.setState({ openStopModal: false });
  };

  onCloseGameModal = () => {
    const { history, match } = this.props;
    history.push(`/game-info/8${match.params.id}`);
  };

  nexRound = (answer) => {
    const { data, round } = this.state;

    if (round < data.length - 1) {
      this.setState({
        corrent: data[round].answer,
        tap: false,
        disabled: true,
        answer: null,
      });
    }
    if (round === data.length - 1) {
      this.setState({
        corrent: data[round].answer,
        tap: false,
        end: true,
        disabled: false,
        answer: null,
      });
    }
    this.setState({ answer, disabled: false, tap: true });
  };

  getAnswer = () => {
    const { round } = this.state;
    this.setState({
      round: round + 1,
      tap: false,
      disabled: true,
      answer: null,
    });
  };

  startGameNew = () => {
    const { history, match } = this.props;
    const testId = parse(this.props.location.search)['test'];

    history.replace(`/game/8${match.params.id}?test=${testId}`);
  };

  render() {
    const {
      data,
      round,
      openStopModal,
      tap,
      answer,
      end,
      disabled,
    } = this.state;
    const { history, t } = this.props;
    if (!data) {
      return <h1>{t('Loading')}</h1>;
    }
    return (
      <Fragment>
        {openStopModal && (
          <Modal
            onCancel={this.continueGameModal}
            onDelete={this.onCloseGameModal}
            text={t(
              'Are you sure want to end game? Once you exit you cannot restart the game',
            )}
            bntCancel={t('Cancel')}
            btnSuccess={t('End game')}
          />
        )}
        <PageWrapper isDashboardHeader history={history} isDashboardFooter>
          <div className="w-100">
            <GameHeader
              stopGameHandler={this.stopGameHandler}
              currentTask={round + 1}
              allTask={data.length}
              timerSeconds={null}
              canForceEnd={false}
              gameType={t('Practice Run', { title: t('Matched') })}
            />
            <div className={style.gameTaskWrapper}>
              <p className={`${style.gameDescription} font-weight-bold`}>
                {t('Does the meaning match the', {
                  data: t(data[round].task),
                })}
              </p>
              <div className={style.gameBody}>
                <Title>{t('Meaning')}</Title>
                <Card>
                  <span
                    style={{ color: data[round].firstColor }}
                    className="text-center"
                  >
                    {t(data[round].first)}
                  </span>
                </Card>
                <Card>
                  {data[round].task === 'picture' ? (
                    <img
                      alt={data[round].first}
                      src={data[round].src}
                      className={style.img}
                    />
                  ) : (
                    <span style={{ color: data[round].secondColor }}>
                      {t(data[round].second)}
                    </span>
                  )}
                  {tap && (
                    <div
                      className={`selectedItemTestGames ${
                        data[round].answer === answer ? 'correct' : 'incorrect'
                      }`}
                    >
                      {data[round].answer === answer ? (
                        <i className="fas fa-check"></i>
                      ) : (
                        <i className="fas fa-times"></i>
                      )}
                    </div>
                  )}

                  {tap && (
                    <Toolpit
                      status={data[round].answer === answer}
                      game={'Matched'}
                      type={data[round].task}
                    />
                  )}
                </Card>
                <Title>{t(data[round].task)}</Title>
              </div>
            </div>
            <div className="d-flex flex-column mb-5">
              <div className="d-flex justify-content-center">
                <div className={`${style.buttons} my-4`}>
                  <button
                    disabled={!disabled || end}
                    onClick={() => this.nexRound(false)}
                    className={
                      answer === true || end
                        ? `${style.confirmButton} ${style.disabledBtn}`
                        : style.confirmButton
                    }
                  >
                    {t('No')}
                  </button>
                  <button
                    disabled={!disabled || end}
                    onClick={() => this.nexRound(true)}
                    className={
                      answer === false || end
                        ? `${style.confirmButton} ${style.disabledBtn}`
                        : style.confirmButton
                    }
                  >
                    {t('Yes')}
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {end ? (
                  <div
                    disabled={!end}
                    className={style.endPractice}
                    onClick={this.startGameNew}
                  >
                    {t('Start Assessment')}
                  </div>
                ) : round !== data.length - 1 ? (
                  <button
                    disabled={disabled || end}
                    onClick={this.getAnswer}
                    className={`m-0 
                      ${
                        disabled || end
                          ? `${style.buttonNext} ${style.disabledBtn}`
                          : style.buttonNext
                      }`}
                  >
                    {t('Next')}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </PageWrapper>
      </Fragment>
    );
  }
}

export default withTranslation()(GameEight);
