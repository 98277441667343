import React from 'react';

function AsideCompany(props) {
  return (
    <div className="aside-block aside-block-company">
      <p className="aside-block-title">Company</p>

      <img
        style={{ maxHeight: '33px', marginBottom: '16px' }}
        src={props.companyLogo}
        alt="Company logo"
      />
      <p style={{ marginBottom: '38px', color: '#303030' }}>
        {props.companyName}
      </p>
    </div>
  );
}

export default AsideCompany;
