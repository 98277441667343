import React from 'react';
import style from './style.module.css';

const TaskCounter = ({ currentTask, allTask }) => (
  <div className={style.taskCounterWrapper}>
    <div className={style.taskContent}>
      <span className={style.currentTask}>{currentTask}</span>
      <span>/</span>
      <span className={style.allTask}>{allTask}</span>
    </div>
  </div>
);

export default TaskCounter;
