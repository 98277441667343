export const jobLevel = [
  { value: '1', label: 'Entry Level' },
  { value: '2', label: 'Individual Contributor' },
  { value: '3', label: 'Senior Individual Contributor' },
  { value: '4', label: 'Manager' },
  { value: '5', label: 'Manager of Managers' },
  { value: '6', label: 'Senior Leader' },
  { value: '7', label: 'C-Suite' },
  { value: '8', label: 'CEO' },
];
