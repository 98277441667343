export const Game7 = {
  is_timer_countdown: true,
  timer_seconds: 10000,
  games: {
    0: {
      main_image: { id: 1, path: '/assets/img/g7/r1/01.svg' },
      options: [
        { id: 2, path: '/assets/img/g7/r1/02.svg' },
        { id: 3, path: '/assets/img/g7/r1/03.svg' },
        { id: 4, path: '/assets/img/g7/r1/04.svg' },
        { id: 5, path: '/assets/img/g7/r1/05.svg' },
        { id: 6, path: '/assets/img/g7/r1/06.svg' },
        { id: 7, path: '/assets/img/g7/r1/08.svg' },
      ],
      answer: 5,
    },
    1: {
      main_image: { id: 8, path: '/assets/img/g7/r2/01.svg' },
      options: [
        { id: 9, path: '/assets/img/g7/r2/02.svg' },
        { id: 10, path: '/assets/img/g7/r2/03.svg' },
        { id: 11, path: '/assets/img/g7/r2/04.svg' },
        { id: 12, path: '/assets/img/g7/r2/05.svg' },
        { id: 13, path: '/assets/img/g7/r2/06.svg' },
        { id: 14, path: '/assets/img/g7/r2/07.svg' },
      ],
      answer: 12,
    },
    2: {
      main_image: { id: 15, path: '/assets/img/g7/r3/01.svg' },
      options: [
        { id: 16, path: '/assets/img/g7/r3/02.svg' },
        { id: 17, path: '/assets/img/g7/r3/03.svg' },
        { id: 18, path: '/assets/img/g7/r3/04.svg' },
        { id: 19, path: '/assets/img/g7/r3/05.svg' },
        { id: 20, path: '/assets/img/g7/r3/06.svg' },
        { id: 21, path: '/assets/img/g7/r3/07.svg' },
      ],
      answer: 20,
    },
  },
};
