import axios, { AxiosResponse } from 'axios';
import { BASE_URL, PATH } from '../constants/variables';

export function submitGameAnswers(collectedData: any, assessmentId: number) {
  let request: Promise<AxiosResponse | undefined> = new Promise((resolve) => {
    resolve();
  });
  if (collectedData.length > 0) {
    request = axios.post(`${BASE_URL}${PATH}answers/`, collectedData);
  }

  return request.then((res) => {
    if (!res || res.status === 201) {
      return axios.put(
        `${BASE_URL}${PATH}assessments/${assessmentId}/complete/`,
      );
    }
  });
}
