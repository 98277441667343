import {
  CAREER_DRIVERS,
  CAREER_DRIVERS_SUCCESS,
  CAREER_DRIVERS_ERROR,
  CHANGE_STORE,
  GET_ASSESSMENT_BY_ID_SUCCESS,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  assessment: null,
};

export default function careerDriver(state = initialState, { type, payload }) {
  switch (type) {
    case CAREER_DRIVERS: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case CAREER_DRIVERS_SUCCESS: {
      return {
        ...state,
        careerDriversData: payload,
      };
    }
    case GET_ASSESSMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case CAREER_DRIVERS_ERROR: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case CHANGE_STORE: {
      return {
        ...payload.newState,
      };
    }
    default: {
      return state;
    }
  }
}
