import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchUserData } from '../../actions/userPersonalInfo';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import { BASE_URL, PATH } from '../../constants/variables';
import {
  Assessment,
  AssessmentWithProgressData,
  FetchAssessmentData,
  FetchAssessmentWithProgress,
  FetchUserData,
  QuestionType,
  WithId,
} from '../../models/assessments';
import { User } from '../../models/user';
import setToken from '../../setToken';
import { fetchAssessmentsData } from '../../actions/getAssessments';
import { Application } from '../../models/applications';
import { withTranslation } from 'react-i18next';
import { Scope, TranslateOptions } from 'i18n-js';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

type ActionFunction = (currentApplicationId: number) => void;

function ApplicationActionButton({
  label,
  action,
}: {
  label: string;
  action: ActionFunction;
}) {
  const id = store.getState().assessmentsReduces.currentApplicationId;
  return (
    <button
      onClick={() => action(id)}
      style={{ marginTop: '20px' }}
      className="tr8s-button mb green goToDashboard w-100"
    >
      {label}
    </button>
  );
}

export interface TestCompletedProps
  extends RouteComponentProps<
    WithId,
    {},
    { isKnockedOut: boolean } | undefined
  > {
  fetchUserData: FetchUserData;
  fetchAssessmentData: FetchAssessmentData;
  isLoading: boolean;
  userInfo: User;
  assessment: Assessment;
  assessmentsData: {
    applicationInfo: Application;
    list: AssessmentWithProgressData;
  };
  applications: string[];
  fetchAssessmentsData: FetchAssessmentWithProgress;
  t: (scope: Scope, options?: TranslateOptions) => string;
}

interface Step {
  label: string;
  stagesLabel?: string;
}

interface ITestCompletedState {
  nextStageData: null | AssessmentWithProgressData;
}

class TestCompleted extends React.Component<
  TestCompletedProps,
  ITestCompletedState
> {
  constructor(props: TestCompletedProps) {
    super(props);

    this.state = {
      nextStageData: null,
    };
  }

  componentDidMount() {
    const {
      history,
      fetchUserData,
      fetchAssessmentData,
      userInfo,
    } = this.props;

    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      if (!userInfo) {
        fetchUserData();
      }
      fetchAssessmentData(this.props.match.params.id);

      axios
        .put(
          `${BASE_URL}${PATH}assessments/${this.props.match.params.id}/complete/`,
        )
        .then((res) => {
          if (res.status === 200) {
            console.log('completed');
          }
        });
    } else if (!token) {
      history.push('/login');
    }
  }

  componentWillUnmount() {
    const { history } = this.props;

    if (history.action === 'POP') {
      history.replace('/dashboard');
    }
  }

  componentDidUpdate(prevProps: TestCompletedProps) {
    if (prevProps.assessment !== this.props.assessment) {
      const { applications } = this.props;

      applications.forEach((id: string) => {
        fetchAssessmentsData(id);
        axios(`${BASE_URL}${PATH}applications/${id}/progress/`)
          .then((res) => {
            this.setState({ nextStageData: res.data });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    }
  }

  handleRedirect = (
    isAssessmentCompleted: boolean,
    isPersonalityCompleted: boolean,
    currentApplicationId: number,
  ): void => {
    const { history } = this.props;
    const path =
      isAssessmentCompleted && !isPersonalityCompleted
        ? `/dashboard`
        : `/assessments/${currentApplicationId}`;
    history.push(path);
  };

  handleViewResults = (application: number): void => {
    this.props.history.push(`/assessment/${application}/report`);
  };

  render() {
    const { userInfo, isLoading, assessment, assessmentsData, t } = this.props;
    const { nextStageData } = this.state;

    if (isLoading || !userInfo || !assessment || !nextStageData) {
      return <Loader />;
    }

    if (!assessmentsData) {
      return (
        <Redirect
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
        />
      );
    }

    const { current } = assessmentsData.list;

    const isNewStageStarted =
      current.length === 1 && !!nextStageData.current.length;
    const isPersonalityCompleted = nextStageData.completed.some(
      (assItem) => assItem.test.type.id === QuestionType.Personality,
    );
    const isKnockedOut = nextStageData?.knocked;
    const isAssessmentCompleted = !nextStageData.current.length;
    const isStop = isKnockedOut || isAssessmentCompleted;

    let stagesLabel, isResults;

    if (
      isStop &&
      (!isPersonalityCompleted ||
        (userInfo.is_employee &&
          !assessmentsData.applicationInfo.job.can_employee_view_results))
    ) {
      stagesLabel = t(
        'Thank you for completing the assessment Your application has been submitted',
      );
      isResults = false;
    } else if (isStop && isPersonalityCompleted) {
      stagesLabel = t(
        'Thank you for completing the assessment Your application has been submitted Please click View results to check your results',
      );
      isResults = true;
    } else if (isNewStageStarted && !isKnockedOut) {
      stagesLabel = t(
        'Congratulations you have successfully completed this assessment Please click Continue to proceed to the next assessment',
      );
      isResults = false;
    }

    const step: Step = {
      label: t('You have just completed this section'),
      stagesLabel,
    };

    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper isDashboardHeader isDashboardFooter>
        <div className="w-100 text-align-start">
          <h1 className="content-title completeTitle">
            {`${t('Thanks')} ${this.props.userInfo.first_name}!`}
          </h1>

          <ul className="tr8s-steps" style={{ marginBottom: '10px' }}>
            <li>
              <span
                className={`tr8s-step-span tr8s-steps-last active ${
                  direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                }`}
              />
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: step.label }}
              ></p>
            </li>

            <li>
              <p className="text">{step.stagesLabel}</p>
            </li>
          </ul>

          <div style={{ width: 200 }}>
            {isResults ? (
              <button
                onClick={() =>
                  this.handleViewResults(
                    store.getState().assessmentsReduces.currentApplicationId,
                  )
                }
                style={{ marginTop: '20px' }}
                className="tr8s-button mb green goToDashboard d-block mb-0 w-100"
              >
                {t('View Results')}
              </button>
            ) : (
              <ApplicationActionButton
                label={
                  isStop && !isPersonalityCompleted
                    ? t('Back to Dashboard')
                    : t('Continue')
                }
                action={(currentApplicationId: number) =>
                  this.handleRedirect(
                    isStop,
                    isPersonalityCompleted,
                    currentApplicationId,
                  )
                }
              />
            )}
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.authUser.userInfo,
    assessment: state.assessmentsReduces.assessment,
    applications: state.assessmentsReduces.applications,
    assessmentsData: state.assessmentsReduces.assessments,
    vacancy: state.getAssessmentByVacancyId.vacancy,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchUserData,
    fetchAssessmentData,
    fetchAssessmentsData,
  })(TestCompleted),
);
