import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetUserPassword } from '../../actions/changePasswordAction';
import Loader from '../Loader/Loader';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordRepeat: '',
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { t } = this.props;
    if (this.state.password !== this.state.passwordRepeat) {
      return false;
    } else {
      const { uid, token } = this.props.match.params;
      const { resetUserPassword, history } = this.props;
      resetUserPassword(
        {
          new_password: this.state.password,
          uid: uid,
          token: token,
          message: t('Password has been changed successfully'),
        },
        history,
      );
    }
  };

  render() {
    const { isLoading, error, t } = this.props;
    const direction = localStorage.getItem('contentDirection');

    let matcherror = '';

    if (
      this.state.passwordRepeat &&
      this.state.password !== this.state.passwordRepeat
    ) {
      matcherror = (
        <div className="errorBanner">{t('Password do not match')}</div>
      );
    }

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="auth-form-container text-center">
        <p className="auth-form-title">{t('Change password')}</p>
        {error && <div className="errorBanner">{error}</div>}
        <form
          className={`${
            direction === CONTENT_DIRECTION.RTL ? 'text-left' : 'text-right'
          }`}
          onSubmit={this.handleSubmit}
        >
          <div className="form-group position-relative">
            <input
              className="auth-form-input"
              placeholder={t('New password')}
              name="password"
              type="password"
              required
              value={this.state.password}
              onChange={this.handleInputChange}
            />

            <input
              className="auth-form-input"
              placeholder={t('Repeat new password')}
              type="password"
              name="passwordRepeat"
              required
              value={this.state.passwordRepeat}
              onChange={this.handleInputChange}
            />
            {matcherror}
          </div>

          <div className="form-group position-relative mb-0">
            <button
              type="submit"
              className="loginBtn tr8s-button change-password-btn"
            >
              {t('Reset password')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.changePasswordReduces.isLoading,
    status: state.changePasswordReduces.status,
    error: state.changePasswordReduces.error,
  };
};

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, { resetUserPassword })(ResetPasswordForm),
  ),
);
