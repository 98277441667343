import axios from 'axios';
import {
  GET_AUTH_USER_FAILURE,
  GET_AUTH_USER_REQUEST,
  GET_AUTH_USER_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const userAuthRequest = (isLoading) => {
  return {
    type: GET_AUTH_USER_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const userAuthSuccess = (userId) => {
  return {
    type: GET_AUTH_USER_SUCCESS,
    payload: {
      userId,
    },
  };
};

const userAuthFailure = (error) => {
  return {
    type: GET_AUTH_USER_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchAuthData = (user, history) => {
  return async (dispatch) => {
    dispatch(userAuthRequest(true));
    await axios
      .post(`${BASE_URL}/api/auth/token/login/`, user)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.message);
        }
        return res;
      })
      .then((res) => {
        //console.log("res.status223", res);

        const { auth_token } = res.data;
        setToken(auth_token);

        localStorage.setItem('token', auth_token);

        axios.get(`${BASE_URL}/api/auth/users/me/`).then((response) => {
          const {
            id,
            privacy_policy_accepted,
            consent_provided,
          } = response.data;
          dispatch(userAuthRequest(false));
          dispatch(userAuthSuccess(id));
          if (!privacy_policy_accepted) {
            history.push('/confirmation-of-global-policy');
          } else if (!consent_provided) {
            history.push('/personal-information');
          } else if (privacy_policy_accepted) {
            history.push('/dashboard');
          }
        });
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(
          userAuthFailure(
            err.response.data.non_field_errors.length
              ? err.response.data.non_field_errors.join(', ')
              : 'something went wrong...',
          ),
        );
        dispatch(userAuthRequest(false));
      });
  };
};
