import axios from 'axios';
import {
  FILTER_JOB,
  GET_JOBS_FAILURE,
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
} from '../constants/ActionTypes';
import setToken from '../setToken';
import { apiOrigin } from '../utils/requests';

const getJobsByIdRequest = (isLoading) => {
  return {
    type: GET_JOBS_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const getJobsByIdSuccess = (data) => {
  return {
    type: GET_JOBS_SUCCESS,
    payload: data,
  };
};

const getJobsByIdFailure = (error) => {
  return {
    type: GET_JOBS_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchJobsByCompanyId = (candidateId, callback = null) => {
  return async (dispatch) => {
    dispatch(getJobsByIdRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios(`${apiOrigin}auth/candidates/${candidateId}/job/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(getJobsByIdSuccess({ jobs: res.data }));
        dispatch(getJobsByIdRequest(false));
        callback && callback(res.data);
      })
      .catch((err) => {
        dispatch(getJobsByIdFailure(err));
        dispatch(getJobsByIdRequest(false));
      });
  };
};

export const createFilterJob = (/** @type {number} */ id) => ({
  type: FILTER_JOB,
  payload: {
    id,
  },
});
