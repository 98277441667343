import { createSelector } from 'reselect';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import {
  VideoUploadParams,
  VideoUploadParamsWithBlob,
  WithAssessmentId,
} from '../containers/VideoAssessment/models';
import { VideoQuestion } from '../models/questions';
import { AsyncState, WithErrorMessage } from '../models/state';

export interface VideoAssessmentState {
  questionsState: AsyncState<VideoQuestion[]>;
  videoUpload: AsyncState<VideoUploadParams>;
  assessmentStatus: AsyncState<'complete'>;
}

export const initialVideoAssessmentState: VideoAssessmentState = {
  questionsState: {
    status: 'initial',
  },
  videoUpload: {
    status: 'initial',
  },
  assessmentStatus: {
    status: 'initial',
  },
};

export enum FetchVideoQuestionsType {
  request = 'whitebox video -> questions -> request',
  success = 'whitebox video -> questions -> success',
  failure = 'whitebox video -> questions -> failure',
}

export const createFetchVideoQuestions = createAsyncAction(
  FetchVideoQuestionsType.request,
  FetchVideoQuestionsType.success,
  FetchVideoQuestionsType.failure,
)<{ testId: string }, { questions: VideoQuestion[] }, WithErrorMessage>();
export type FetchVideoQuestionsAction = ActionType<
  typeof createFetchVideoQuestions
>;

export enum VideoUploadType {
  request = 'whitebox video -> upload -> request',
  success = 'whitebox video -> upload -> success',
  failure = 'whitebox video -> upload -> failure',
}

export const createVideoUpload = createAsyncAction(
  VideoUploadType.request,
  VideoUploadType.success,
  VideoUploadType.failure,
)<VideoUploadParamsWithBlob, VideoUploadParams, WithErrorMessage>();
export type VideoUploadAction = ActionType<typeof createVideoUpload>;

export enum AssessmentStatusType {
  request = 'whitebox video -> assessment status -> request',
  success = 'whitebox video -> assessment status -> success',
  failure = 'whitebox video -> assessment status -> failure',
}

export const createAssessmentStatus = createAsyncAction(
  AssessmentStatusType.request,
  AssessmentStatusType.success,
  AssessmentStatusType.failure,
)<WithAssessmentId, 'complete', WithErrorMessage>();
export type AssessmentStatusAction = ActionType<typeof createAssessmentStatus>;

export const createVideoUploadAndCompleteAssessment = () =>
  ({
    type: 'whitebox video -> upload video and complete assessment',
  } as const);
export type VideoUploadAndCompleteAssessment = ReturnType<
  typeof createVideoUploadAndCompleteAssessment
>;

export type VideoAssessmentAction =
  | VideoUploadAndCompleteAssessment
  | FetchVideoQuestionsAction
  | VideoUploadAction
  | AssessmentStatusAction;

const videoAssessment = (
  state = initialVideoAssessmentState,
  action: VideoAssessmentAction,
): VideoAssessmentState => {
  switch (action.type) {
    case FetchVideoQuestionsType.request:
      return { ...state, questionsState: { status: 'loading' } };
    case FetchVideoQuestionsType.success:
      return {
        ...state,
        questionsState: { status: 'success', value: action.payload.questions },
      };
    case FetchVideoQuestionsType.failure:
      return {
        ...state,
        questionsState: {
          status: 'failure',
          value: action.payload.errorMessage,
        },
      };

    case VideoUploadType.request:
      return { ...state, videoUpload: { status: 'loading' } };
    case VideoUploadType.success:
      return {
        ...state,
        videoUpload: { status: 'success', value: action.payload },
      };
    case VideoUploadType.failure:
      return {
        ...state,
        videoUpload: {
          status: 'failure',
          value: action.payload.errorMessage,
        },
      };

    case AssessmentStatusType.request:
      return { ...state, assessmentStatus: { status: 'loading' } };
    case AssessmentStatusType.success:
      return {
        ...state,
        assessmentStatus: { status: 'success', value: action.payload },
      };
    case AssessmentStatusType.failure:
      return {
        ...state,
        assessmentStatus: {
          status: 'failure',
          value: action.payload.errorMessage,
        },
      };

    default:
      return state;
  }
};

export default videoAssessment;

export const selectVideoAssessment = (state: {
  videoAssessment: VideoAssessmentState;
}) => {
  return state.videoAssessment;
};

export const selectVideoQuestionsState = createSelector(
  selectVideoAssessment,
  ({ questionsState }) => questionsState,
);
