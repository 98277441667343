import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import SvgImage from '../../../components/SvgImage';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../../constants/contentDirection';

const CardDesktop = ({ item, index }) => {
  const { t } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          className={`cardWrapper ${
            snapshot.isDragging ? 'cardWrapper-dragging' : ''
          }`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isdragging={`${snapshot.isDragging}`}
        >
          <div className="headSection">
            <SvgImage
              code={item.icon_svg_code}
              src={item.icon}
              alt=""
              className={`cardIcon whitelabel ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
            />

            <span className="cardTitle">{t(item.name)}</span>
          </div>
          <div className="descriptionSection">
            <span className="cardDescription">{t(item.description)}</span>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default CardDesktop;
