import React from 'react';
import { useTranslation } from 'react-i18next';

const SurveyQuestionsDesktop = ({ questions, onChangeRadio, startIndex }) => {
  const { t } = useTranslation();

  return (
    <table className="table mb-0">
      <thead>
        <tr>
          <th scope="col fex" />
          {questions[0].options.map((item) => {
            return (
              <th key={item.id} scope="col flex">
                <p>{t(item.text)}</p>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {questions.map((option, itemIndex) => (
          <tr key={option.id}>
            <th scope="row" className="title">
              {`${startIndex + 1 + itemIndex}. ${option.text}`}
            </th>
            {questions[0].options.map((item, scaleIdx) => {
              return (
                <td key={`${option.id}+${item.id}+${Math.random()}`}>
                  <label className="checkbox-container">
                    <input
                      type="radio"
                      id={`${option.id}+${item.id}`}
                      value={item.value}
                      onClick={(e) => onChangeRadio(e, startIndex + itemIndex)}
                      name={`${option.id}`}
                      defaultChecked={+option.value === scaleIdx + 1}
                    />
                    <span className="checkmark radio" />
                  </label>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SurveyQuestionsDesktop;
