import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import CardDesktop from './CardDesktop';

const ImportantCardDesktop = ({ columnId, column, tasks, cardPlaceholder }) => {
  const { t } = useTranslation();

  return (
    <div className="importantCard">
      <div className="cardHeader">{t(column.name)}</div>
      <div className="cardContentWrapper">
        <Droppable droppableId={columnId.toString()} type="TASK">
          {(provided, snapshot) => (
            <div
              className="sceleton"
              ref={provided.innerRef}
              {...provided.droppableProps}
              isdraggingover={`${snapshot.isDraggingOver}`}
            >
              {tasks.map((task, index) => (
                <CardDesktop key={task.id} item={task} index={index} />
              ))}
              <div className="driver-card-border">{provided.placeholder}</div>
            </div>
          )}
        </Droppable>
        <div className="card-placeholders__important">{cardPlaceholder(0)}</div>
      </div>
    </div>
  );
};

export default ImportantCardDesktop;
