import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: false,
  status: null,
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: payload.status,
      };
    }
    case CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case FORGET_PASSWORD_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: payload.status,
      };
    }
    case FORGET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: payload.status,
      };
    }
    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
