import { range } from 'lodash';
import { mainColor } from '../../utils/theme';

export const likertRange = range(1, 6);

export const radioWrapperStyle: React.CSSProperties = {
  maxWidth: 600,
};

export const radioLabelStyle: React.CSSProperties = {
  cursor: 'pointer',
};

export const radioInputStyle: React.CSSProperties = {
  height: 0,
  width: 0,
};

const radioSize = 26;
export const highlightColor = '#27a4a1';
export const radioStyle: React.CSSProperties = {
  width: radioSize,
  height: radioSize,
  borderWidth: 1,
  borderColor: mainColor,
  borderStyle: 'solid',
  borderRadius: '50%',
};

const checkmarkSize = 2;
export const checkmarkStyle: React.CSSProperties = {
  width: 6,
  height: 11,
  position: 'relative',
  left: 1,
  top: -1,
  borderColor: 'white',
  borderStyle: 'solid',
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: checkmarkSize,
  borderBottomWidth: checkmarkSize,
  transform: 'rotate(45deg)',
};
