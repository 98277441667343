import React from 'react';
import { Link } from 'react-router-dom';

const id = 1;

function AsideVacancy(props) {
  return (
    <div className="aside-block aside-block-vacancy">
      <p className="aside-block-title">Vacancy</p>

      <Link
        to={`/vacancies/${id}`}
        className="tr8s-link"
        style={{ marginBottom: '38px', display: 'inline-block' }}
      >
        {props.vacancyName}
      </Link>

      {/*<a
        className="tr8s-link"
        style={{marginBottom: '38px', display: 'inline-block'}}
        href={props.vacancyLink}
      >{props.vacancyName}
      </a>*/}
    </div>
  );
}

export default AsideVacancy;
