import React from 'react';
import PageWrapper from '../PageWrapper';
import { useTranslation } from 'react-i18next';

const Message = (props) => {
  const { t } = useTranslation();

  const toLogin = () => {
    props.history.push('/login');
  };

  return (
    <PageWrapper>
      <div className="container m-auto">
        <div className="row">
          <div className="col-12">
            <div className="auth-form-container text-center">
              <p className="auth-form-title">
                {t("We've sent an email to your email address")}
              </p>

              <p className="auth-form-text m-0 p-0">
                {t(
                  'Check your email for a message with a link to Update your password',
                )}
              </p>
              <p className="auth-form-text mb-4">
                {t('This link expire in 4 hours')}
              </p>
              <div className="d-flex flex-column align-items-center">
                <button
                  onClick={toLogin}
                  className="tr8s-button link-msg-btn"
                  type="submit"
                >
                  {t('Continue to Login')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Message;
