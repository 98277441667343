import {
  GET_ASSESSMENTS_REQUEST,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_FAILURE,
  GET_SIPMLE_ASSESSMENTS_REQUEST,
  GET_SIPMLE_ASSESSMENTS_SUCCESS,
  GET_SIPMLE_ASSESSMENTS_FAILURE,
  VIDEO_ASSESSMENTS_REQUEST,
  VIDEO_ASSESSMENTS_FAILURE,
  VIDEO_ASSESSMENTS_SUCCESS,
  GET_APPLICATIONS,
  GET_ASSESSMENT_CACHED_ANSWERS_REQUEST,
  GET_ASSESSMENT_CACHED_ANSWERS_SUCCESS,
  GET_ASSESSMENT_CACHED_ANSWERS_FAILURE,
  SET_CURRENT_APPLICATION_ID,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  vacancies: [],
  currentApplicationId: null,
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case GET_ASSESSMENTS_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case GET_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        assessments: payload.assessments,
      };
    }
    case GET_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case GET_SIPMLE_ASSESSMENTS_REQUEST: {
      return {
        ...state,
        assessmentsLoading: payload.assessmentsLoading,
      };
    }
    case GET_SIPMLE_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        assessment: payload.assessment,
      };
    }
    case GET_SIPMLE_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case GET_APPLICATIONS: {
      return {
        ...state,
        applications: payload.assessment.applications,
      };
    }
    case GET_ASSESSMENT_CACHED_ANSWERS_REQUEST: {
      return {
        ...state,
        answersCacheLoading: payload.loading,
      };
    }
    case GET_ASSESSMENT_CACHED_ANSWERS_SUCCESS: {
      return {
        ...state,
        answersCache: payload.data,
      };
    }
    case GET_ASSESSMENT_CACHED_ANSWERS_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case VIDEO_ASSESSMENTS_REQUEST: {
      return {
        ...state,
        videoassessmentsLoading: payload.videoassessmentsLoading,
      };
    }
    case VIDEO_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        videoAssessment: payload.videoAssessment,
      };
    }
    case VIDEO_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        videoError: payload.videoError,
      };
    }

    case SET_CURRENT_APPLICATION_ID: {
      return {
        ...state,
        currentApplicationId: payload.currentApplicationId,
      };
    }
    default: {
      return state;
    }
  }
}
