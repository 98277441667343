import React from 'react';
import { forgetUserPassword } from '../../actions/changePasswordAction';
import { connect } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { forgetUserPassword, history } = this.props;
    const { email } = this.state;
    forgetUserPassword(
      {
        email,
      },
      history,
    );
  };

  render() {
    const { forgetPasswordError, forgetPasswordLoader, t } = this.props;
    const direction = localStorage.getItem('contentDirection');

    if (forgetPasswordLoader) {
      return <Loader />;
    }
    return (
      <div className="auth-form-container text-center">
        <p className="auth-form-title">{t('Forgot your password')}</p>
        <p className="auth-form-text">
          {t(
            'No worries resetting your password is easy Please type the email address you used to register',
          )}{' '}
        </p>
        {forgetPasswordError && (
          <div className="errorBanner">{forgetPasswordError}</div>
        )}
        <form
          className={`${
            direction === CONTENT_DIRECTION.RTL ? 'text-left' : 'text-right'
          }`}
          onSubmit={this.handleSubmit}
        >
          <div className="form-group position-relative">
            <input
              className="auth-form-input"
              required
              placeholder={t('Username')}
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group position-relative mb-0">
            <button
              className="loginBtn tr8s-button send-password-reset-btn"
              type="submit"
            >
              {t('Send Password Reset')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgetPasswordError: state.changePasswordReduces.error,
    forgetPasswordLoader: state.changePasswordReduces.isLoading,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { forgetUserPassword })(ForgotPasswordForm),
);
