// Authentication actions types
export const GET_AUTH_USER_REQUEST = 'GET_AUTH_USER_REQUEST';
export const GET_AUTH_USER_SUCCESS = 'GET_AUTH_USER_SUCCESS';
export const GET_AUTH_USER_FAILURE = 'GET_AUTH_USER_FAILURE';
// Personal user information
export const USER_PROFILE_INFO_REQUEST = 'USER_PROFILE_INFO_REQUEST';
export const USER_PROFILE_INFO_SUCCESS = 'USER_PROFILE_INFO_SUCCESS';
export const USER_PROFILE_INFO_FAILURE = 'USER_PROFILE_INFO_FAILURE';

export const CHANGE_USER_PROFILE_INFO_REQUEST =
  'CHANGE_USER_PROFILE_INFO_REQUEST';
export const CHANGE_USER_PROFILE_INFO_SUCCESS =
  'CHANGE_USER_PROFILE_INFO_SUCCESS';
export const CHANGE_USER_PROFILE_INFO_FAILURE =
  'CHANGE_USER_PROFILE_INFO_FAILURE';

// Vacancy actions types
export const GET_VACANCY_REQUEST = 'GET_VACANCY_REQUEST';
export const GET_VACANCY_SUCCESS = 'GET_VACANCY_SUCCESS';
export const GET_VACANCY_FAILURE = 'GET_VACANCY_FAILURE';

// Jobs actions types
export const GET_JOBS_REQUEST = 'GET_JOBS_REQUEST';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_FAILURE = 'GET_JOBS_FAILURE';
export const FILTER_JOB = 'FILTER_JOB';

// User profile dictionary

export const GET_USER_PROFILE_DICTIONARY_REQUEST =
  'GET_USER_PROFILE_DICTIONARY_REQUEST';
export const GET_USER_PROFILE_DICTIONARY_SUCCESS =
  'GET_USER_PROFILE_DICTIONARY_SUCCESS';
export const GET_USER_PROFILE_DICTIONARY_FAILURE =
  'GET_USER_PROFILE_DICTIONARY_FAILURE';

// Get assessments

export const GET_ASSESSMENTS_REQUEST = 'GET_ASSESSMENTS_REQUEST';
export const GET_ASSESSMENTS_SUCCESS = 'GET_ASSESSMENTS_SUCCESS';
export const GET_ASSESSMENTS_FAILURE = 'GET_ASSESSMENTS_FAILURE';

// Apply to job
export const APPLY_JOB_REQUEST = 'APPLY_JOB_REQUEST';
export const APPLY_JOB_SUCCESS = 'APPLY_JOB_SUCCESS';
export const APPLY_JOB_FAILURE = 'APPLY_JOB_FAILURE';

// delete assessments

export const DELETE_VACANCY_REQUEST = 'DELETE_VACANCY_REQUEST';
export const DELETE_VACANCY_REQUEST_SUCCESS = 'DELETE_VACANCY_REQUEST_SUCCESS';
export const DELETE_VACANCY_REQUEST_FAILURE = 'DELETE_VACANCY_REQUEST_FAILURE';

//Get assessment detail by Id

export const GET_ASSESSMENT_BY_ID_REQUEST = 'DELETE_VACANCY_REQUEST';
export const GET_ASSESSMENT_BY_ID_SUCCESS = 'DELETE_VACANCY_REQUEST_SUCCESS';
export const GET_ASSESSMENT_BY_ID_FAILURE = 'DELETE_VACANCY_REQUEST_FAILURE';

// game info
export const GET_GAME_INFO_REQUEST = 'GET_GAME_INFO_REQUEST';
export const GET_GAME_INFO_SUCCESS = 'GET_GAME_INFO_SUCCESS';
export const GET_GAME_INFO_FAILURE = 'GET_GAME_INFO_FAILURE';

// Get simple assesstmens

export const GET_SIPMLE_ASSESSMENTS_REQUEST = 'GET_SIPMLE_ASSESSMENTS_REQUEST';
export const GET_SIPMLE_ASSESSMENTS_SUCCESS = 'GET_SIPMLE_ASSESSMENTS_SUCCESS';
export const GET_SIPMLE_ASSESSMENTS_FAILURE = 'GET_SIPMLE_ASSESSMENTS_FAILURE';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';

export const SET_CURRENT_APPLICATION_ID = 'SET_CURRENT_APPLICATION_ID';

// Get assessment cached answers
export const GET_ASSESSMENT_CACHED_ANSWERS_REQUEST =
  'GET_ASSESSMENT_CACHED_ANSWERS_REQUEST';
export const GET_ASSESSMENT_CACHED_ANSWERS_SUCCESS =
  'GET_ASSESSMENT_CACHED_ANSWERS_SUCCESS';
export const GET_ASSESSMENT_CACHED_ANSWERS_FAILURE =
  'GET_ASSESSMENT_CACHED_ANSWERS_FAILURE';

// Video assessment

export const VIDEO_ASSESSMENTS_REQUEST = 'VIDEO_ASSESSMENTS_REQUEST';
export const VIDEO_ASSESSMENTS_SUCCESS = 'VIDEO_ASSESSMENTS_SUCCESS';
export const VIDEO_ASSESSMENTS_FAILURE = 'VIDEO_ASSESSMENTS_FAILURE';

export const GET_QUESTION_ASSESSMENTS_REQUEST =
  'GET_QUESTION_ASSESSMENTS_REQUEST';
export const GET_QUESTION_ASSESSMENTS_SUCCESS =
  'GET_QUESTION_ASSESSMENTS_SUCCESS';
export const GET_QUESTION_ASSESSMENTS_FAILURE =
  'GET_QUESTION_ASSESSMENTS_FAILURE';
// Personality test actions types
export const GET_PERSONALITY_TEST_QUESTION_SUCCESS =
  'GET_PERSONALITY_TEST_QUESTION_SUCCESS';

export const START_PERSONALITY_TEST = 'START_PERSONALITY_TEST';

export const END_PERSONALITY_TEST = 'END_PERSONALITY_TEST';

// Personality game test actions types

export const GET_GAME_TEST_REQUEST = 'GET_GAME_TEST_REQUEST';
export const GET_GAME_TEST_SUCCESS = 'GET_GAME_TEST_SUCCESS';
export const GET_GAME_TEST_FAILURE = 'GET_GAME_TEST_FAILURE';

export const START_GAME_TEST = 'START_GAME_TEST';

export const END_GAME_TEST = 'END_GAME_TEST';
// Situational judgment test part one actions types
export const GET_SJT1_TEST_QUESTION_SUCCESS = 'GET_SJT1_TEST_QUESTION_SUCCESS';

export const START_SJT1_TEST = 'START_SJT1_TEST';

export const END_SJT1_TEST = 'END_SJT1_TEST';

// Situational judgment test part two actions types
export const GET_SJT2_TEST_QUESTION_SUCCESS = 'GET_SJT2_TEST_QUESTION_SUCCESS';

export const START_SJT2_TEST = 'START_SJT2_TEST';

export const END_SJT2_TEST = 'END_SJT2_TEST';

// Login with token

export const LOGIN_WITH_TOKEN_REQUEST = 'LOGIN_WITH_TOKEN_REQUEST';
export const LOGIN_WITH_TOKEN_SUCCESS = 'LOGIN_WITH_TOKEN_SUCCESS';
export const LOGIN_WITH_TOKEN_FAILURE = 'LOGIN_WITH_TOKEN_FAILURE';

// Accept privacy policy

export const ACCEPT_PRIVACY_POLICY_REQUEST = 'ACCEPT_PRIVACY_POLICY_REQUEST';
export const ACCEPT_PRIVACY_POLICY_SUCCESS = 'ACCEPT_PRIVACY_POLICY_SUCCESS';
export const ACCEPT_PRIVACY_POLICY_FAILURE = 'ACCEPT_PRIVACY_POLICY_FAILURE';

// Sign up

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

// Logout
export const LOGUOT_REQUEST = 'LOGUOT_REQUEST';
export const LOGUOT_SUCCESS = 'LOGUOT_SUCCESS';
export const LOGUOT_FAILURE = 'LOGUOT_FAILURE';

// Change password

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

// Forget password

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE';

// Reset password

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// Referece Company Data

export const REF_COMPANY_REQUEST = 'REF_COMPANY_REQUEST';
export const REF_COMPANY_SUCCESS = 'REF_COMPANY_SUCCESS';
export const REF_COMPANY_FAILURE = 'REF_COMPANY_FAILURE';

// Games

export const START_GAME = 'START_GAME';
export const START_GAME_SUCCESS = 'START_GAME_SUCCESS';
export const START_GAME_ERROR = 'START_GAME_ERROR';

export const GET_NEXT_ROUND = 'GET_NEXT_ROUND';
export const GET_NEXT_ROUND_SUCCESS = 'GET_NEXT_ROUND_SUCCESS';
export const GET_NEXT_ROUND_ERROR = 'GET_NEXT_ROUND_ERROR';

export const SUBMIT_ROUND = 'SUBMIT_ROUND';
export const SUBMIT_ROUND_SUCCESS = 'SUBMIT_ROUND_SUCCESS';
export const SUBMIT_ROUND_ERROR = 'SUBMIT_ROUND_ERROR';

export const END_GAME = 'END_GAME';
export const END_GAME_SUCCESS = 'END_GAME_SUCCESS';
export const END_GAME_ERROR = 'END_GAME_ERROR';

export const SORTED_ITEM = 'SORTED_ITEM';

export const VIDEO_INTERVIEW = 'VIDEO_INTERVIEW';
export const VIDEO_INTERVIEW_SUCCESS = 'VIDEO_INTERVIEW_SUCCESS';
export const VIDEO_INTERVIEW_ERROR = 'VIDEO_INTERVIEW_ERROR';

export const CAREER_DRIVERS = 'CAREER_DRIVERS';
export const CAREER_DRIVERS_SUCCESS = 'CAREER_DRIVERS_SUCCESS';
export const CAREER_DRIVERS_ERROR = 'CAREER_DRIVERS_ERROR';

export const REACTIVATE = 'REACTIVATE';
export const REACTIVATE_SUCCESS = 'REACTIVATE_SUCCESS';
export const REACTIVATE_ERROR = 'REACTIVATE_ERROR';

export const CHANGE_STORE = 'CHANGE_STORE';

export const GET_ALL_IMAGES = 'GET_ALL_IMAGES';
