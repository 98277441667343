export interface WithId {
  id: string | undefined;
}

export type WithKnockoutFlag = { isKnockedOut: boolean };

export enum QuestionType {
  Personality = 1,
  SituationalJudgement,
  CareerDrivers,
  ProfileQuestionnaire = 5,
  Games,
  TechnicalAssessment,
  SurveyAssessment,
  /**
   * In house video assessment
   */
  VideoAssessment,
}

export interface Assessment {
  id: number;
  test: Test;
  application_id: number;
  status: number;
  status_changed: string;
  parent_id: null;
  children: any[];
  total_traits: number;
}

export interface Test {
  id: number;
  title: string;
  description: string;
  duration: string;
  icon: null;
  category: number;
  total_questions: number;
  parent_id: null;
  icon_svg: string;
  type: TestType;
}

export interface TestType {
  id: number;
  type: number;
  icon_svg: string;
  description_sample: string;
  duration_sample: string;
  title_sample: string;
  alias: string;
  category_description: string;
  view_order: number;
  selection_description: string;
  can_flag_knockout: boolean;
  can_score_knockout: boolean;
}

export interface AssessmentWithProgressData {
  current: Assessment[];
  completed: Assessment[];
  message: string;
  knocked: boolean;
  status: number;
}

export type FetchUserData = () => void;

export type FetchAssessmentData = (id: WithId['id']) => void;

export type FetchAssessmentWithProgress = (id: number | string) => void;
