import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '../PageWrapper';
import { withTranslation } from 'react-i18next';

class GameToFound extends Component {
  goBack = () => {
    const { assessments, history } = this.props;
    if (assessments && Object.keys(assessments).length > 0) {
      history.push(`/assessments/${assessments.id}`);
    } else {
      history.push(`/dashboard`);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <PageWrapper history={this.props.history}>
        <div className="container m-auto">
          <div className="row">
            <div className="col-12">
              <div className="auth-form-container text-center">
                <p className="auth-form-title">{t('Sorry')}</p>
                <p className="auth-form-text my-4">
                  {t(
                    'The assessment you are trying to access is not available anymore',
                  )}
                </p>
                <button className="tr8s-button green" onClick={this.goBack}>
                  {t('Back to all assessments')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assessments: state.getAssessmentByVacancyId.vacancy,
  };
};

export default withTranslation()(connect(mapStateToProps)(GameToFound));
