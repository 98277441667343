import React, { Component } from 'react';
import PageWrapper from '../../components/PageWrapper';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Expired extends Component {
  render() {
    const { t } = this.props;
    return (
      <PageWrapper>
        <div className="container m-auto">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <p className="auth-form-title" style={{ marginTop: 0 }}>
                  {t('Sorry')}
                </p>
                <p className="auth-form-text">
                  {t('The deadline for your application has expired')}
                </p>
                <Link
                  className="tr8s-button green theme-background"
                  to="/login"
                >
                  {t('Back to login')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default withTranslation()(Expired);
