import ls from 'local-storage';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signUpUser } from '../../actions/SignUpAction';
import { withTranslation } from 'react-i18next';

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      repeatPassword: '',
      emailDomailError: false,
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.password !== this.state.repeatPassword) {
      return false;
    } else if (this.state.emailDomailError) {
      return false;
    } else {
      const { signUpUser, history } = this.props;
      const { firstName, lastName, password, email } = this.state;
      let company_id = '';
      if (ls.get('refCompanyData')) {
        company_id = ls.get('refCompanyData').id;
      }

      signUpUser(
        {
          email,
          password,
          //password_repeat: repeatPassword,
          first_name: firstName,
          last_name: lastName,
          company_id: company_id,
        },
        history,
      );
    }
  };

  validateDomain = (e) => {
    const me = e.target.value;
    const refCompanyData = ls.get('refCompanyData');

    if (refCompanyData.valid_domains.length > 0) {
      let refDomails = refCompanyData.valid_domains
        .split(',')
        .map((i) => i.trim());
      let domains = refDomails;
      const idx1 = me.indexOf('@');
      if (idx1 > -1) {
        const splitStr = me.split('@');
        const sub = splitStr[1].split('.');
        if (domains.indexOf(sub[0]) > -1) {
          this.setState({ emailDomailError: false });
        } else {
          this.setState({ emailDomailError: true });
        }
      }
    } else {
      this.setState({ emailDomailError: false });
    }
  };

  render() {
    const { signUpError, t, valid_domains: validDomains } = this.props;

    let matcherror = '';

    if (
      this.state.repeatPassword &&
      this.state.password !== this.state.repeatPassword
    ) {
      matcherror = (
        <div className="errorBanner">{t('Password do not match')}</div>
      );
    }

    return (
      <div className="auth-form-container p-3 text-center">
        <p className="auth-form-title">{t('Please create your account')}</p>
        {signUpError && <div className="errorBanner">{signUpError}</div>}
        <form className="text-right" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input
              className="auth-form-input"
              required
              placeholder={t('First Name')}
              name="firstName"
              type="text"
              value={this.state.firstName}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group position-relative">
            <input
              className="auth-form-input"
              required
              placeholder={t('Last Name')}
              name="lastName"
              type="text"
              value={this.state.lastName}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group position-relative">
            <input
              className="auth-form-input"
              required
              placeholder={t('E-Mail')}
              name="email"
              type="email"
              value={this.state.email}
              onBlur={this.validateDomain}
              onChange={this.handleInputChange}
            />
          </div>
          {this.state.emailDomailError ? (
            <div className="errorBanner">
              {t(
                'Registration for email address with only domain are allowed',
                {
                  validDomains,
                },
              )}
            </div>
          ) : (
            ''
          )}
          <div className="form-group position-relative">
            <input
              className="auth-form-input"
              required
              placeholder={t('Password')}
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group position-relative">
            <input
              className="auth-form-input"
              required
              placeholder={t('Repeat Password')}
              type="password"
              name="repeatPassword"
              value={this.state.repeatPassword}
              onChange={this.handleInputChange}
            />
          </div>
          {matcherror}
          <div className="form-group position-relative">
            <button className="tr8s-button loginBtn" type="submit">
              {t('Sign up')}
            </button>
          </div>
          <div className="login-signup-switch">
            <span>
              <Link className="footer-copyright" to={'login'}>
                {t('Already have an account Login')}
              </Link>
            </span>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signUpError: state.authUser.signupError,
    signUpLoader: state.authUser.isLoading,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { signUpUser })(SignupForm),
);
