import React from 'react';
import style from './style.module.css';

const Timer = ({ minutes = 0, seconds = 0 }) => (
  <div className={style.timerWrapper}>
    <div className={style.timerContainer}>
      <span>{minutes > 9 ? minutes : `0${minutes}`}</span>
      <span>:</span>
      <span>{seconds > 9 ? seconds : `0${seconds}`}</span>
    </div>
  </div>
);

export default Timer;
