import axios from 'axios';
import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchQuestionData } from '../../actions/personalityTestActions';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import Progress from '../../components/TestProgressBar';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import PersonalityFormDesktop from './components/PersonalityFormDesktop';
import PersonalityFormMobile from './components/PersonalityFormMobile';
import { withTranslation } from 'react-i18next';
import { fetchAssessmentCachedAnswers } from '../../actions/getAssessmentCachedAnswers';
import Modal from '../../components/ModalDeleteVacancy';
import store from '../../store';

class PersonalityTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: [],
      currentPage: 0,
      isNextDisabled: true,
      isTablet: false,
      isSubmitting: false,
      showSaveModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();

    const queryString = parse(this.props.location.search);
    const {
      match,
      history,
      fetchAssessmentData,
      fetchQuestionData,
      fetchAssessmentCachedAnswers,
    } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      fetchAssessmentData(match.params.id);
      fetchAssessmentCachedAnswers(match.params.id);
      fetchQuestionData(
        queryString.test,
        this.state.currentPage,
        history,
        this.setQuestions,
      );
    } else if (!token) {
      history.push('/login');
    }
  }

  componentWillUnmount() {
    const { history, t } = this.props;
    const { isSubmitting } = this.state;

    if (history.action === 'POP' && isSubmitting) {
      alert(t('Please wait for submit'));
    }

    window.removeEventListener('resize', this.updateDimensions);
  }

  static getDerivedStateFromProps(props, state) {
    const questions = state.pages[state.currentPage - 1];
    const currentPage = state.currentPage;
    const cachedAnswers = props.cachedAnswers;
    if (questions) {
      let answeredQuestions = 0;
      for (const option of questions.results[0].options) {
        if (cachedAnswers && cachedAnswers[currentPage - 1]) {
          try {
            if (!option.value) {
              const questionCachedAnswers = cachedAnswers[currentPage - 1];
              const cachedAnswer = questionCachedAnswers.data.find(
                (item) => item.option_id === option.id,
              );
              if (cachedAnswer) {
                option.value = cachedAnswer.scale_id;
              }
            }
          } catch {}
        }
        if (option.value) answeredQuestions++;
      }
      return {
        pages: state.pages,
        isNextDisabled:
          questions.results[0].options.length !== answeredQuestions ||
          props.questionsLoading,
      };
    }
    return null;
  }

  updateDimensions = () => {
    const mediaQueryList = matchMedia('screen and (max-width: 767px)');
    const { matches } = mediaQueryList;
    const { isTablet } = this.state;

    if (isTablet !== matches) {
      this.setState({ isTablet: matches });
    }
  };

  setQuestions = (data) => {
    this.setState({
      pages: [...this.state.pages, data],
      currentPage: this.state.currentPage + 1,
    });
  };

  collectData = () => {
    const collectedData = this.state.pages.map((questionData) => {
      const data = questionData.results[0].options.map(({ id, value }) => ({
        option_id: id,
        scale_id: value,
      }));
      return {
        assessment: this.props.match.params.id,
        question: questionData.results[0].id,
        data,
      };
    });
    return collectedData;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    const collectedData = this.collectData();

    axios.post(`${BASE_URL}${PATH}answers/`, collectedData).then((res) => {
      if (res.status === 201) {
        axios
          .put(
            `${BASE_URL}${PATH}assessments/${this.props.match.params.id}/complete/`,
          )
          .then((res) => {
            if (res.status === 200) {
              axios
                .get(
                  `${BASE_URL}${PATH}assessments/${this.props.match.params.id}/`,
                )
                .then((assessmentResponse) => {
                  if (assessmentResponse.data.status === 4) {
                    const queryString = parse(this.props.location.search);

                    this.props.history.replace(
                      `/personality-assessment/${
                        this.props.match.params.id
                      }/tie?test=${queryString.test}&applications=${
                        store.getState().assessmentsReduces.currentApplicationId
                      }`,
                    );
                  } else {
                    this.props.history.replace(
                      `/assessments/${this.props.match.params.id}/tests-completed/`,
                    );
                  }
                });
            }
          });
      }
    });
  };

  isSubmitDisabled = (isSubmit = false) => {
    const collectedData = this.collectData();
    let disabled = false;
    collectedData.forEach((element) => {
      disabled = element.data.some((item) => !item.scale_id);
    });

    const questions = this.state.pages[this.state.currentPage - 1];
    if (this.state.pages.length < questions.count) {
      disabled = true;
    }
    return disabled;
  };

  isShowSubmit = () => {
    let disabled = false;

    const questions = this.state.pages[this.state.currentPage - 1];
    if (this.state.pages.length < questions.count) {
      disabled = true;
    }
    return disabled;
  };

  handleClickNextPage = () => {
    this.setState({ isNextDisabled: true });
    if (this.state.pages.length <= this.state.currentPage) {
      const queryString = parse(this.props.location.search);

      this.props.fetchQuestionData(
        queryString.test,
        this.state.currentPage,
        this.props.history,
        this.setQuestions,
      );
    } else {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handleClickPreviousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  handleChangeRadio = (item, itemIndex) => {
    const pagesCopy = [...this.state.pages];

    pagesCopy[this.state.currentPage - 1].results[0].options[itemIndex][
      'value'
    ] = item.id;

    this.setState({ pages: pagesCopy });
  };

  saveAndClose = () => {
    const collectedData = this.collectData();

    const requestData = {
      assessment_id: this.props.assessment.id,
      answers: collectedData,
    };
    axios
      .post(`${BASE_URL}${PATH}answers/cache_answers/`, requestData)
      .then((res) => {
        this.props.history.push(
          `/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`,
        );
        this.setState({
          showSaveModal: false,
        });
      });
  };

  render() {
    const { assessment, t } = this.props;
    const { isTablet, isSubmitting } = this.state;
    const isBackBtnVisible = !!this.state.pages[this.state.currentPage - 1]
      ?.previous;

    const questions = this.state.pages[this.state.currentPage - 1];
    if (!assessment || !questions) {
      return <Loader />;
    }
    if (assessment.status === 2) {
      return (
        <Redirect
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
        />
      );
    }

    return (
      <PageWrapper
        isDashboardHeader
        test
        history={this.props.history}
        match={this.props.match}
        testType={'personalityData'}
        isDashboardFooter
      >
        <div className="w-100 text-align-start">
          <form>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1
                  className="content-title test-example"
                  dangerouslySetInnerHTML={{
                    __html: `${t(assessment.test.title)}`,
                  }}
                ></h1>
              </div>
              <div>
                {this.state.showSaveModal && (
                  <Modal
                    onCancel={() => this.setState({ showSaveModal: false })}
                    onDelete={this.saveAndClose}
                    text={t(
                      'Would you like to save your answers and continue the rest later?',
                    )}
                    bntCancel={t('Cancel')}
                    btnSuccess={t('Yes')}
                  />
                )}
                <div
                  className="saveAndClose"
                  onClick={() => this.setState({ showSaveModal: true })}
                >
                  {t('Save and close')}
                </div>
              </div>
            </div>
            <div className="progressStatus">
              <Progress
                current={this.state.currentPage}
                all={questions.count}
              />
            </div>

            <div className="personality-test-form personality-test-form-position">
              <p className="test-question-title">
                {t(questions.results[0].text)}
              </p>
              <div className="scrollingTable mb-3">
                {isTablet ? (
                  <PersonalityFormMobile
                    questions={questions}
                    handleChangeRadio={this.handleChangeRadio}
                  />
                ) : (
                  <PersonalityFormDesktop
                    questions={questions}
                    handleChangeRadio={this.handleChangeRadio}
                  />
                )}
              </div>
            </div>
            <div
              className={`d-flex mb-5 ${
                isBackBtnVisible
                  ? 'justify-content-between'
                  : 'justify-content-end'
              }`}
            >
              <button
                type="button"
                className={`tr8s-button green assessmentBtnNext mt-0 ${
                  isBackBtnVisible ? '' : 'd-none'
                }`}
                onClick={this.handleClickPreviousPage}
              >
                {t('Back')}
              </button>

              <div className="d-flex flex-column">
                <button
                  type="button"
                  disabled={this.state.isNextDisabled}
                  className={`tr8s-button mb-3 green assessmentBtnNext mt-0
                     ${
                       !!this.state.pages[this.state.currentPage - 1]?.next
                         ? ''
                         : 'd-none'
                     }
                     ${
                       this.state.isNextDisabled
                         ? 'cursor-not-allowed'
                         : 'cursor-pointer'
                     }`}
                  onClick={this.handleClickNextPage}
                >
                  {t('Next')}
                </button>

                {!this.isSubmitDisabled() && (
                  <button
                    className={`tr8s-button mt-0 green assessmentBtnNext assessment-submit-btn`}
                    disabled={isSubmitting}
                    onClick={this.handleSubmit}
                  >
                    {isSubmitting ? t('Please wait Submitting') : t('Submit')}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assessment: state.assessmentsReduces.assessment,
    assessmentLoading: state.assessmentsReduces.isLoading,
    questions: state.getQuestion.question,
    cachedAnswersLoading: state.assessmentsReduces.answersCacheLoading,
    cachedAnswers: state.assessmentsReduces.answersCache,
    questionsLoading: state.getQuestion.isLoading,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchQuestionData,
    fetchAssessmentData,
    fetchAssessmentCachedAnswers,
  })(PersonalityTest),
);
