import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import ImportantCardDesktop from './components/ImportantCardDesktop';
import CardsWrapperDesktop from './components/CardsWrapperDesktop';
import { withTranslation } from 'react-i18next';
import store from '../../store';

class CareerDriversDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toPull: false,
    };
  }

  cardPlaceholder = (count) => {
    const array = [];
    for (let i = 0; i < count; i++) {
      array.push(<div key={i} className="driver-card-placeholder" />);
    }
    return array;
  };

  onDragEnd = (result) => {
    if (result.destination) {
      this.props.updateStateOnDragEnd(result);
    }
  };

  render() {
    const { toPull } = this.state;
    const {
      isLoading,
      assessment,
      categories,
      driverIds,
      drivers,
      isWarningMessage,
      t,
    } = this.props;
    if (isLoading || !assessment) {
      return <Loader />;
    }
    if (assessment.status === 2) {
      return (
        <Redirect
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
        />
      );
    }

    const rowsDrivers = driverIds
      .filter((id) => {
        const selectedIds = categories.reduce(
          (acc, current) => [
            ...acc,
            ...current.driverIds.map((driverId) => driverId),
          ],
          [],
        );
        return !selectedIds.includes(id);
      })
      .map((id) => drivers[id]);

    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
      >
        <div
          className="drivers-wrapper"
          style={{
            zIndex: this.state.toPull ? 0 : 2,
          }}
        >
          <CardsWrapperDesktop
            toPull={toPull}
            tasks={rowsDrivers}
            cardPlaceholder={this.cardPlaceholder}
          />
        </div>
        {isWarningMessage && (
          <div
            className="alert alert-danger w-75 mx-auto d-flex justify-content-center"
            role="alert"
          >
            {t('It is necessary to choose 5 drivers per category')}
          </div>
        )}
        <div className="importantCard-wrapper">
          {categories.map((cat) => {
            return (
              <ImportantCardDesktop
                key={cat.id}
                columnId={cat.id}
                column={cat}
                tasks={cat.driverIds.map((id) => drivers[id])}
                cardPlaceholder={this.cardPlaceholder}
              />
            );
          })}
        </div>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.careerDriver.isLoading,
    assessment: state.careerDriver.assessment,
    careerDriversData: state.careerDriver.careerDriversData,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(CareerDriversDesktop),
);
