import { QuestionType } from '../../models/assessments';
import { VideoQuestion } from '../../models/questions';
import { AssessmentProps, PracticeProps } from './models';

const statusCircleSize = 10;
export const statusCircleStyle: React.CSSProperties = {
  width: statusCircleSize,
  height: statusCircleSize,
};

export const practiceQuestions: VideoQuestion[] = [
  {
    id: 1,
    text: 'Can you give us a brief introduction about yourself?',
    duration: '00:01:00',
    type: QuestionType.VideoAssessment,
  },
];

export const noVideoRefErrorMessage =
  'Cannot set `srcObject` because `videoRef` is null';
export const noMediaStreamErrorMessage =
  'Cannot set `srcObject` because `mediaStream` is null';

/**
 * @see https://en.wikipedia.org/wiki/Display_resolution
 */
export const resolution = {
  width: 640,
  height: 360,
};

const noOptions: Pick<MediaDeviceInfo, 'deviceId' | 'label'>[] = [
  // * onChange is not invoked since the value of the MenuItem is falsy
  { deviceId: '', label: 'No options detected' },
];
export const groupDevices = (devicesInfo: MediaDeviceInfo[]) => {
  const devices = devicesInfo.reduce(
    (currentDevices, device) =>
      currentDevices.find(
        (currentDevice) =>
          currentDevice.deviceId === device.deviceId ||
          currentDevice.label === device.label,
      )
        ? currentDevices
        : currentDevices.concat(device),
    [] as MediaDeviceInfo[],
  );

  const video = devices.filter((device) => device.kind.startsWith('video'));
  const audio = devices.filter((device) => device.kind.startsWith('audio'));

  return {
    video: video.length ? video : noOptions,
    audio: audio.length ? audio : noOptions,
  };
};

export const padTime = (time: number) => time.toString().padStart(2, '0');

export const isAssessmentProps = (
  props: AssessmentProps | PracticeProps,
): props is AssessmentProps => {
  return 'onStop' in props;
};
