import axios from 'axios';
import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

class AssessmentsInfoLast extends React.Component {
  componentDidMount() {
    const { match, history, fetchAssessmentData } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      fetchAssessmentData(match.params.id);
      // fetchAssessmentByIdData(match.params.id);
    } else if (!token) {
      history.push('/login');
    }
  }

  goToProfile = () => {
    this.props.history.push('/profile');
  };

  personalityTestExample = () => {
    const { history, match } = this.props;
    const queryString = parse(this.props.location.search);
    axios
      .put(`${BASE_URL}${PATH}assessments/${match.params.id}/start/`)
      .then((res) => {
        if (res.data.success) {
          history.replace(
            `/personality-assessment/${match.params.id}/?test=${queryString.test}&uuid=${queryString.uuid}`,
          );
        }
      });
  };

  render() {
    const { assessment, vacancy, t } = this.props;
    if (!assessment || !vacancy) {
      return <Loader />;
    }

    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <Link
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back to assessment dashboard')}
        </Link>
        <div className="text-align-start">
          <h1
            className="content-title assessment-title"
            dangerouslySetInnerHTML={{
              __html: `${t('You are about to start the assessment', {
                title: t(assessment.test.title),
              })}`,
            }}
          ></h1>
          <p
            style={{ marginBottom: '16px', fontSize: '13px' }}
            className="content-text"
          >
            {t(
              'We recommend you complete this assessment somewhere quiet and free from interruption',
            )}
            <br />
          </p>
          <div style={{ marginTop: '35px' }} />
          <div className="infoContainer m-0">
            <div
              className={`infoWrapper ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
            >
              <img src="/assets/img/clock.svg" alt="" className="icon" />
              <div
                className={`textInfo ${
                  direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                }`}
              >
                <p className="Infotype text-uppercase">{t('Estimated time')}</p>
                <p>
                  {typeof assessment.test.duration.split(':')[1] != 'undefined'
                    ? t('minutes', {
                        time: parseInt(assessment.test.duration.split(':')[1]),
                      })
                    : ''}
                </p>
              </div>
            </div>
            <div className="infoWrapper">
              <img src="/assets/img/questions.svg" alt="" className="icon" />
              <div
                className={`textInfo ${
                  direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                }`}
              >
                <p className="Infotype">{t('Statements')}</p>
                <p>{assessment.total_traits * 7}</p>
              </div>
            </div>
          </div>
          <div className="buttonNext">
            <button
              onClick={this.personalityTestExample}
              style={{ marginTop: '28px' }}
              className="tr8s-button mb green startNow"
            >
              {t('Start the assessment now')}
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assessment: state.assessmentsReduces.assessment,
    assessmentIsLoading: state.assessmentsReduces.assessmentsLoading,
    assessmentError: state.assessmentsReduces.error,
    vacancy: state.getAssessmentByVacancyId.vacancy,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchAssessmentData,
    fetchAssessmentByIdData,
  })(AssessmentsInfoLast),
);
