import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import setToken from '../../setToken';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';

class TestCompleted extends React.Component {
  componentDidMount() {
    const { match, fetchAssessmentData, history } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      console.log('token', match.params.id);
      fetchAssessmentData(match.params.id);
    } else if (!token) {
      history.push('/login');
    }
  }

  sjtTestStart = () => {
    const { history, match } = this.props;
    const queryObject = parse(this.props.location.search);
    history.replace(
      `/sjt-assessment/${match.params.id}?test=${queryObject.test}`,
    );
  };

  render() {
    const { assessment, vacancy, t } = this.props;
    if (!assessment || !vacancy) {
      return <Loader />;
    }
    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        isDashboardFooter
      >
        <Link
          to={`/assessments/${vacancy.id}`}
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back to all assessments')}
        </Link>
        <div className="text-align-start">
          <h1
            className="content-title assessment-title"
            dangerouslySetInnerHTML={{
              __html: `${t('You are about to start the assessment', {
                title: t(assessment.test.title),
              })}`,
            }}
          ></h1>
          <p className="content-text content-text-preparation">
            {t(
              'Before starting the assessment please ensure that you are away from any distractions and that you have a stable internet connection',
            )}
            <br />
          </p>

          <div className="infoContainer">
            <div
              className={`infoWrapper ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
            >
              <img src="/assets/img/clock.svg" alt="" className="icon" />
              <div
                className={`textInfo ${
                  direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                }`}
              >
                <p className="Infotype">{t('Estimated time')}</p>
                <p>
                  {' '}
                  {typeof assessment.test.duration.split(':')[1] != 'undefined'
                    ? t('minutes', {
                        time: parseInt(assessment.test.duration.split(':')[1]),
                      })
                    : ''}
                </p>
              </div>
            </div>
            <div
              className={`infoWrapper ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
            >
              <img src="/assets/img/questions.svg" alt="" className="icon" />

              <div
                className={`textInfo ${
                  direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                }`}
              >
                <p className="Infotype">{t('Scenarios')}</p>
                <p>{assessment.test.total_questions}</p>
              </div>
            </div>
          </div>
          <div className="buttonNext">
            <button
              onClick={this.sjtTestStart}
              className="tr8s-button mb green startNow"
            >
              {t('Start the assessment now')}
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.assessmentsReduces.isLoading,
    assessment: state.assessmentsReduces.assessment,
    assessmentError: state.assessmentsReduces.error,
    vacancy: state.getAssessmentByVacancyId.vacancy,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchAssessmentData,
    fetchAssessmentByIdData,
  })(TestCompleted),
);
