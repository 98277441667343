import {
  VIDEO_INTERVIEW,
  VIDEO_INTERVIEW_SUCCESS,
  VIDEO_INTERVIEW_ERROR,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  status: null,
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case VIDEO_INTERVIEW: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case VIDEO_INTERVIEW_SUCCESS: {
      return {
        ...state,
        status: payload.status,
      };
    }
    case VIDEO_INTERVIEW_ERROR: {
      return {
        ...state,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
