export const Game10 = {
  is_timer_countdown: true,
  timer_seconds: 600,
  games: {
    0: {
      operation: 'minus',
      main_image_left: { id: 3, path: '/assets/img/8/left.svg' },
      main_image_right: { id: 59, path: '/assets/img/8/right.svg' },
      options: [
        { id: 7, path: '/assets/img/8/01.svg' },
        { id: 11, path: '/assets/img/8/03.svg' },
        { id: 15, path: '/assets/img/8/04.svg' },
        { id: 22, path: '/assets/img/8/05.svg' },
        { id: 50, path: '/assets/img/8/06.svg' },
        { id: 2, path: '/assets/img/8/02.svg' },
      ],
      answer: 7,
    },
    1: {
      operation: 'plus',
      main_image_left: { id: 12, path: '/assets/img/9/left.svg' },
      main_image_right: { id: 21, path: '/assets/img/9/right.svg' },
      options: [
        { id: 17, path: '/assets/img/9/01.svg' },
        { id: 32, path: '/assets/img/9/02.svg' },
        { id: 6, path: '/assets/img/9/03.svg' },
        { id: 48, path: '/assets/img/9/04.svg' },
        { id: 35, path: '/assets/img/9/05.svg' },
        { id: 34, path: '/assets/img/9/06.svg' },
      ],
      answer: 6,
    },
    2: {
      operation: 'plus',
      main_image_left: { id: 23, path: '/assets/img/14/left.svg' },
      main_image_right: { id: 30, path: '/assets/img/14/right.svg' },
      options: [
        { id: 34, path: '/assets/img/14/01.svg' },
        { id: 29, path: '/assets/img/14/02.svg' },
        { id: 25, path: '/assets/img/14/03.svg' },
        { id: 41, path: '/assets/img/14/04.svg' },
        { id: 26, path: '/assets/img/14/05.svg' },
        { id: 6, path: '/assets/img/14/06.svg' },
      ],
      answer: 41,
    },
  },
};
