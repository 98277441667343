import axios from 'axios';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import unauthorized from '../../unauthorized';
import Modal from '../ModalDeleteVacancy';
import PageWrapper from '../PageWrapper';
import GameHeader from '../GameHeader';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { submitGameAnswers } from '../../utils/games';
import DOMPurify from 'dompurify';

const RotateItGame = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isMounted, setMounted] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [isEndPage, setEndPage] = useState(false);
  const [isShowModal, setShowModeal] = useState(false);

  const { t } = useTranslation();

  const componentDidMount = React.useCallback(() => {
    const token = localStorage.getItem('token');
    const queryObject = parse(props.location.search);
    if (token) {
      setToken(token);
      axios
        .get(
          `${BASE_URL}${PATH}tests/${queryObject.test}/questions/?page_size=1000`,
        )
        .then((res) => {
          if (200 === res.status) {
            setPages(res.data.results);
            if (res.data.results.length === 1) {
              setEndPage(true);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          unauthorized(err.response.status);
        });
    }
  }, [props.location.search]);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
      componentDidMount();
    }
  }, [isMounted, componentDidMount]);

  const checkItem = (el) => {
    const pagesCopy = [...pages];
    pages[currentPage]['option_id'] = el.id;
    setPages(pagesCopy);
  };

  const nextPage = () => {
    if (currentPage < pages.length) {
      const nextPage = currentPage + 1;
      if (nextPage + 1 === pages.length) {
        setEndPage(true);
      }
      setCurrentPage(nextPage);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const collectedData = [];
    pages.forEach((question) => {
      if (question.option_id) {
        collectedData.push({
          assessment: props.match.params.id,
          data: { option_id: question.option_id },
          question: +question.id,
        });
      }
    });

    submitGameAnswers(collectedData, props.match.params.id)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          props.history.replace(
            `/assessments/${props.match.params.id}/games-completed`,
          );
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const endGame = () => {
    setShowModeal(true);
  };

  const continueGameModal = () => {
    setShowModeal(false);
  };

  const onCloseGameModal = () => {
    onSubmit();
    setShowModeal(false);
  };

  const addCheckedStyle = (id) => {
    if (pages[currentPage]['option_id'] === id) {
      return `${style.isChecked} ${style.active}`;
    } else {
      return `${style.isChecked}`;
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  const question = pages[currentPage];

  return (
    <>
      {isShowModal && (
        <Modal
          onCancel={continueGameModal}
          onDelete={onCloseGameModal}
          text={t(
            'Are you sure want to end game? Once you exit you cannot restart the game',
          )}
          bntCancel={t('Cancel')}
          btnSuccess={t('End game')}
        />
      )}
      <PageWrapper isDashboardHeader history={props.history} isDashboardFooter>
        <div className="w-100">
          <GameHeader
            allTask={pages.length}
            currentTask={currentPage + 1}
            gameType={t('Rotate It')}
            timerSeconds={120}
            canForceEnd={true}
            stopGameHandler={endGame}
            onTimeEnd={onCloseGameModal}
          />
          <div className={style.gameTaskWrapper}>
            <div className={style.gameTask}>
              <div className={style.taskWrapper}>
                <img
                  className={style.mainImage}
                  key={question.image}
                  src={DOMPurify.sanitize(question.image)}
                  alt=""
                />
              </div>
              <p className={style.gameDescriptionTwo}>
                {t('Select the correct shape when above image is rotated')}
              </p>
            </div>
            <div className={style.answerList}>
              {question.options.map((el, index) => (
                <div
                  key={index}
                  onClick={() => checkItem(el)}
                  className={style.answerItem}
                >
                  <div className={addCheckedStyle(el.id)}></div>
                  <img
                    key={el.image}
                    src={DOMPurify.sanitize(el.image)}
                    alt=""
                  />
                </div>
              ))}
            </div>

            {isEndPage ? (
              <button
                type="button"
                disabled={
                  typeof question.option_id === 'undefined' ? true : false
                }
                onClick={onSubmit}
                className={`${style.buttonNext} mb-4`}
              >
                Submit
              </button>
            ) : (
              <button
                type="button"
                disabled={
                  typeof question.option_id === 'undefined' ? true : false
                }
                onClick={nextPage}
                className={`${style.buttonNext} mb-4`}
              >
                {t('Next')}
              </button>
            )}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default RotateItGame;
