import axios from 'axios';
import {
  GET_GAME_INFO_REQUEST,
  GET_GAME_INFO_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';

const getGameInfoRequest = (gameInfoLoading) => {
  return {
    type: GET_GAME_INFO_REQUEST,
    payload: {
      gameInfoLoading,
    },
  };
};

const getGameInfoSuccess = (gameInfoData) => {
  return {
    type: GET_GAME_INFO_SUCCESS,
    payload: {
      gameInfoData,
    },
  };
};

export const gameInfoData = (assessmentId, history) => {
  return async (dispatch) => {
    dispatch(getGameInfoRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios(`${BASE_URL}${PATH}games/${assessmentId}/info/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(getGameInfoSuccess(res.data));
        dispatch(getGameInfoRequest(false));
      })
      .catch((err) => {
        // unauthorized(err.response.status);
        // dispatch(getGameInfoFailure(err))
        // dispatch(getGameInfoRequest(false));
        // history.push(`/game-error/${assessmentId}`);
      });
  };
};
