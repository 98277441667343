import React from 'react';
import { selectVideoUploadStatus, useVideo } from '../hooks';
import { useTranslation } from 'react-i18next';
import { AssessmentProps, BaseAssessmentProps } from '../models';
import WhiteboxVideoAssessmentBase from './WhiteboxVideoAssessmentBase';
import { useSelector } from 'react-redux';

export interface WhiteboxVideoAssessmentProps
  extends BaseAssessmentProps,
    AssessmentProps {}

const WhiteboxVideoAssessment: React.RefForwardingComponent<
  MediaRecorder | null,
  WhiteboxVideoAssessmentProps
> = (props, mediaRecorderRef) => {
  const mediaRecorder = mediaRecorderRef as React.MutableRefObject<MediaRecorder | null>;

  const { t } = useTranslation();

  React.useEffect(() => {
    // * enable navigation prompt
    // * includes reloads, tab closing, but does not include history navigations
    window.onbeforeunload = () => true;

    return () => {
      // * remove navigation prompt
      window.onbeforeunload = null;
    };
  }, [props.mediaStream]);

  const {
    questionData,
    countdown,
    countdownS,
    isInitial,
    isRecording,
    isStopped,
    setCountdownS,
    setQuestionId,
  } = useVideo(props.questions);

  const videoUploadStatus = useSelector(selectVideoUploadStatus);
  const [canChangeId, setCanChangeId] = React.useState(false);
  React.useEffect(() => {
    if (questionData && questionData.nextQuestionId) {
      if (!isInitial && videoUploadStatus === 'success') {
        if (canChangeId) {
          setCanChangeId(false);
          setQuestionId(questionData.nextQuestionId);
        }
      }
    }
  }, [questionData, isInitial, videoUploadStatus, setQuestionId, canChangeId]);

  const handleStartRecording = React.useCallback(() => {
    if (mediaRecorder.current && questionData) {
      const videoBlobs: Blob[] = [];
      mediaRecorder.current.ondataavailable = (event) =>
        videoBlobs.push(event.data);

      mediaRecorder.current.start();

      const interval = setInterval(() => {
        setCountdownS((seconds) => seconds && seconds - 1);
      }, 1000);

      const seconds =
        questionData.duration.minutes * 60 + questionData.duration.seconds;

      setCountdownS(seconds);

      const stopTimeout = setTimeout(() => {
        // * triggers final dataavailable event to be fired asynchronously
        mediaRecorder.current?.stop();
      }, seconds * 1000);

      mediaRecorder.current.onstop = () => {
        // * prevent double stopping
        clearTimeout(stopTimeout);
        clearInterval(interval);
        setCanChangeId(true);

        setCountdownS(0);

        const videoBlob = new Blob(videoBlobs, { type: 'video/webm' });
        const questionPayload = {
          questionId: questionData.question.id,
          blob: videoBlob,
        };
        if (questionData.nextQuestionId) {
          props.onStop(questionPayload);
        } else {
          props.onComplete(questionPayload);
        }
      };

      mediaRecorder.current.onerror = (event) => {
        // TODO replace alert with a more user friendly approach
        alert(`${t('Error while recording')} ${event.error.message}`);
      };
    } else {
      console.error('Cannot start recording because `mediaRecorder` is null');
    }
    // eslint-disable-next-line
  }, [props, questionData, mediaRecorder, setCountdownS]);

  const handleStopRecording = React.useCallback(() => {
    mediaRecorder.current?.stop();
  }, [mediaRecorder]);

  return (
    <WhiteboxVideoAssessmentBase
      {...props}
      src={props.mediaStream}
      questionData={questionData}
      isInitial={isInitial}
      isRecording={isRecording}
      isStopped={isStopped}
      countdown={countdown}
      countdownS={countdownS}
      onStartRecording={handleStartRecording}
      onStopRecording={handleStopRecording}
    />
  );
};

export default React.forwardRef(WhiteboxVideoAssessment);
