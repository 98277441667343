import clsx from 'clsx';
import React from 'react';
import {
  checkmarkStyle,
  likertRange,
  radioInputStyle,
  radioLabelStyle,
  radioStyle,
  radioWrapperStyle,
} from './utils';

export interface LikertScaleProps
  extends Required<
    Pick<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'value' | 'onChange'
    >
  > {}

const LikertScale: React.FC<LikertScaleProps> = ({ value, ...props }) => {
  return (
    <div className="d-flex justify-content-between" style={radioWrapperStyle}>
      {[...likertRange].map((i) => {
        const checked = value === i;

        return (
          <label
            key={i}
            className="d-flex flex-column align-items-center"
            style={radioLabelStyle}
          >
            <span>{i}</span>
            <input
              {...props}
              value={i}
              checked={checked}
              name={i.toString()}
              type="radio"
              className="invisible"
              style={radioInputStyle}
            />
            <div
              className={`mt-3 d-flex align-items-center justify-content-center rounded-circle ${
                checked ? 'checked' : ''
              }`}
              style={{
                ...radioStyle,
              }}
            >
              <div
                className={clsx({ 'd-none': !checked })}
                style={checkmarkStyle}
              />
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default LikertScale;
