import {
  Button as MDButton,
  ButtonProps as MDButtonProps,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCompanyTheme } from '../../../reducers/refCompanyReducer';

export interface ButtonProps extends MDButtonProps {
  textTransform?: 'lowercase' | 'uppercase' | 'capitalize';
}

const Button: React.FC<ButtonProps> = ({
  disabled,
  variant = 'outlined',
  color,
  style,
  className,
  textTransform = 'capitalize',
  ...props
}) => {
  const companyTheme = useSelector(selectCompanyTheme);

  const styleWithDefaults = React.useMemo(() => {
    return {
      ...style,
      color: color === 'default' ? 'undefined' : style?.color ?? companyTheme,
      borderColor:
        color === 'default' ? undefined : style?.borderColor ?? companyTheme,
      opacity: disabled ? 0.7 : 1,
      whiteSpace: style?.whiteSpace ?? 'nowrap',
      minWidth: style?.minWidth ?? 'auto',
    };
  }, [style, disabled, color, companyTheme]);
  return (
    <MDButton
      className={clsx(className, `text-${textTransform}`)}
      style={styleWithDefaults}
      variant={variant}
      disabled={disabled}
      {...props}
    />
  );
};

export default Button;
