import React from 'react';
import { VideoQuestion } from '../../models/questions';
import { Countdown, QuestionData } from './models';
import { padTime } from './utils';

export const useVideo = (questions: VideoQuestion[]) => {
  const [questionId, setQuestionId] = React.useState<number | null>(
    questions[0]?.id ?? null,
  );
  const currentQuestionId = React.useMemo(() => {
    // * instead of an effect
    // * since useState does not change when the initial values changes
    return questionId ?? questions[0]?.id ?? null;
  }, [questionId, questions]);

  const questionData: QuestionData = React.useMemo(() => {
    const index = questions.findIndex(
      (question) => question.id === currentQuestionId,
    );
    if (index > -1) {
      // * cannot be null based on the above if check
      const currentQuestion = questions[index];

      // * hours are discarded
      const [, minutes, seconds] = currentQuestion.duration.split(':');

      return {
        questionIndex: index,
        question: currentQuestion,
        duration: {
          minutes: parseInt(minutes),
          seconds: parseInt(seconds),
        },
        nextQuestionId: questions[index + 1]?.id ?? null,
      };
    } else {
      return null;
    }
  }, [questions, currentQuestionId]);

  /**
   * in seconds
   */
  const [countdownS, setCountdownS] = React.useState<Countdown>(null);
  const isInitial = React.useMemo(() => {
    return countdownS === null;
  }, [countdownS]);
  const isRecording = React.useMemo(() => {
    return !!countdownS;
  }, [countdownS]);
  const isStopped = React.useMemo(() => {
    return countdownS === 0;
  }, [countdownS]);

  const countdown = React.useMemo(() => {
    if (countdownS) {
      const minutes = Math.floor(countdownS / 60);
      const seconds = countdownS % 60;
      return `${padTime(minutes)}:${padTime(seconds)}`;
    } else {
      return '';
    }
  }, [countdownS]);

  return React.useMemo(
    () => ({
      questionData,
      setQuestionId,
      countdownS,
      countdown,
      isInitial,
      isRecording,
      isStopped,
      setCountdownS,
    }),
    [
      questionData,
      countdownS,
      countdown,
      isInitial,
      isRecording,
      isStopped,
      setQuestionId,
      setCountdownS,
    ],
  );
};

export const selectVideoUploadStatus = (state: any) =>
  state.videoAssessment.videoUpload.status;

export const selectVideoAssessmentStatus = (state: any) =>
  state.videoAssessment.assessmentStatus.status;
