import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from './routes';
import store from './store';
import axios from 'axios';
import setToken from './setToken';

const App = () => {
  const history = useHistory();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          setToken();
          delete axios.defaults.headers.common['Authorization'];
          localStorage.removeItem('token');
          history.push('/login');
        } else if (error.response.status === 403) {
          setToken();
          delete axios.defaults.headers.common['Authorization'];
          localStorage.removeItem('token');
          history.push('/expired');
        }
      }
      return Promise.reject(error);
    },
  );

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default hot(module)(App);
