import React from 'react';
import { useTranslation } from 'react-i18next';

const SurveyQuestionsMobile = ({ questions, onChangeRadio, startIndex }) => {
  const { t } = useTranslation();

  return (
    <>
      {questions.map((option, itemIndex) => {
        const isSomeChecked = questions[0].options.some(
          (item) => item.value === questions[itemIndex]?.value,
        );

        return (
          <div key={option.id} className="option-wrapper-mobile">
            <p className="option-title-mobile">
              {`${startIndex + 1 + itemIndex}. ${option.text}`}
            </p>
            <div className="d-flex justify-content-between">
              {questions[0].options.map((item, itemIdx) => {
                const isChecked = item.value === questions[itemIndex]?.value;

                return (
                  <div
                    key={item.id}
                    className="d-flex flex-column align-items-center flex-shrink-0 flex-grow-0"
                    style={{
                      width: `calc(100% / ${questions[0].options.length})`,
                      maxWidth: `calc(100% / ${questions[0].options.length})`,
                    }}
                  >
                    <label className="checkbox-container pl-0">
                      <input
                        type="radio"
                        id={`${option.id}+${item.id}`}
                        value={item.value}
                        onClick={(e) =>
                          onChangeRadio(e, startIndex + itemIndex)
                        }
                        name={`${option.id}`}
                        defaultChecked={isChecked}
                      />
                      <span className="checkmark radio">{itemIdx + 1}</span>
                    </label>
                    <p
                      className="option-scale-text-mobile"
                      style={{
                        display: `${
                          isChecked ||
                          (!isSomeChecked &&
                            (itemIdx === 0 ||
                              itemIdx === questions[0].options.length - 1))
                            ? 'block'
                            : 'none'
                        }`,
                      }}
                    >
                      {t(item.text)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SurveyQuestionsMobile;
