import React from 'react';
import ResetPasswordForm from '../../components/Auth/ResetPasswordForm';
import PageWrapper from '../../components/PageWrapper';

const ResetPassword = (props) => {
  return (
    <PageWrapper history={props.history} match={props.match}>
      <div className="container m-auto">
        <div className="row">
          <div className="col-12">
            <ResetPasswordForm history={props.history} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ResetPassword;
