import { useEffect, useState } from 'react';

export const useIsTablet = () => {
  const mediaQueryList = matchMedia('screen and (max-width: 767px)');
  const [matches, setMatches] = useState(mediaQueryList.matches);

  useEffect(() => {
    const listener = (event) => setMatches(event.matches);

    mediaQueryList.addListener(listener);

    return () => mediaQueryList.removeListener(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return matches;
};
