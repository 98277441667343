import React, { PureComponent } from 'react';
import style from './style.module.scss';
import ProgressBar from '../ProgressBar';
import TaskCounter from '../TaskCounter';
import Timer from '../Timer';
import { withTranslation } from 'react-i18next';

class GameHeader extends PureComponent {
  state = {
    minutes: 0,
    seconds: 0,
  };

  componentDidMount() {
    const { timerSeconds } = this.props;
    if (timerSeconds) {
      const minutes = timerSeconds > 60 ? timerSeconds / 60 : 0;
      const seconds = timerSeconds - minutes * 60;
      this.setState({ minutes, seconds });
      this.doIntervalChangeDown();

      window.addEventListener('beforeunload', this.onBeforeUnload);
      window.addEventListener('unload', this.onBeforeUnload);
    }
  }

  onBeforeUnload = (e) => {
    const { t } = this.props;

    e.returnValue = t(
      'Are you sure want to end game? Once you exit you cannot restart the game',
    );
  };

  doIntervalChangeDown = () => {
    this.startGameDelay = setTimeout(() => {
      this.timer = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.seconds === 0 && prevState.minutes === 0) {
            const { onTimeEnd } = this.props;
            if (onTimeEnd) {
              onTimeEnd();
            }
            this.componentWillUnmount();
          } else if (prevState.seconds === 0) {
            return {
              minutes: prevState.minutes - 1,
              seconds: 59,
            };
          } else {
            return {
              seconds: prevState.seconds - 1,
            };
          }
        });
      }, 1000);
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.timer);
    clearTimeout(this.startGameDelay);
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    window.removeEventListener('unload', this.onBeforeUnload);
  }

  render() {
    const {
      allTask = 1,
      currentTask = 1,
      stopGameHandler,
      gameType,
      timerSeconds,
      canForceEnd,
      t,
    } = this.props;
    const { seconds = 0, minutes = 0 } = this.state;

    return (
      <div className={style.gameHeaderWrapper}>
        <div className={style.gameHeaderContainer}>
          <div style={{ width: '100%', maxWidth: '650px' }}>
            {this.props.timerProgress === true ? (
              <ProgressBar
                lineHeight={9}
                current={this.props.timerSeconds - (minutes * 60 + seconds)}
                max={this.props.timerSeconds}
                colorBar={'#dedede'}
                colorProgress={'#27a4a1'}
              />
            ) : (
              <ProgressBar
                lineHeight={9}
                current={currentTask}
                max={allTask}
                colorBar={'#dedede'}
                colorProgress={'#27a4a1'}
              />
            )}
          </div>
          <div
            className={style.gameHeaderNavigation}
            style={{ maxWidth: '650px' }}
          >
            {timerSeconds ? (
              <Timer minutes={minutes} seconds={seconds} />
            ) : (
              <div />
            )}
            <TaskCounter currentTask={currentTask} allTask={allTask} />
            {canForceEnd ? (
              <div className={style.stopGame} onClick={stopGameHandler}>
                {t('End game')}
              </div>
            ) : (
              <div />
            )}
            {gameType && <div className={style.gameType}>{gameType}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GameHeader);
