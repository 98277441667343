import React from 'react';
import ForgotPasswordSuccessForm from '../../components/Auth/ForgotPasswordSuccessForm';
import PageWrapper from '../../components/PageWrapper';

const ForgotPasswordSuccess = (props) => {
  return (
    <PageWrapper history={props.history}>
      <div className="container m-auto">
        <div className="row">
          <div className="col-12">
            <ForgotPasswordSuccessForm />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ForgotPasswordSuccess;
