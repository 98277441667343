export const jobFunctions = [
  { value: '1', label: 'Analytics and Data Science' },
  { value: '2', label: 'Banking and Insurance' },
  { value: '3', label: 'Communications' },
  { value: '4', label: 'Customer Service/Call Center' },
  { value: '5', label: 'Digital Marketing' },
  { value: '6', label: 'Education and Training' },
  { value: '7', label: 'Engineering' },
  { value: '8', label: 'Executive/General Management' },
  { value: '9', label: 'Finance and Accounting' },
  { value: '10', label: 'General and Administrative Support' },
  { value: '11', label: 'Geoscience, Agriculture and Forestry' },
  { value: '12', label: 'Health Care' },
  { value: '13', label: 'Hospitality and Tourism' },
  { value: '14', label: 'Human Resources' },
  { value: '15', label: 'Information Technology' },
  { value: '16', label: 'Legal and Compliance' },
  { value: '17', label: 'Logistics/Supply Chain' },
  { value: '18', label: 'Marketing' },
  { value: '19', label: 'Operations' },
  { value: '20', label: 'Project and Program Management' },
  { value: '21', label: 'Property Management and Construction' },
  { value: '22', label: 'Public Safety and Military' },
  { value: '23', label: 'Quality Assurance' },
  { value: '24', label: 'Research and Development' },
  { value: '25', label: 'Retail' },
  { value: '26', label: 'Risk Management' },
  { value: '27', label: 'Safety, Health, and Environment' },
  { value: '28', label: 'Sales' },
  { value: '29', label: 'Strategy and Policy Creation and Implementation' },
  { value: '30', label: 'Trading' },
  { value: '31', label: 'Unemployed' },
];
