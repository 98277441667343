import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { Job, Trait } from '../../models/applications';
import { User } from '../../models/user';

interface FinancialAnalystRoleProps {
  potentialDerailers: Trait[];
  matchedTraits: Trait[];
  name: string;
  candidateData: User;
  job: Job;
  type?: any;
}

const RoleMatching = ({
  potentialDerailers,
  matchedTraits,
  name,
  candidateData,
  job,
  type,
}: FinancialAnalystRoleProps) => {
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  return (
    <section className="assessment-sec pdf-style page-break">
      <div className="financial-analyst">
        <h1 className="financial-analyst-title">
          {t('Name fit to the Role', {
            name,
            title: job.title,
          })}
        </h1>

        <section>
          <span className="financial-analyst-subtitle">
            {t('Matching traits')}
          </span>
          {!!matchedTraits?.length ? (
            <div className="row align-items-stretch my-4">
              {matchedTraits.map((item: Trait) => {
                return (
                  <div key={item.id} className="col-md-4">
                    <div className="card px-4 h-100">
                      <div className="financial-analyst-img-wrapper">
                        <img src={item.icon} alt="" />
                      </div>
                      <h5
                        className={`head-card ${
                          !type ? 'report-xmdFont' : 'report-xsmFont'
                        }`}
                      >
                        {i18n.language === 'ar' && item.name_ar
                          ? item.name_ar
                          : item.name}
                      </h5>
                      <p className="ptext">
                        {i18n.language === 'ar' && item.short_description_ar
                          ? item.short_description_ar
                          : item.short_description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-1 mb-4">
              <p>
                {t('financialAnalyticsMatchingTraits', {
                  name,
                  candidatesDataGender1:
                    candidateData.gender === 1
                      ? t('he does')
                      : candidateData.gender === 2
                      ? t('she does')
                      : t('they do'),
                  candidatesDataGender2:
                    candidateData.gender === 1
                      ? t('his')
                      : candidateData.gender === 2
                      ? t('her')
                      : t('their'),
                })}
              </p>
            </div>
          )}
        </section>

        <section className="assessment-sec">
          <span className="financial-analyst-subtitle">
            {t('Potential derailers')}
          </span>
          {!!potentialDerailers?.length ? (
            <div className="row align-items-stretch my-4">
              {potentialDerailers.map((item: Trait) => {
                return (
                  <div key={item.id} className="col-md-4">
                    <div className="card px-4 h-100">
                      <div className="financial-analyst-img-wrapper">
                        <img src={item.icon} alt="" />
                      </div>

                      <h5 className="head-card report-xmdFont">
                        {i18n.language === 'ar' && item.name_ar
                          ? item.name_ar
                          : item.name}
                      </h5>
                      <p className="ptext">
                        {i18n.language === 'ar' && item.short_description_ar
                          ? item.short_description_ar
                          : item.short_description}
                      </p>

                      <h5 className="card-details-title">
                        {t('Interview questions')}
                      </h5>
                      <div key={item.id} className="performing-best-list">
                        <div
                          className={`performing-best-list-wrapper listw ${
                            direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                          }`}
                          dangerouslySetInnerHTML={{
                            __html:
                              i18n.language === 'ar' && item.questions_ar
                                ? item.questions_ar
                                : item.questions,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-1 mb-4">
              <p>
                {t('financialAnalyticsPotentialDerailers', {
                  name,
                  candidatesDataGenderAction1:
                    candidateData.gender === 1
                      ? t('he does')
                      : candidateData.gender === 2
                      ? t('she does')
                      : t('they do'),
                  candidatesDataGender2:
                    candidateData.gender === 1
                      ? t('his')
                      : candidateData.gender === 2
                      ? t('her')
                      : t('their'),
                })}
              </p>
            </div>
          )}
        </section>
      </div>
    </section>
  );
};

export default RoleMatching;
