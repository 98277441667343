import axios from 'axios';
import { parse } from 'query-string';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import image1En from '../../assets/game8/screen1-en.png';
import image2En from '../../assets/game8/screen2-en.png';
import image1Ar from '../../assets/game8/screen1-ar.png';
import image2Ar from '../../assets/game8/screen2-ar.png';
import Loader from '../../components/Loader/Loader';
import { BASE_URL, PATH } from '../../constants/variables';
import PageWrapper from '../PageWrapper';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';

class GameInfo extends React.Component {
  // Game Matched
  componentDidMount() {
    const { match, fetchAssessmentData } = this.props;
    fetchAssessmentData(match.params.id);
    this.loadImage(image1En);
    this.loadImage(image2En);
    this.loadImage(image1Ar);
    this.loadImage(image2Ar);
  }

  loadImage = (src, srcSet) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;
      if (srcSet) {
        image.srcset = srcSet;
      }

      if (image.complete) {
        return resolve();
      }
      image.onload = resolve;
      image.onerror = reject;
    });
  };

  testRound = () => {
    const { match, history } = this.props;
    axios
      .put(`${BASE_URL}${PATH}assessments/${this.props.match.params.id}/start/`)
      .then((res) => {
        if (res.data.success) {
          const testId = parse(this.props.location.search)['test'];

          history.replace(`/practice-run/8${match.params.id}?test=${testId}`);
        }
      });
  };

  render() {
    const { assessment, t } = this.props;
    if (!assessment) {
      return <Loader />;
    }
    const nextIcon = <i className="fas fa-chevron-right direction-icons"></i>;
    const prevIcon = <i className="fas fa-chevron-left direction-icons"></i>;

    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <Link
          to={`/game-assessments/${assessment.parent_id}`}
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back to all game based assessments')}
        </Link>
        <div className="text-align-start">
          <h1 className="content-title startTest">
            {t('You are about to start the game')}{' '}
            <b>{t(assessment.test.title)}</b>
          </h1>
          <p className="content-text-assessment game-info-text">
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'Correctly match the meaning to the picture or font color',
                ),
              }}
            />
            <br />
            <br />
            <b>{t('Instructions')}: </b>
            <br />•{' '}
            {t(
              'Select yes or no depending on whether the meaning on the top matches the picture or font color on the bottom',
            )}
            <br />• {t('This is a timed assessment')}
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t('Time minutes', { minutes: 2 }),
              }}
            />
            <br />
            {t(
              'You should aim to get as many answers correct as possible in the time allocated',
            )}
          </p>
          <div className="infoContainer sliderWrapper" dir="ltr">
            <Carousel nextIcon={nextIcon} prevIcon={prevIcon} interval={null}>
              <Carousel.Item>
                <img
                  className="d-block sliderImage matched-game-slider"
                  src={
                    direction === CONTENT_DIRECTION.RTL ? image1Ar : image1En
                  }
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block sliderImage matched-game-slider"
                  src={
                    direction === CONTENT_DIRECTION.RTL ? image2Ar : image2En
                  }
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="gameInfoBottomWrapper flex-grow-0">
          <div className="gameInfoButtons">
            <div />
            <button
              onClick={this.testRound}
              className="test-result-block-button start-practice-btn"
            >
              {t('Start practice')}
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.gameTest.gameInfoLoading,
    assessment: state.assessmentsReduces.assessment,
    error: state.gameTest.gameInfoError,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchAssessmentData })(GameInfo),
);
