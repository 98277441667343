import {
  GET_SJT2_TEST_QUESTION_SUCCESS,
  START_SJT2_TEST,
} from '../constants/ActionTypes';

const initialState = {
  testQuestions: [],
  isLoading: false,
  error: null,
  testTimer: Date.now() + 100000,
};

export default function situationalJudgmentTestPartTwoReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    // GET TEST QUESTIONS
    case GET_SJT2_TEST_QUESTION_SUCCESS: {
      return {
        ...state,
        testQuestions: action.payload.testQuestions,
        isLoading: false,
        error: null,
      };
    }
    // START TEST
    case START_SJT2_TEST: {
      return {
        ...state,
        testTimer: Date.now() + 10000,
      };
    }
    // DEFAULT
    default: {
      return state;
    }
  }
}
