import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import Loader from '../../components/Loader/Loader';
import setToken from '../../setToken';
import PageWrapper from '../PageWrapper';
import { withTranslation } from 'react-i18next';

class GameNotFound extends Component {
  goBack = () => {
    const { assessment, history } = this.props;
    if (assessment) {
      history.push(`/game-assessments/${assessment.parent_id}`);
    } else {
      history.push(`/dashboard`);
    }
  };
  componentDidMount() {
    const { match, history, fetchAssessmentData } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      // if (!userInfo) {
      //   fetchUserData();
      // }
      fetchAssessmentData(match.params.id);
    } else if (!token) {
      history.push('/login');
    }
  }
  render() {
    const { assessment, t } = this.props;
    if (!assessment) {
      return <Loader />;
    }

    return (
      <PageWrapper history={this.props.history}>
        <div className="container m-auto">
          <div className="row">
            <div className="col-12">
              <div className="auth-form-container text-center">
                <p className="auth-form-title">Sorry</p>
                <p className="auth-form-text" style={{ margin: '36px 0' }}>
                  {t(
                    'The game you are trying to access is not available anymore',
                  )}
                </p>
                <button className="tr8s-button green" onClick={this.goBack}>
                  {t('Back to all game based assessments')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assessment: state.assessmentsReduces.assessment,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchAssessmentData })(GameNotFound),
);
