import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentData } from '../../actions/getSimpleAssesstments';
import { fetchAssessmentByIdData } from '../../actions/getVacancyDetailsByAssessmentId';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import Progress from '../../components/TestProgressBar';
import setToken from '../../setToken';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import store from '../../store';

const QuestionsMock = [
  {
    company: 20,
    id: 1,
    options: [
      { id: 1, text: 'Assume this is a mistake and speak to your manager' },
      {
        id: 2,
        text:
          'Confront your manager regarding why you are being treated unfairly',
      },
      { id: 3, text: 'Complain to the Human Resources department' },
      { id: 4, text: 'Quit' },
    ],
    scales: [
      { id: 1, label: 'Thumbs Down', value: 0 },
      { id: 2, label: 'Thumbs Up', value: 1 },
    ],
    text:
      'Everyone in your department has received a brand new laptop except you. Choose your most and your least likely actions.',
    type: 'SjtQuestion',
  },
];

class SituationalJudgmentTestExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      pages: [],
      currentPage: 0,
      answerList: [],
    };
  }
  componentDidMount() {
    const { history, fetchAssessmentData, assessment, match } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      this.setState({
        pages: QuestionsMock,
        answerList: QuestionsMock,
      });

      setToken(token);
      if (!assessment) {
        fetchAssessmentData(match.params.id);
      }
    } else if (!token) {
      history.push('/login');
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const queryObject = parse(this.props.location.search);
    const { match, history } = this.props;
    history.replace(`/sjt-start/${match.params.id}?test=${queryObject.test}`);
  };

  isCheck = (e) => {
    const { value, name } = e.target;
    const { currentPage, pages } = this.state;

    const answerListNew = pages[currentPage].options
      .map((option) => {
        if (option.data?.value === +value) {
          delete option.data;
        }
        return option;
      })
      .map((option) => {
        if (option.id === +name) {
          return {
            ...option,
            data: {
              optionid: parseInt(name),
              value: parseInt(value),
            },
          };
        }
        return option;
      });

    const newPages = [...pages];
    newPages[currentPage].options = answerListNew;

    this.setState({
      answerList: answerListNew,
      pages: newPages,
    });
  };

  checkDisabledButton = () => {
    const { currentPage, pages } = this.state;
    const data = pages;

    const isMostSelected = data[currentPage].options.some(
      (option) => option.data?.value === 1,
    );
    const isLeastSelected = data[currentPage].options.some(
      (option) => option.data?.value === 0,
    );

    return isMostSelected && isLeastSelected;
  };

  render() {
    const { assessment, assessmentIsLoading, history, match, t } = this.props;

    const { disabledButton, pages, currentPage } = this.state;

    if (assessmentIsLoading || !assessment) {
      return <Loader />;
    }

    const direction = localStorage.getItem('contentDirection');

    return (
      <PageWrapper
        history={history}
        isDashboardHeader
        test={false}
        match={match}
        testType={'SjtData'}
        isDashboardFooter
      >
        <Link
          to={`/assessments/${
            store.getState().assessmentsReduces.currentApplicationId
          }`}
          className="goVacancy text-align-start"
        >
          <img
            src="/assets/img/arrow-right.svg"
            className={`arrowRight ${
              direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
            }`}
            alt="arrow"
          />
          {t('Back')}
        </Link>
        <div className="text-align-start">
          <h1
            className="content-title test-example"
            dangerouslySetInnerHTML={{
              __html: `${t('Practice Run', {
                title: t(assessment.test.title),
              })}`,
            }}
          ></h1>

          <div className="progressStatus mb-35">
            <Progress current={currentPage + 1} all={pages.length} />
          </div>

          <p className="test-question-title">
            {t(
              'Everyone in your department has received a brand new laptop except you Choose your actions',
            )}
          </p>

          <div className="personality-test-form">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">
                    <p>{t('Most')}</p>
                  </th>
                  <th scope="col">
                    <p>{t('Least')}</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {!!pages.length
                  ? pages[currentPage].options.map((option) => {
                      const isThumbUp = option.data?.value === 1;
                      const isThumbDown = option.data?.value === 0;

                      return (
                        <tr key={currentPage + ' ' + option.text}>
                          <th scope="row" className="title sjt-option-title">
                            {t(option.text)}
                          </th>
                          <td>
                            <label className="radiobox-container sjt-thumb-container">
                              <input
                                className="l"
                                type="radio"
                                name={`${option.id}`}
                                value="1"
                                checked={isThumbUp}
                                onChange={this.isCheck}
                              />
                              <img
                                className="thumbUp"
                                src="/assets/img/thumb.svg"
                                alt="thumb"
                              />
                              <span className="checkmarkRadio radio thum " />
                            </label>
                          </td>

                          <td>
                            <label className="radiobox-container sjt-thumb-container">
                              <input
                                className="r"
                                type="radio"
                                name={`${option.id}`}
                                value="0"
                                onChange={this.isCheck}
                                checked={isThumbDown}
                              />
                              <img
                                className="thumbDown"
                                src="/assets/img/thumb.svg"
                                alt="thumb"
                              />
                              <span className="checkmarkRadio radio" />
                            </label>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>

            <div className="d-flex justify-content-end mb-4">
              {currentPage === pages.length - 1 && (
                <button
                  disabled={!this.checkDisabledButton() || disabledButton}
                  className={`mt-0 mb-4 tr8s-button mb assessmentBtnNext 
                    ${this.checkDisabledButton() ? 'green' : 'gray'}
                  `}
                  onClick={this.handleSubmit}
                >
                  {t('Submit')}
                </button>
              )}
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    assessment: state.assessmentsReduces.assessment,
    assessmentIsLoading: state.assessmentsReduces.assessmentsLoading,
    assessmentError: state.assessmentsReduces.error,
    vacancy: state.getAssessmentByVacancyId.vacancy,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchAssessmentData,
    fetchAssessmentByIdData,
  })(SituationalJudgmentTestExample),
);
