import axios from 'axios';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { BASE_URL } from '../../constants/variables';
import setToken from '../../setToken';
// import { ethnicities } from './data/Ethnicity';
import { englishLevel } from './data/EnglishLevel';
import { education } from './data/Education';
import { jobLevel } from './data/JobLevel';
import { jobFunctions } from './data/JobFunction';
import countries from 'i18n-iso-countries';
import { useLanguage } from '../../hooks/useLanguage';
import languagesTranslateAR from 'cldr-localenames-modern/main/ar-AE/languages.json';
import languagesTranslateEN from 'cldr-localenames-modern/main/en-GB/languages.json';
import { LANGUAGES } from '../../models/language';
import _ from 'lodash';
import numeralSystems from 'numeral-systems';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { nativeLanguages } from './data/NativeLanguage';

const PersonalInformationForm = (props) => {
  const { i18n } = useTranslation();
  const [state, setupState] = useState({
    profileInfo: null,
  });
  const [isChecked, setIsChecked] = useState(false);
  const [nationality, setNationality] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/company/departments`).then((res) => {
      if (res.status === 200) {
        setDepartmentsList(res.data);
      }
    });
  }, []);

  const setState = (obj) => {
    setupState({ ...state, ...obj });
  };

  const { t } = useTranslation();
  const language = useLanguage();

  const getСountriesList = useCallback(() => {
    const countriesListObj = countries.getNames(language, {
      select: 'official',
    });
    return getArrayForSelect(countriesListObj);
  }, [language]);

  const getLanguageList = useCallback((obj, path) => {
    let langObj = _.get(obj, path);
    const filteredObj = {};
    for (const lang of nativeLanguages) {
      filteredObj[lang] = langObj[lang];
    }
    return getArrayForSelect(filteredObj);
  }, []);

  const getArrayForSelect = (obj) => {
    const selectList = [];

    for (const property in obj) {
      selectList.push({
        value: property,
        label: obj[property],
      });
    }

    return selectList;
  };

  useEffect(() => {
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    countries.registerLocale(require('i18n-iso-countries/langs/ar.json'));

    if (language) {
      const countriesList = getСountriesList().sort((item1, item2) => {
        return item1.label < item2.label ? -1 : 1;
      });
      setNationality(countriesList);

      const languagesList =
        language === LANGUAGES.EN
          ? getLanguageList(
              languagesTranslateEN,
              'main.en-GB.localeDisplayNames.languages',
            )
          : getLanguageList(
              languagesTranslateAR,
              'main.ar-AE.localeDisplayNames.languages',
            );

      setLanguages(languagesList);
    }
  }, [language, getСountriesList, getLanguageList]);

  const handleConsentChange = (e) => {
    let isChecked = e.target.checked;

    setIsChecked(isChecked);
  };

  const formik = useFormik({
    initialValues: {
      id: ' ',
      gender: ' ',
      first_name: ' ',
      last_name: ' ',
      birthday: ' ',
      day: ' ',
      month: ' ',
      year: ' ',
      email: ' ',
      ethnicity: ' ',
      nationality: ' ',
      native_language: ' ',
      english_level: ' ',
      education: ' ',
      country_of_residence: ' ',
      department: ' ',
      job_level: ' ',
      primary_job_function: ' ',
      consent_provided: true,
      privacy_policy_accepted_temp: false,
      type: 'Candidate',
      ...state.profileInfo,
    },
    onSubmit: async (values) => {
      values.consent_provided = true;
      delete values.privacy_policy_accepted_temp; // = true;
      delete values.related_jobs;
      delete values.applications;
      values.birthday = values.year
        .concat('-' + values.month)
        .concat('-' + values.day);
      //alert(JSON.stringify(values, null, 2));
      await axios
        .patch(`${BASE_URL}/api/auth/users/me/`, values)
        .then((res) => {
          if (res.status !== 200) {
            throw Error(res.message);
          }
          return res;
        })
        .then((res) => {
          props.clearUser();
          props.history.push('/dashboard');
        })
        .catch((err) => {});
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);

    const { userInfo } = props;

    if (!!userInfo) {
      if (!userInfo.privacy_policy_accepted) {
        props.history.push('/confirmation-of-global-policy');
      }
      delete userInfo['job'];
      delete userInfo['company'];
      setState({ profileInfo: userInfo });

      setIsChecked(userInfo.consent_provided);
      Object.keys(userInfo).forEach((key) => {
        formik.setFieldValue(key, userInfo[key]);
        if (key === 'birthday') {
          formik.setFieldValue('year', dob(userInfo[key], 0));
          formik.setFieldValue('month', dob(userInfo[key], 1));
          formik.setFieldValue('day', dob(userInfo[key], 2));
        }
      });
    }
    // eslint-disable-next-line
  }, [props.userInfo]);

  const dob = (birthday, key) => {
    if (!birthday) return;
    let dboarray = birthday.split('-');
    return dboarray[key];
  };

  if (!formik.values.email) {
    return (
      <div>
        <div style={{ marginTop: '150px' }} />
        <Loader />
      </div>
    );
  }

  const direction = localStorage.getItem('contentDirection');

  return (
    <div>
      <div className="w-100">
        <div className="row">
          <div className="col-md-12">
            <p className="profile-info-title">{t('My Profile')}</p>
          </div>
          <div
            // ? xxl is available starting with v5, though the new version is in beta
            // ? and it introduces certain breaking changes
            className="col-12 col-xxl-8 offset-xxl-2"
            style={{ display: 'flex' }}
          >
            <form className="col-md-12 px-0" onSubmit={formik.handleSubmit}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="col-md-6">
                  <div className="form-group">
                    {formik.values.first_name && (
                      <label className="label">{t('First Name')} *</label>
                    )}
                    <input
                      className={`${
                        formik.values.first_name
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      name="first_name"
                      id="first_name"
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                      type="text"
                      placeholder={`${t('First Name')} *`}
                      required
                    />
                  </div>

                  <div className="form-group">
                    {formik.values.last_name && (
                      <label className="label">{t('Last Name')} *</label>
                    )}
                    <input
                      className={`${
                        formik.values.last_name
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      name="last_name"
                      id="last_name"
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                      type="text"
                      placeholder={`${t('Last Name')} *`}
                      required
                    />
                  </div>

                  <div className="form-group">
                    {formik.values.email && (
                      <label className="label">{t('E-Mail')} *</label>
                    )}
                    <input
                      name="email"
                      id="email"
                      disabled={true}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      type="email"
                      className={`${
                        formik.values.email
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('E-Mail')} *`}
                      required
                    />
                  </div>

                  <label className="label">{t('Date of Birth')} *</label>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <select
                          name="day"
                          id="day"
                          value={formik.values.day}
                          onChange={formik.handleChange}
                          className="form-control profile-setting-input activeInputProfile"
                          required
                        >
                          <option value="">{t('Day')}</option>
                          {[...Array(31)].map((option, i) => (
                            <option
                              key={i < 10 ? '0' + i : i}
                              value={i < 9 ? '0' + (i + 1) : i + 1}
                            >
                              {language === LANGUAGES.EN
                                ? i + 1
                                : numeralSystems(i + 1, 'arabic')}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col">
                      <div className="form-group">
                        <select
                          name="month"
                          id="month"
                          value={formik.values.month}
                          onChange={formik.handleChange}
                          className={`${
                            true
                              ? 'form-control profile-setting-input activeInputProfile'
                              : 'form-control profile-setting-input'
                          }`}
                          required
                        >
                          <option value="">{t('Month')}</option>

                          {[...Array(12)].map((option, i) => (
                            <option
                              key={i}
                              value={i < 9 ? '0' + (i + 1) : i + 1}
                            >
                              {language === LANGUAGES.EN
                                ? i + 1
                                : numeralSystems(i + 1, 'arabic')}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col">
                      <div className="form-group">
                        <select
                          name="year"
                          id="year"
                          value={formik.values.year}
                          onChange={formik.handleChange}
                          className={`${
                            true
                              ? 'form-control profile-setting-input activeInputProfile'
                              : 'form-control profile-setting-input'
                          }`}
                          required
                        >
                          <option value="">{t('Year')}</option>
                          {[...Array(99)].map((option, i) => (
                            <option key={i} value={2019 - i}>
                              {language === LANGUAGES.EN
                                ? 2019 - i
                                : numeralSystems(2019 - i, 'arabic')}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    {formik.values.gender && (
                      <label className="label">{t('Gender')} *</label>
                    )}
                    <select
                      name="gender"
                      id="gender"
                      onChange={formik.handleChange}
                      value={formik.values.gender}
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Gender')} *`}
                      required
                    >
                      <option value="">{t('Gender')} *</option>
                      <option value={1}>{t('Male')}</option>
                      <option value={2}>{t('Female')}</option>
                    </select>
                  </div>

                  {/* Ethinicity Will be commented out and will be optional for companies */}
                  {/* <div className="form-group">
                    {formik.values.ethnicity && (
                      <label className="label">{t('Ethnicity')} *</label>
                    )}
                    <select
                      name="ethnicity"
                      id="ethnicity"
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Ethnicity')} *`}
                      onChange={formik.handleChange}
                      value={formik.values.ethnicity}
                      required
                    >
                      <option value="">{t('Ethnicity')} *</option>
                      {ethnicities.map((item) => (
                        <option key={item.value} value={item.value}>
                          {t(item.label)}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  <div className="form-group">
                    {formik.values.nationality && (
                      <label className="label">{t('Nationality')} *</label>
                    )}
                    <select
                      name="nationality"
                      id="nationality"
                      onChange={formik.handleChange}
                      value={formik.values.nationality}
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Nationality')} *`}
                      required
                    >
                      <option value="">{t('Nationality')} *</option>
                      {(nationality || []).map((item) => (
                        <option key={item.value} value={item.value}>
                          {t(item.label)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {formik.values.native_language && (
                      <label className="label">{t('Native Language')} *</label>
                    )}
                    <select
                      name="native_language"
                      id="native_language"
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Native Language')} *`}
                      onChange={formik.handleChange}
                      value={formik.values.native_language}
                      required
                    >
                      <option value="">{t('Native Language')} *</option>

                      {languages.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {t(item.label)}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group">
                    {formik.values.english_level && (
                      <label className="label">{t('English Level')} *</label>
                    )}
                    <select
                      name="english_level"
                      id="english_level"
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('English Level')} *`}
                      onChange={formik.handleChange}
                      value={formik.values.english_level}
                      required
                    >
                      <option value="">{t('English Level')} *</option>
                      {englishLevel.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {t(item.label)}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group">
                    {formik.values.education && (
                      <label className="label">{t('Education')} *</label>
                    )}
                    <select
                      name="education"
                      id={'education'}
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Education')} *`}
                      onChange={formik.handleChange}
                      value={formik.values.education}
                      required
                    >
                      <option value="">{t('Education')} *</option>
                      {education.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {t(item.label)}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="label">
                      {t('Country of Residence')} *
                    </label>
                    <select
                      name="country_of_residence"
                      id="country_of_residence"
                      onChange={formik.handleChange}
                      value={formik.values.country_of_residence}
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Country of Residence')} *`}
                      // onChange={}
                      // value={""}
                      required
                    >
                      <option value="">{t('Country of Residence')} *</option>
                      {(nationality || []).map((item) => (
                        <option key={item.value} value={item.value}>
                          {t(item.label)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    {formik.values.department && (
                      <label className="label">{t('Department')} *</label>
                    )}
                    <select
                      name="department"
                      id="department"
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Department')} *`}
                      onChange={formik.handleChange}
                      value={
                        formik.values.department
                          ? formik.values.department.id
                          : ''
                      }
                      required
                    >
                      <option value="">{t('Department')} *</option>
                      {departmentsList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'ar' && item.name_ar
                            ? item.name_ar
                            : item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    {formik.values.job_level && (
                      <label className="label">{t('Job Level')} *</label>
                    )}
                    <select
                      name="job_level"
                      id="job_level"
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Job Level')} *`}
                      onChange={formik.handleChange}
                      value={formik.values.job_level}
                      required
                    >
                      <option value="">{t('Job Level')} *</option>
                      {jobLevel.map((item) => (
                        <option key={item.value} value={item.value}>
                          {t(item.label)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="label">
                      {t('Primary Job Function')} *
                    </label>
                    <select
                      name="primary_job_function"
                      id="primary_job_function"
                      onChange={formik.handleChange}
                      value={formik.values.primary_job_function}
                      className={`${
                        1
                          ? 'form-control profile-setting-input activeInputProfile'
                          : 'form-control profile-setting-input'
                      }`}
                      placeholder={`${t('Primary Job Function')} *`}
                      // onChange={}
                      // value={""}
                      required
                    >
                      <option value="">{t('Primary Job Function')} *</option>
                      {jobFunctions.map((item) => (
                        <option key={item.value} value={item.value}>
                          {t(item.label)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <p
                className="text-align-start"
                style={{
                  paddingLeft: '15px',
                  color: 'rgba(51, 51, 51, 0.43)',
                  fontSize: '10px',
                }}
              >
                * {t('Mandatory field')}
              </p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80%',
                    margin: '30px 0 50px 0',
                  }}
                >
                  <label
                    className={`checkbox-container ${
                      direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                    }`}
                    style={{
                      lineHeight: '12px',
                      fontSize: '10px',
                    }}
                  >
                    <input
                      type="checkbox"
                      // checked={formik.values.privacy_policy_accepted_temp}
                      name="privacy_policy_accepted_temp"
                      id="privacy_policy_accepted_temp"
                      // onChange={formik.handleChange}
                      // value={formik.values.privacy_policy_accepted_temp}
                      onChange={handleConsentChange}
                      checked={isChecked}
                      required
                    />

                    <span className="checkmark" />
                    <span
                      className="text-left d-inline-flex align-items-center"
                      style={{ minHeight: 25 }}
                    >
                      {t(
                        'I give consent to the collection of all background data and understand that this is for research purposes only',
                      )}
                    </span>
                  </label>

                  <button
                    type="submit"
                    className="tr8s-button green saveProfile mr-0"
                    style={{ width: '110px' }}
                    disabled={!true}
                  >
                    {t('Save')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.authUser.userInfo,
  };
};

export default connect(mapStateToProps)(PersonalInformationForm);
