import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import AssessmentSumProgressBar from './AssessmentSumProgressBar';
import { TraitScore } from '../../models/applications';

interface AssessmentSumProgressBlockProps {
  scores: TraitScore[];
  isAppendixPage: boolean;
}

const AssessmentSumProgressBlock = ({
  scores,
  isAppendixPage,
}: AssessmentSumProgressBlockProps) => {
  const { t } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  const customTraits = scores.filter((item) => item.trait.group_id === null);

  return (
    <section className="assessment-sec page-break">
      {isAppendixPage ? (
        <h2 className="progress-report-heading">{t('Appendix Page Head')}</h2>
      ) : (
        <h2 className="progress-report-heading">{t('Assessment Summary')}</h2>
      )}
      {customTraits.length === 0 || isAppendixPage ? (
        <div className="d-flex flex-wrap">
          <div className="pr-3 w-50 progress-report-section">
            <div className="progress-report">
              <p>
                <span>{t('Evolve')}</span>
                {t('Creating for success')}
              </p>
              {scores
                .filter((item) => item.trait.group_id === 1)
                .map((item) => (
                  <AssessmentSumProgressBar
                    key={item.trait.id}
                    item={item}
                    customTraitsCount={customTraits.length}
                    showProgressBar={!isAppendixPage}
                  />
                ))}
            </div>
            <div className="progress-report progress-report-execute">
              <p>
                <span>{t('Execute')}</span>
                {t('Getting the job done')}
              </p>
              {scores
                .filter((item) => item.trait.group_id === 3)
                .map((item) => (
                  <AssessmentSumProgressBar
                    key={item.trait.id}
                    item={item}
                    customTraitsCount={customTraits.length}
                    showProgressBar={!isAppendixPage}
                  />
                ))}
            </div>
          </div>
          <div
            className={`${
              direction === CONTENT_DIRECTION.RTL ? 'pr-3' : 'pl-3'
            } w-50 progress-report`}
          >
            <p>
              <span>{t('Engage')}</span>
              {t('Bringing people along')}
            </p>
            {scores
              .filter((item) => item.trait.group_id === 2)
              .map((item) => (
                <AssessmentSumProgressBar
                  key={item.trait.id}
                  item={item}
                  customTraitsCount={customTraits.length}
                  showProgressBar={!isAppendixPage}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-wrap">
          {customTraits.map((item, i) => (
            <div className="pr-3 w-50">
              <div className="progress-report-custom">
                <AssessmentSumProgressBar
                  key={item.trait.id}
                  item={item}
                  customTraitsCount={customTraits.length}
                  showProgressBar={!isAppendixPage}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default AssessmentSumProgressBlock;
