import {
  FILTER_JOB,
  GET_JOBS_FAILURE,
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
} from '../constants/ActionTypes';

const initialState = {
  error: null,
  isLoading: true,
  jobs: null,
};

export default function authUserReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case GET_JOBS_REQUEST: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case GET_JOBS_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_JOBS_FAILURE: {
      return {
        ...state,
        error: payload.error,
      };
    }
    case FILTER_JOB: {
      return {
        ...state,
        jobs: Array.isArray(state.jobs)
          ? state.jobs.filter((job) => job.id !== payload.id)
          : state.jobs,
      };
    }
    default: {
      return state;
    }
  }
}
