import React, { Component } from 'react';
import { connect } from 'react-redux';
import DriversListMobile from './components/DriversListMobile';
import ImportantCategoryHeaderMobile from './components/ImportantCategoryHeaderMobile';

class CareerDriversMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCategories: [],
    };
  }

  componentDidMount() {
    const openCategories = this.props.categories.map(() => false);
    this.setState({ openCategories });
  }

  handleChangeOpenCategories = (idx) => {
    const openCategoriesCopy = [...this.state.openCategories];
    openCategoriesCopy[idx] = !openCategoriesCopy[idx];
    this.setState({ openCategories: openCategoriesCopy });
  };

  render() {
    const { drivers, driverIds, categories, handleCheckedChange } = this.props;
    const { openCategories } = this.state;

    return (
      <div className="d-flex flex-column">
        {categories.map((cat, catIdx) => {
          const isOpen = openCategories[catIdx];
          return (
            <div key={cat.id}>
              <ImportantCategoryHeaderMobile
                isOpen={isOpen}
                handleChangeOpenCategories={() =>
                  this.handleChangeOpenCategories(catIdx)
                }
                column={cat}
                tasks={cat.driverIds.map((id) => drivers[id])}
              />

              {!!driverIds?.length && isOpen && (
                <DriversListMobile
                  drivers={driverIds.map((id) => drivers[id])}
                  categoryId={cat.id}
                  categories={categories}
                  handleCheckedChange={handleCheckedChange}
                  catIdx={catIdx}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.careerDriver.isLoading,
    assessment: state.careerDriver.assessment,
    careerDriversData: state.careerDriver.careerDriversData,
  };
};

export default connect(mapStateToProps)(CareerDriversMobile);
