export const testQuestions = {
  results: [
    {
      id: 1,
      text:
        'Please use the scale provided to identify your preference for each statement.',
      options: [
        {
          id: 1,
          text: 'I am interested in how things work',
        },
        {
          id: 2,
          text: 'I adapt to new challenges',
        },
        {
          id: 3,
          text: 'I am a creative person',
        },
        {
          id: 4,
          text: 'I am satisfied when I resolve a complex problem',
        },
      ],
      scales: [
        {
          id: 1,
          label: 'Strongly Disagree',
          value: 1,
        },
        {
          id: 2,
          label: 'Disagree',
          value: 2,
        },
        {
          id: 3,
          label: 'Slightly Disagree',
          value: 3,
        },
        {
          id: 4,
          label: 'Neutral',
          value: 4,
        },
        {
          id: 5,
          label: 'Slightly Agree',
          value: 5,
        },
        {
          id: 6,
          label: 'Agree',
          value: 6,
        },
        {
          id: 8,
          label: 'Strongly Agree',
          value: 7,
        },
      ],
    },
  ],
};
