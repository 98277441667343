import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { CareerDriver } from '../../models/careerDrivers';

export interface CareerDriversProps {
  dataMost: CareerDriver[];
  dataLeast: CareerDriver[];
  name: string;
}

interface CareerDriversCardsProps {
  data: CareerDriver[];
}

const CareerDriversCards = ({ data }: CareerDriversCardsProps) => {
  const { i18n } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  return (
    <div
      className={`row justify-content-center ${
        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
      }`}
    >
      {data.map((item) => {
        return (
          <div key={item.id} className="col-md-4">
            <div className="report-driverCard">
              <div className="mb-3 d-flex">
                <img src={item.icon} alt="" className="report-driverImg" />
                <span className="report-driverCard-title">
                  {i18n.language === 'ar' && item.name_ar
                    ? item.name_ar
                    : item.name}
                </span>
              </div>
              <p>
                {i18n.language === 'ar' && item.description_ar
                  ? item.description_ar
                  : item.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CareerDriversBlock = ({
  dataMost,
  dataLeast,
  name,
}: CareerDriversProps) => {
  const { t } = useTranslation();

  return (
    <section className="assessment-sec career-driver-block">
      <h2>{t('Career drivers')}</h2>
      <p>
        {t(
          'This section provides greater insight into preferred motivators, aspirations and values',
          {
            name,
          },
        )}
      </p>
      <div className="thumb-block">
        <h3>
          {t('Most important career drivers to', {
            name,
          })}
        </h3>
        <CareerDriversCards data={dataMost} />
      </div>
      <div className="thumb-block">
        <h3>
          {t('Least important career drivers to', {
            name,
          })}
        </h3>

        <CareerDriversCards data={dataLeast} />
      </div>
    </section>
  );
};

export default CareerDriversBlock;
