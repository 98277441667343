import axios from 'axios';
import { DELETE_VACANCY_REQUEST } from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const deleteVacancyRequest = (isLoading) => {
  return {
    type: DELETE_VACANCY_REQUEST,
    payload: {
      isLoading,
    },
  };
};

export const deleteVacancy = (
  vacancyId,
  fetchVacanciesData,
  callBack,
  history = '',
) => {
  return async (dispatch) => {
    dispatch(deleteVacancyRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios
      .post(`${BASE_URL}${PATH}applications/${vacancyId}/archive/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        callBack();
        fetchVacanciesData(history);
        dispatch(deleteVacancyRequest(false));
      })
      .catch((err) => {
        unauthorized(err.response.status);
        // dispatch(deleteVacancyFailure(err))
        dispatch(deleteVacancyRequest(false));
      });
  };
};
