import React from 'react';
import { connect } from 'react-redux';
import { fetchUserData, userInfoSuccess } from '../../actions/userPersonalInfo';
import PageWrapper from '../../components/PageWrapper';
import PersonalInformationForm from '../../components/ProfileSettings/PersonalInformationForm';
import setToken from '../../setToken';

class ProfileSettings extends React.Component {
  state = {
    click: false,
  };

  componentDidMount() {
    const { history } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      fetchUserData(history);
      // fetchProfileDictionaryData();
    }
    if (!token) {
      history.push('/login');
    }
  }

  updateClick = () => {
    this.setState({ click: true });
  };

  render() {
    const { profileDictionary, userInfo, match } = this.props;

    // if (isLoadingDictionary || isLoadingUserInfo) {
    //   return <Loader />;
    // }

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        isDashboardFooter
      >
        <PersonalInformationForm
          clearUser={this.props.userInfoSuccess}
          profileDictionary={profileDictionary}
          userInfo={userInfo}
          history={this.props.history}
          match={match}
          updateClick={this.updateClick}
        />
      </PageWrapper>
    );
  }
}

export default connect(null, { fetchUserData, userInfoSuccess })(
  ProfileSettings,
);
