import React from 'react';

const TestProgressBar = ({ current = 0, all = 0 }) => (
  <div className="testProgreeBarWrapper">
    <div className="testProgreeBarInfo">
      <span>{current}</span>
      <span>/</span>
      <span>{all}</span>
    </div>
    <div className="progressStatusBarWrapper">
      <div
        className="progressStatusBaColor"
        style={{ width: `${(current * 100) / all}%` }}
      />
    </div>
  </div>
);

export default TestProgressBar;
