import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ForgotPasswordSuccessForm extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="auth-form-container text-center">
        <p className="auth-form-title">{t('Thank you')}</p>

        <p className="auth-form-text">
          {t('Check your email and click on the link to update your password')}
          <br />
          {t('This link will expire in 4 hours')}
        </p>

        <Link className="tr8s-button green theme-background" to="/login">
          {t('Continue to Login')}
        </Link>
      </div>
    );
  }
}

export default withTranslation()(ForgotPasswordSuccessForm);
