import React from 'react';
import { useSelector } from 'react-redux';
import { selectCompanyTheme } from '../../../reducers/refCompanyReducer';
import { IStep } from '../models';
import { useTranslation } from 'react-i18next';

export interface StepProps extends Pick<IStep, 'label'> {
  isUpcoming: boolean;
  backgroundColor: React.CSSProperties['backgroundColor'];
}

const Step: React.FC<StepProps> = (props) => {
  const companyTheme = useSelector(selectCompanyTheme);

  const style: React.CSSProperties = React.useMemo(() => {
    return {
      background: props.isUpcoming ? '#eee' : companyTheme,
      borderRight: '1px solid white',
    };
  }, [props.isUpcoming, companyTheme]);
  return (
    <div
      className="py-2 text-white text-center flex-grow-1 font-weight-bold"
      style={style}
    >
      {props.label}
    </div>
  );
};

export interface StepperProps {
  stepLabels: IStep['label'][];
  stepIndex: number;
  onNext?: (step: number) => void;
  onBack?: (step: number) => void;
}

const Stepper: React.FC<StepperProps> = (props) => {
  const companyTheme = useSelector(selectCompanyTheme);
  const { t } = useTranslation();

  return (
    <div className="d-none d-sm-flex mt-3">
      {props.stepLabels.map((step, i) => {
        return (
          <Step
            key={step}
            label={t(step)}
            backgroundColor={companyTheme}
            isUpcoming={i > props.stepIndex}
          />
        );
      })}
    </div>
  );
};

export default Stepper;
