import React from 'react';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';

export interface Step {
  status: 'active' | 'inactive' | 'knocked-out';
  label: string;
  stagesLabel?: string;
  description?: string;
}

const iconSize = 25;
const knockedOutIcon = (
  <div
    className="bg-warning text-white text-center rounded-circle mr-2"
    style={{ height: iconSize, width: iconSize, lineHeight: `${iconSize}px` }}
  >
    <i className="fas fa-exclamation-triangle" />
  </div>
);

export interface StepsProps {
  steps: Step[];
}

const Steps: React.FC<StepsProps> = (props) => {
  const direction = localStorage.getItem('contentDirection');

  return (
    <ul
      className={`tr8s-steps mb-3 ${
        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
      }`}
    >
      {props.steps.map((step, i) => {
        if (i + 1 !== props.steps.length) {
          return (
            <li key={i}>
              {step.status === 'knocked-out' ? (
                knockedOutIcon
              ) : (
                <span
                  className={
                    step.status === 'active'
                      ? 'tr8s-step-span active'
                      : 'tr8s-step-span'
                  }
                >
                  {i + 1}
                </span>
              )}
              <p dangerouslySetInnerHTML={{ __html: step.label }}></p>
              <p>{step.description}</p>
            </li>
          );
        } else {
          return (
            <li key={i}>
              {step.status === 'knocked-out' ? (
                knockedOutIcon
              ) : (
                <span
                  className={
                    step.status === 'active'
                      ? 'tr8s-step-span tr8s-steps-last active'
                      : 'tr8s-step-span tr8s-steps-last'
                  }
                >
                  {i + 1}
                </span>
              )}
              <div>
                <p dangerouslySetInnerHTML={{ __html: step.label }}></p>
                <p>{step.description}</p>
              </div>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default Steps;
