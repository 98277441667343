import React from 'react';
import { Link } from 'react-router-dom';
import ChangePasswordForm from '../../components/Auth/ChangePasswordForm';
import PageWrapper from '../../components/PageWrapper';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const direction = localStorage.getItem('contentDirection');

  return (
    <PageWrapper history={props.history} match={props.match}>
      <Link to={`/dashboard`} className="goVacancy text-align-start">
        <img
          src="/assets/img/arrow-right.svg"
          className={`arrowRight ${
            direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
          }`}
          alt="arrow"
        />
        <span>{t('Back to Dashboard')}</span>
      </Link>

      <div className="container m-auto">
        <div className="row">
          <div className="col-12">
            <ChangePasswordForm history={props.history} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ChangePassword;
