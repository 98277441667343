import axios from 'axios';
import {
  ACCEPT_PRIVACY_POLICY_FAILURE,
  ACCEPT_PRIVACY_POLICY_REQUEST,
  ACCEPT_PRIVACY_POLICY_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';
import { userInfoFailure, userInfoSuccess } from './userPersonalInfo';

const privacyPolicyRequest = (isLoading) => {
  return {
    type: ACCEPT_PRIVACY_POLICY_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const privacyPolicySuccess = (status) => {
  return {
    type: ACCEPT_PRIVACY_POLICY_SUCCESS,
    payload: {
      status,
    },
  };
};

const privacyPolicyFailure = (error) => {
  return {
    type: ACCEPT_PRIVACY_POLICY_FAILURE,
    payload: {
      error,
    },
  };
};

export const AcceptPrivacyPolicy = (history) => {
  return async (dispatch) => {
    dispatch(privacyPolicyRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios
      .post(`${BASE_URL}/api/auth/users/accept-privacy-policy/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(privacyPolicySuccess(res.data.success));
        dispatch(privacyPolicyRequest(false));

        if (!!res.data.success) {
          axios(`${BASE_URL}/api/auth/users/me/`)
            .then((res) => {
              const { data } = res;
              dispatch(userInfoSuccess(data));
              history.push('/personal-information');
            })
            .catch((err) => {
              dispatch(userInfoFailure(err));
            });
        }
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(privacyPolicyFailure(err));
        dispatch(privacyPolicyRequest(false));
      });
  };
};
