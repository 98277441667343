import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhiteboxVideoAssessmentProps } from '../models';
import { statusCircleStyle } from '../utils';
import Button from './Button';
import Layout from './Layout/Layout';
import Video from './Video';
import { CONTENT_DIRECTION } from '../../../constants/contentDirection';
import { useSelector } from 'react-redux';
import { selectVideoAssessmentStatus, selectVideoUploadStatus } from '../hooks';

const WhiteboxVideoAssessmentBase: React.FC<WhiteboxVideoAssessmentProps> = (
  props,
) => {
  const { t } = useTranslation();

  const isAssessment = React.useMemo(() => {
    return !props.practiceProps;
  }, [props.practiceProps]);

  const direction = localStorage.getItem('contentDirection');

  const [readyCountdown, setReadyCountdown] = useState<number>(0);

  const handleStart = (event: React.MouseEvent) => {
    setReadyCountdown(3);
    const interval = setInterval(() => {
      let nextVal = 0;
      setReadyCountdown((val) => {
        nextVal = val && val - 1;
        return nextVal;
      });
      if (nextVal <= 0) {
        clearInterval(interval);
        props.onStartRecording(event);
      }
    }, 1000);
  };

  let durationInMinutes = 0;
  if (props.questionData) {
    const duration = props.questionData.duration;
    durationInMinutes = duration.minutes + duration.seconds / 60;
  }

  const isUploading = useSelector(selectVideoUploadStatus) === 'loading';
  const isCompleting = useSelector(selectVideoAssessmentStatus) === 'loading';
  const isSaving = isUploading || isCompleting;

  return (
    <Layout
      stepIndex={props.stepIndex}
      stepLabels={props.stepLabels}
      title={props.title}
      back={props.back}
      left={
        <div className="flex-grow-1 d-flex flex-column text-align-start">
          <h2>
            {props.questionData
              ? `${isAssessment ? t('Question') : t('Practice Question')} (${
                  props.questionData.questionIndex + 1
                }/${props.questions.length})`
              : t('Question')}
          </h2>
          <p className="mt-3">{t(props.questionData?.question.text || '')}</p>
          <div className="text-align-start mt-auto d-flex flex-wrap justify-content-between align-items-center align-items-xl-start">
            <p className="mb-0">
              {props.questionData && (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'You will have up to min to answer this question',
                        {
                          minutesNumber: durationInMinutes,
                        },
                      ),
                    }}
                  ></span>
                  <br />
                </>
              )}
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Instructions Press Ready once you are prepared to start recording',
                  ),
                }}
              ></span>
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Once done answering the question press Done with Answer',
                  ),
                }}
              ></span>
              {props.practiceProps?.text}
            </p>
          </div>
        </div>
      }
      right={
        <Video
          src={props.src}
          muted={props.isInitial || props.isRecording || isAssessment}
          controls={props.isStopped && !isAssessment}
          overlayContent={
            !!readyCountdown ? (
              <div className="standby-recording-overlay-content">
                <h1 className="text-white text-center">{readyCountdown}</h1>
              </div>
            ) : props.isRecording || typeof props.src === 'string' ? null : (
              <div className="standby-recording-overlay-content">
                {!isSaving && (
                  <h2 className="text-white text-center">
                    {t('Press Ready to record')}
                  </h2>
                )}
              </div>
            )
          }
          footer={
            <div className="mt-3">
              <div className="d-flex align-items-center">
                <div
                  className={clsx(
                    'rounded-circle',
                    props.isRecording ? 'bg-danger' : 'bg-secondary',
                  )}
                  style={statusCircleStyle}
                />
                <span
                  className={clsx('ml-2', {
                    'text-secondary': !props.isRecording,
                  })}
                >
                  {props.isRecording
                    ? `${t('Recording')} ${props.countdown}`
                    : props.isStopped
                    ? t('Stopped')
                    : t('Press Ready to record')}
                </span>
              </div>
              <div className="mt-3 d-flex justify-content-between flex-wrap">
                <div>
                  <Button
                    onClick={handleStart}
                    disabled={props.isRecording || !!readyCountdown || isSaving}
                    className={`${
                      direction === CONTENT_DIRECTION.RTL ? 'ml-3' : ''
                    }`}
                  >
                    {isSaving ? t('Please wait Submitting') : t('Ready')}
                  </Button>
                  <Button
                    disabled={!props.isRecording}
                    onClick={props.onStopRecording}
                    className={`${
                      direction === CONTENT_DIRECTION.RTL ? '' : 'ml-3'
                    }`}
                    color="default"
                  >
                    {t('Done with Answer')}
                  </Button>
                </div>
                <div>
                  {props.practiceProps && (
                    <props.practiceProps.Button countdownS={props.countdownS} />
                  )}
                </div>
              </div>
            </div>
          }
        />
      }
    />
  );
};

export default WhiteboxVideoAssessmentBase;
