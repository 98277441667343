import axios from 'axios';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import Card from '../../components/Card';
import Loader from '../../components/Loader/Loader';
import Title from '../../components/Title';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import unauthorized from '../../unauthorized';
import Modal from '../ModalDeleteVacancy';
import PageWrapper from '../PageWrapper';
import GameHeader from '../GameHeader';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { submitGameAnswers } from '../../utils/games';
import DOMPurify from 'dompurify';

const MatchedGame = (props) => {
  // Game Matched
  const [currentPage, setCurrentPage] = useState(0);
  const [isMounted, setMounted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [, setEndPage] = useState(false);
  const [isShowModal, setShowModeal] = useState(false);

  const { t } = useTranslation();

  const componentDidMount = React.useCallback(() => {
    const token = localStorage.getItem('token');
    const queryObject = parse(props.location.search);

    if (token) {
      setToken(token);

      axios
        .get(
          `${BASE_URL}${PATH}tests/${queryObject.test}/questions/?page_size=1000`,
        )
        .then((res) => {
          if (200 === res.status) {
            setPages(res.data.results);
            if (res.data.results.length === 1) {
              setEndPage(true);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          unauthorized(err.response.status);
        });
    }
  }, [props.location.search]);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
      componentDidMount();
    }
  }, [isMounted, componentDidMount]);

  const nextPage = (data) => {
    if (currentPage < pages.length) {
      const nextPage = currentPage + 1;
      pages[currentPage].data = data;

      if (nextPage === pages.length) {
        setEndPage(true);
        onSubmit();
        return;
      }
      setCurrentPage(nextPage);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const collectedData = [];
    pages.forEach((question) => {
      if (question.id && question.hasOwnProperty('data')) {
        collectedData.push({
          assessment: props.match.params.id,
          data: question.data,
          question: +question.id,
        });
      }
    });

    submitGameAnswers(collectedData, props.match.params.id)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          props.history.replace(
            `/assessments/${props.match.params.id}/games-completed`,
          );
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const endGame = () => {
    setShowModeal(true);
  };

  const continueGameModal = () => {
    setShowModeal(false);
  };

  const onCloseGameModal = () => {
    onSubmit();
    setShowModeal(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {isShowModal && (
        <Modal
          onCancel={continueGameModal}
          onDelete={onCloseGameModal}
          text={t(
            'Are you sure want to end game? Once you exit you cannot restart the game',
          )}
          bntCancel={t('Cancel')}
          btnSuccess={t('End game')}
        />
      )}
      <PageWrapper isDashboardHeader history={props.history} isDashboardFooter>
        <div className="w-100 mb-5">
          <GameHeader
            allTask={pages.length}
            currentTask={currentPage + 1}
            gameType={t('Matched')}
            timerSeconds={120}
            canForceEnd={true}
            stopGameHandler={endGame}
            onTimeEnd={onCloseGameModal}
          />
          <div className={`${style.gameTaskWrapper}`}>
            <p className={`${style.gameDescription} font-weight-bold`}>
              {t('Does the meaning match the', {
                data: !!pages.length ? t(pages[currentPage].operation) : '',
              })}
            </p>
            {pages.length > 0 ? (
              <>
                <div className={style.gameBody}>
                  <Title>{t('Meaning')}</Title>

                  <Card>
                    <span style={{ color: pages[currentPage].meaning_color }}>
                      {t(pages[currentPage].meaning_text.toLowerCase())}
                    </span>
                  </Card>
                  {pages[currentPage].operation === 'picture' ? (
                    <Card>
                      {pages[currentPage].operation === 'picture' ? (
                        <img
                          className={style.img}
                          src={DOMPurify.sanitize(
                            pages[currentPage].target_image,
                          )}
                          alt={t('Meaning')}
                        />
                      ) : (
                        <span
                          style={{ color: pages[currentPage].mening_color }}
                        >
                          {t(pages[currentPage].target_text.toLowerCase())}
                        </span>
                      )}
                    </Card>
                  ) : (
                    <Card>
                      <span style={{ color: pages[currentPage].target_color }}>
                        {t(pages[currentPage].target_text.toLowerCase())}
                      </span>
                    </Card>
                  )}
                  <Title>
                    {pages[currentPage].operation === 'picture'
                      ? t(pages[currentPage].operation)
                      : `${t(pages[currentPage].operation)}`}
                  </Title>
                  <div className={style.buttons}>
                    <div
                      onClick={() => nextPage(false)}
                      className={style.confirmButton}
                    >
                      {t('No')}
                    </div>
                    <div
                      onClick={() => nextPage(true)}
                      className={style.confirmButton}
                    >
                      {t('Yes')}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default MatchedGame;
