import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import Progress from '../../components/TestProgressBar';
import { BASE_URL, PATH } from '../../constants/variables';
import setToken from '../../setToken';
import unauthorized from '../../unauthorized';
import PersonalityFormMobile from './components/PersonalityFormMobile';
import PersonalityFormDesktop from './components/PersonalityFormDesktop';
import { useTranslation } from 'react-i18next';

const scales = [
  { id: 1, label: 'Least like me', value: '1' },
  { id: 2, label: 'Not much like me', value: '2' },
  { id: 3, label: 'Neutral', value: '3' },
  { id: 4, label: 'Somewhat like me', value: '4' },
  { id: 5, label: 'Most like me', value: '5' },
];

const chunk_inefficient = function (chunkSize, array) {
  return [].concat.apply(
    [],
    array.map(function (elem, i) {
      return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
    }),
  );
};

const PersonalityTestTie = (props) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [isMounted, setMounted] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [isEndPage, setEndPage] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const componentDidMount = React.useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      axios
        .get(
          `${BASE_URL}${PATH}assessments/${props.match.params.id}/tied_traits_options/`,
        )
        .then((res) => {
          if (200 === res.status) {
            setPages(chunk_inefficient(5, res.data));

            const optionsModif = chunk_inefficient(5, res.data).map(
              (optionsItem, optionsItemIdx) => ({
                results: [
                  {
                    id: optionsItemIdx + 1,
                    text: t(
                      'Please use the scale provided to identify your preference for each statement.',
                    ),
                    scales,
                    options: optionsItem,
                  },
                ],
              }),
            );
            setPages(optionsModif);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response !== undefined) {
            unauthorized(err.response.status);
          }
        });
    }
    // eslint-disable-next-line
  }, [
    // pages,
    props.match.params.id,
  ]);

  const updateDimensions = useCallback(() => {
    const mediaQueryList = matchMedia('screen and (max-width: 767px)');
    const { matches } = mediaQueryList;

    if (isTablet !== matches) {
      setIsTablet(matches);
    }
  }, [isTablet]);

  const handleGoBack = useCallback(() => {
    const { history, t } = props;

    if (history.action === 'POP' && isSubmitting) {
      alert(t('Please wait for submit'));
    }
  }, [props, isSubmitting]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    if (!isMounted) {
      setMounted(true);
      componentDidMount();
    }

    return handleGoBack;
  }, [isMounted, componentDidMount, updateDimensions, handleGoBack]);

  const nextPage = () => {
    if (currentPage < pages.length) {
      const nextPage = currentPage + 1;
      if (nextPage + 1 === pages.length) {
        setEndPage(true);
      }
      setCurrentPage(nextPage);
    }
  };

  const handleChangeRadio = (item, itemIndex) => {
    const pagesCopy = [...pages];
    const { options } = pagesCopy[currentPage].results[0];

    if (options[itemIndex].value === item.id) {
      console.log('1 condition');
      options[itemIndex].value = '';
    } else if (!options.some((option) => option.value === item.id)) {
      console.log('2 condition');
      options[itemIndex].value = item.id;
    }

    setPages(pagesCopy);
  };

  const onSubmit = () => {
    setLoading(true);
    setIsSubmitting(true);

    const collectedData = pages.reduce((acc, current) => {
      const newOptions = current.results[0].options.map((option) => ({
        option_id: option.id,
        scale: +option.value,
      }));
      return [...acc, ...newOptions];
    }, []);

    axios
      .post(
        `${BASE_URL}${PATH}assessments/${props.match.params.id}/untie_traits_options/`,
        collectedData,
      )
      .then((res) => {
        if (res.status) {
          const persTestPathArr = props.location.pathname.split('/');
          const persTestId = persTestPathArr[persTestPathArr.length - 2];

          props.history.replace(`/assessments/${persTestId}/tests-completed/`);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const isDisabled = () =>
    pages[currentPage].results[0].options.some((item) => !item.value);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      isDashboardHeader
      history={props.history}
      match={props.match}
      isDashboardFooter
    >
      <div className="w-100 text-align-start">
        <form>
          <h1
            className="content-title test-example"
            dangerouslySetInnerHTML={{
              __html: t(`TR8S&copy; Personality`),
            }}
          ></h1>
          <div className="progressStatus">
            <Progress current={currentPage + 1} all={pages.length} />
          </div>

          <div className="personality-test-form personality-test-form-position">
            <p
              className="test-question-title"
              dangerouslySetInnerHTML={{
                __html: t('Great you are almost done'),
              }}
            ></p>

            <div className="scrollingTable mb-3">
              {isTablet && pages.length ? (
                <PersonalityFormMobile
                  questions={pages[currentPage]}
                  handleChangeRadio={handleChangeRadio}
                  isTie={true}
                />
              ) : (
                <PersonalityFormDesktop
                  questions={pages[currentPage]}
                  handleChangeRadio={handleChangeRadio}
                  isTie={true}
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end mb-5">
            {!isEndPage ? (
              <button
                type="button"
                disabled={isDisabled()}
                className={`tr8s-button green assessmentBtnNext mt-0 ${
                  isDisabled() ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={nextPage}
              >
                {t('Next')}
              </button>
            ) : null}

            {isEndPage ? (
              <button
                type="button"
                disabled={isDisabled()}
                className={`tr8s-button mt-0 green assessmentBtnNext ${
                  isDisabled() ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={onSubmit}
              >
                {t('Submit')}
              </button>
            ) : null}
          </div>
        </form>
      </div>
    </PageWrapper>
  );
};

export default PersonalityTestTie;
