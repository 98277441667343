import React from 'react';
import { connect } from 'react-redux';
import { REF_ID } from '../../constants/variables';
import ls from 'local-storage';
import { withRouter } from 'react-router-dom';
import { refCompanyAction } from '../../actions/refCompanyAction';
import LoginForm from '../../components/Auth/LoginForm';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';

class Login extends React.Component {
  state = {
    data: false,
  };

  async componentDidMount() {
    const { refCompanyAction } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    let ref_id = REF_ID;
    if (query.get('ref_id')) {
      ref_id = query.get('ref_id');
    } else if (ls.get('refCompanyData')) {
      ref_id = ls.get('refCompanyData').ref_id;
    } else {
      ref_id = REF_ID;
    }
    refCompanyAction(ref_id, '');
  }

  render() {
    if (typeof this.props.refCompData === 'undefined') {
      return <Loader />;
    }

    return (
      <PageWrapper
        logoURL={this.props.refCompData.logo}
        logoAlt={this.props.refCompData.name}
      >
        <div className="container mb-auto">
          <div className="row">
            <div className="col-12">
              <LoginForm
                history={this.props.history}
                fetchAuthData={this.props.fetchAuthData}
                allowSignup={this.props.refCompData.allow_signup}
              />
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refCompLoading: state.refCompanyReducer.refCompLoading,
    refCompData: state.refCompanyReducer.refCompData,
  };
};

export default withRouter(
  connect(mapStateToProps, { refCompanyAction })(Login),
);
