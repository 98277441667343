import React from 'react';
import AsideCompany from './Aside/AsideCompany';
import AsideSteps from './Aside/AsideSteps';
import AsideVacancy from './Aside/AsideVacancy';
import Footer from './Footer';
import Header from './Header';

const steps = [
  { status: 'active', label: 'Knock-out questions' },
  { status: 'active', label: 'Upload CV' },
];

const options = [
  { id: 1, label: 'Strongly disagree' },
  { id: 2, label: 'Disagree' },
  { id: 3, label: 'Slightly disagree' },
  { id: 4, label: 'Neither agree nor disagree' },
  { id: 5, label: 'Slightly agree' },
  { id: 6, label: 'Agree' },
  { id: 7, label: 'Strongly agree' },
];

class Layout extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-9 content">
            <div className="content-wrapper">
              <Header isDashboardHeader />

              <div>
                <form className="test-two-select">
                  {options.map((option, i) => (
                    <label key={i} htmlFor={option.id}>
                      {option.label}
                      <input id={option.id} name="options" type="radio" />
                    </label>
                  ))}
                </form>
              </div>

              <Footer isDashboardFooter />
            </div>
          </div>

          <div className="d-none d-lg-block col-lg-3">
            <div className="row"></div>

            <div className="aside-wrapper">
              <div style={{ marginBottom: '105px' }} />

              <AsideVacancy
                vacancyName="Web Developer (Front End)"
                vacancyLink="#"
              />

              <AsideCompany
                companyName="FedEx Company"
                companyLogo="/assets/img/fedex-logo.png"
              />

              <AsideSteps steps={steps} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
