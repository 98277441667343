import axios from 'axios';
import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
} from '../constants/ActionTypes';
import { BASE_URL, PATHAPI } from '../constants/variables';

const signUpRequest = (isLoading) => {
  return {
    type: SIGN_UP_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const signUpSuccess = (userId) => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: {
      userId,
    },
  };
};

const signUpFailure = (error) => {
  return {
    type: SIGN_UP_FAILURE,
    payload: {
      error,
    },
  };
};

export const signUpUser = (user, history) => {
  return async (dispatch) => {
    dispatch(signUpRequest(true));
    await axios
      .post(`${BASE_URL}${PATHAPI}auth/users/`, user)
      .then((res) => {
        if (res.status !== 201) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        dispatch(signUpSuccess(res.data));
        signUpRequest(false);
        dispatch(signUpFailure(''));
        history.push('/login');
      })
      .catch((err) => {
        dispatch(
          signUpFailure(
            err.response.data.email
              ? err.response.data.email
              : err.response.data.password,
          ),
        );
        signUpRequest(false);
        history.push('/sign-up');
      });
  };
};
