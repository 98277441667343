import axios from 'axios';
import {
  GET_ASSESSMENT_BY_ID_REQUEST,
  GET_ASSESSMENT_BY_ID_SUCCESS,
  GET_ASSESSMENT_BY_ID_FAILURE,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const getAssessmentsByIdRequest = (isLoading) => {
  return {
    type: GET_ASSESSMENT_BY_ID_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const getAssessmentsByIdSuccess = (data) => {
  return {
    type: GET_ASSESSMENT_BY_ID_SUCCESS,
    payload: data,
  };
};

const getAssessmentsByIdFailure = (error) => {
  return {
    type: GET_ASSESSMENT_BY_ID_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchAssessmentByIdData = (assessmentId) => {
  return async (dispatch) => {
    dispatch(getAssessmentsByIdRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios(`${BASE_URL}${PATH}assessments/${assessmentId}/`)
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        // console.log('-------', res.data);
        // const { vacancy } = res.data;
        dispatch(getAssessmentsByIdSuccess({ assessment: res.data }));
        dispatch(getAssessmentsByIdRequest(false));
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(getAssessmentsByIdFailure(err));
        dispatch(getAssessmentsByIdRequest(false));
      });
  };
};
