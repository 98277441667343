import axios from 'axios';
import {
  USER_PROFILE_INFO_FAILURE,
  USER_PROFILE_INFO_REQUEST,
  USER_PROFILE_INFO_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL } from '../constants/variables';
import unauthorized from '../unauthorized';

const userInfoRequest = (isLoading) => {
  return {
    type: USER_PROFILE_INFO_REQUEST,
    payload: {
      isLoading,
    },
  };
};

export const userInfoSuccess = (userInfo) => {
  return {
    type: USER_PROFILE_INFO_SUCCESS,
    payload: {
      userInfo,
    },
  };
};

export const userInfoFailure = (error) => {
  return {
    type: USER_PROFILE_INFO_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchUserData = (history = '') => {
  return async (dispatch) => {
    dispatch(userInfoRequest(true));
    await axios(`${BASE_URL}/api/auth/users/me/`)
      .then((res) => {
        unauthorized(res.status);
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => {
        const { data } = res;
        if (history && !data.privacy_policy_accepted) {
          history.push('/confirmation-of-global-policy');
        } else if (history && !data.consent_provided) {
          history.push('/personal-information');
        } else {
          dispatch(userInfoSuccess(data));
          dispatch(userInfoRequest(false));
        }
      })
      .catch((err) => {
        unauthorized(err.response.status);
        dispatch(userInfoFailure(err));
        dispatch(userInfoRequest(false));
      });
  };
};
