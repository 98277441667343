import React from 'react';
import Steps from '../UI/Steps';

function AsideSteps(props) {
  return (
    <div className="aside-block">
      <p className="aside-block-title">Steps</p>

      <div className="aside-w-steps">
        <Steps steps={props.steps} />
      </div>
    </div>
  );
}

export default AsideSteps;
