// Core
import { get } from 'loadsh';
import ls from 'local-storage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AssessmentNotFound from '../components/AssessmentNotFound';
import Game10 from '../components/game10';
// import GameThird from '../components/game3';
// Games
import GameSeven from '../components/game7';
import GameEight from '../components/game8';
import GameNine from '../components/game9';
import Game10Info from '../components/gameInfo/gameInfo10';
// import GameThen2 from "../components/game10/index2";
// import Game3Info from '../components/gameInfo/gameInfo3';
import Game7Info from '../components/gameInfo/gameInfo7';
import Game8Info from '../components/gameInfo/gameInfo8';
import Game9Info from '../components/gameInfo/gameInfo9';
import GameNotFound from '../components/GameNotFound';
import StartGame10 from '../components/gameStart/gameStart10.js';
// import StartGame3 from '../components/gameStart/gameStart3.js';
import StartGame7 from '../components/gameStart/gameStart7.js';
import StartGame8 from '../components/gameStart/gameStart8.js';
import StartGame9 from '../components/gameStart/gameStart9.js';
// Layout
import Layout from '../components/Layout';
import Main from '../components/Main';
import Message from '../components/Message';
import Page404 from '../components/page404';
import TestGame10 from '../components/testRounds/game10';
// import TestGame3 from '../components/testRounds/game3';
import TestGame7 from '../components/testRounds/game7';
import TestGame8 from '../components/testRounds/game8';
import TestGame9 from '../components/testRounds/game9';
import AdvanceManagerReport from '../containers/AdvanceManagerReport/AdvanceManagerReport';
import AssessmentsInfo from '../containers/AssessmentsInfo';
import AssessmentsInfoLast from '../containers/AssessmentsInfoLast';
import AssessmentsParticularVacancy from '../containers/AssessmentsParticularVacancy';
import ChangePassword from '../containers/Auth/ChangePassword';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import ForgotPasswordSuccess from '../containers/Auth/ForgotPasswordSuccess';
// Authentication
import Login from '../containers/Auth/Login';
import ResetPassword from '../containers/Auth/ResetPassword';
import ResetPasswordSuccess from '../containers/Auth/ResetPasswordSuccess';
import SignUp from '../containers/Auth/SignUp';
import CareerDrivers from '../containers/CareerDrivers';
import ConfirmationOfGlobalPolicy from '../containers/ConfirmationOfGlobalPlolicy';
import GameAssessments from '../containers/GameAssessments';
import GamesCompleted from '../containers/GamesCompleted/GamesCompleted';
import PaymentPaypalSuccess from '../containers/Jobs/PaymentPaypalSuccess';
import QuestionnaireAssessment from '../containers/QuestionnaireAssessment/QuestionnaireAssessment';
import ManagerReport from '../containers/ManagerReport/ManagerReport';
import PersonalityTest from '../containers/PersonalityTest/PersonalityTest';
import PersonalityTestExample from '../containers/PersonalityTest/PersonalityTestExample';
// Personality test
import PersonalityTestReport from '../containers/PersonalityTestReport/PersonalityTestReport';
import PersonalityTestTie from '../containers/PersonalityTest/PersonalityTestTie';
import PrivacyPolicy from '../containers/PrivacyPolicy';
// Profile
import ProfileSettings from '../containers/Profile/ProfileSettings';
import ProfileTestResults from '../containers/Profile/ProfileTestResults';
// Situational judgment test
import SituationalJudgmentTest from '../containers/SituationalJudgmentTest/SituationalJudgmentTest';
import SituationalJudgmentTestAccept from '../containers/SituationalJudgmentTest/SituationalJudgmentTestAccept';
import SituationalJudgmentTestExample from '../containers/SituationalJudgmentTest/SituationalJudgmentTestExample';
// Knock-out questions
import TermsofService from '../containers/TermsofService';
// Test completed
import TestCompleted from '../containers/TestCompleted/TestCompleted';
// Upload CV
// import UploadCV from '../containers/UploadCV/UploadCV';
// import UploadCVSuccess from '../containers/UploadCV/UploadCVSuccess';
// Vacancy
import Vacancy from '../containers/Vacancy/Vacancy';
import WhiteboxVideo from '../containers/VideoAssessment/WhiteboxVideo';
import routes from '../utils/routes';
import Expired from '../containers/Auth/Expired';

const Routes = () => {
  let theme = useSelector((state) =>
    get(state, ['refCompanyReducer', 'refCompData', 'theme'], false),
  );
  // console.log("theme1", theme);
  if (!theme) {
    const themeData = ls.get('refCompanyData');
    theme = get(themeData, ['theme'], false);
  }

  return (
    <React.Fragment>
      <Helmet>
        <style>
          {`
          span.whitelabel svg {
            max-width: 100%;
            max-height: 100%;
          }
          `}
        </style>
        {theme ? (
          <style>
            {`
            .loginBtn, .test-result-block-button,.progressStatusBaColor, .testProgreeBarInfo, .theme-background,.listItem:hover,
            .lds-roller div:after, .assessmentBtnNext, .checkbox-container input:checked ~ .checkmark,
            .goToDashboard, .completeTitle + ul li span.active, .importantCard .cardHeader, .importantCardMobile .cardHeader,
            .green.startNow, [class*="style_progress"] [class*="style_complele"], [class^="style_buttonNext"], .help-popup-header, .green.saveProfile,
            .auth-form-container div button:first-child, .saveAndClose, .rounded-circle.checked {
              background: ${theme} !important;
            }
            .saveAndClose {
              color: #ffffff;
            }
            .progressStatus, vacancyStatus, .MuiSlider-root, .completeTitle + ul li span.active, .theme-color,
            .cardContentWrapper i {
              color: ${theme} !important;
            }
            .progressBar .CircularProgressbar .CircularProgressbar-path {
              stroke: ${theme} !important;
            }
            svg.whitelabel path, span.whitelabel path {
              fill: ${theme} !important;
            }
            .cls-1, .whitebox__card-icon .cls-2 { fill: ${theme} !important }
            .viewDetailButton, .checkmark, .checkbox-container input:checked ~ .checkmark, .checkbox-container input:checked ~ .checkmark,
            .auth-form-container div button:last-child, .rounded-circle {
              border-color: ${theme} !important;
            }
            .triangle-topright{
              border-top-color: ${theme} !important;
            }
          `}
          </style>
        ) : (
          ''
        )}
      </Helmet>
      <Switch>
        {/* Auth */}
        <Route exact component={Main} path="/" />
        <Route exact component={Login} path="/login" />
        <Route exact component={SignUp} path="/sign-up" />
        <Route exact component={Expired} path="/expired" />
        <Route
          exact
          component={ConfirmationOfGlobalPolicy}
          path="/sign-up-success"
        />
        <Route exact component={ForgotPassword} path="/forgot-password" />
        <Route
          exact
          component={ResetPassword}
          path="/password/reset/confirm/:uid/:token"
        />
        <Route
          exact
          component={ResetPasswordSuccess}
          path="/reset-password-success"
        />
        <Route
          exact
          component={ForgotPasswordSuccess}
          path="/forgot-password-success"
        />
        <Route exact component={ChangePassword} path="/change-password" />

        {/* {Jobs} */}
        {/* <Route exact component={Jobs} path="/jobs" /> */}
        <Route
          exact
          component={PaymentPaypalSuccess}
          path="/payment-paypal-success/:jobId"
        />

        {/* Profile */}
        <Route exact component={ProfileSettings} path="/profile" />
        <Route exact component={ProfileSettings} path="/personal-information" />
        <Route exact component={ProfileTestResults} path="/dashboard" />
        {/* Vacancy */}
        <Route exact component={Vacancy} path="/vacancies/:id" />
        {/* QuestionnaireAssessment questions */}
        <Route
          exact
          component={QuestionnaireAssessment}
          path="/vacancies/:id/questionnaire-assessment"
        />
        {/*<Route exact component={UploadCV} path="/vacancies/:id/upload-cv" />*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  component={UploadCVSuccess}*/}
        {/*  path="/vacancies/:id/upload-cv-success"*/}
        {/*/>*/}
        {/* Personality test */}
        <Route
          exact
          component={PersonalityTestExample}
          path="/personality-example/:id"
        />
        <Route
          exact
          component={PersonalityTest}
          path="/personality-assessment/:id"
        />

        <Route
          exact
          component={PersonalityTestTie}
          path="/personality-assessment/:id/tie"
        />

        {/* Situational judgment test */}
        <Route
          exact
          component={SituationalJudgmentTestExample}
          path="/sjt-example/:id"
        />
        <Route
          exact
          component={SituationalJudgmentTest}
          path="/sjt-assessment/:id"
        />
        <Route
          exact
          component={SituationalJudgmentTest}
          path="/vacancies/:id/situational-judgment-test-part-two-start"
        />
        {/* Test completed */}
        <Route
          exact
          component={TestCompleted}
          path="/assessments/:id/tests-completed"
        />
        <Route
          exact
          component={GamesCompleted}
          path="/assessments/:id/games-completed"
        />
        <Route
          exact
          component={AssessmentsParticularVacancy}
          path={routes.applications}
        />
        <Route exact component={AssessmentsInfo} path="/assessments/:id/info" />
        <Route
          exact
          component={AssessmentsInfoLast}
          path="/personality-start/:id"
        />
        <Route
          exact
          component={SituationalJudgmentTestAccept}
          path="/sjt-start/:id"
        />
        <Route
          exact
          component={ConfirmationOfGlobalPolicy}
          path="/confirmation-of-global-policy"
        />

        <Route exact component={CareerDrivers} path="/career-drivers/:id" />

        <Route exact component={PrivacyPolicy} path="/privacy-policy" />
        <Route exact component={Message} path="/sign-up-message" />
        <Route exact component={GameAssessments} path="/game-assessments/:id" />
        <Route
          exact
          component={QuestionnaireAssessment}
          path="/questionnaire-assessment/:id"
        />
        <Route exact component={TermsofService} path="/terms-of-service" />
        {/* Games */}
        {/*<Route exact component={GameThird} path="/game/3:id" />*/}
        <Route exact component={GameSeven} path="/game/7:id" />
        <Route exact component={GameEight} path="/game/8:id" />
        <Route exact component={GameNine} path="/game/9:id" />
        <Route exact component={Game10} path="/game/10:id" />
        {/* <Route exact component={GameThen2} path="/game/11:id" /> */}
        {/* practice-info */}
        {/*<Route exact component={Game3Info} path="/game-info/3:id" />*/}
        <Route exact component={Game7Info} path="/game-info/7:id" />
        <Route exact component={Game8Info} path="/game-info/8:id" />
        <Route exact component={Game9Info} path="/game-info/9:id" />
        <Route exact component={Game10Info} path="/game-info/10:id" />
        {/* practice-start */}
        {/*<Route exact component={StartGame3} path="/game-start/3:id" />*/}
        <Route exact component={StartGame7} path="/game-start/7:id" />
        <Route exact component={StartGame8} path="/game-start/8:id" />
        <Route exact component={StartGame9} path="/game-start/9:id" />
        <Route exact component={StartGame10} path="/game-start/10:id" />
        {/* practice-round */}
        {/*<Route exact component={TestGame3} path="/practice-run/3:id" />*/}
        <Route exact component={TestGame7} path="/practice-run/7:id" />
        <Route exact component={TestGame8} path="/practice-run/8:id" />
        <Route exact component={TestGame9} path="/practice-run/9:id" />
        <Route exact component={TestGame10} path="/practice-run/10:id" />
        {/* Video assessments */}
        <Route exact component={WhiteboxVideo} path={routes.videoAssessment} />
        {/* Report */}
        <Route
          exact
          component={PersonalityTestReport}
          path="/assessment/:id/report"
        />
        <Route
          exact
          component={ManagerReport}
          path="/application/:id/manager-report"
        />

        <Route
          exact
          component={AdvanceManagerReport}
          path="/application/:id/advance-manager-report"
        />
        {/* Layout */}
        <Route exact component={Layout} path="/layout" />
        {/* 404 */}
        <Route exact component={GameNotFound} path="/game-error/:id" />
        <Route
          exact
          component={AssessmentNotFound}
          path="/assessment-error/:id"
        />
        <Route component={Page404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
