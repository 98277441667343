import ls from 'local-storage';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAuthData } from '../../actions/authUserActions';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import {
  getLocalStorageItem,
  localStorageKey,
} from '../../services/localStorage';
import DOMPurify from 'dompurify';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    const { fetchAuthData, history } = this.props;
    let company_id = '';
    if (ls.get('refCompanyData')) {
      company_id = ls.get('refCompanyData').id;
    }
    fetchAuthData({ email, password, company_id }, history);
  };

  render() {
    const { loginError, t } = this.props;
    const direction = localStorage.getItem('contentDirection');
    // if(loginLoader){
    //   return <Loader />;
    // }

    const refCompanyData = getLocalStorageItem(localStorageKey.refCompanyData);

    const loginLogo = this.props.logoURL ? (
      <img
        className="login-logo"
        src={this.props.logoURL}
        alt={this.props.logoAlt}
      />
    ) : refCompanyData && refCompanyData.logo ? (
      <img
        className="login-logo"
        src={DOMPurify.sanitize(refCompanyData.logo)}
        alt={refCompanyData.name}
      />
    ) : (
      <img className="login-logo" src="/assets/img/logo.png" alt="WhiteboxHR" />
    );

    return (
      <div className="auth-form-container text-center">
        {!this.props.userInfo ? loginLogo : ''}
        <p className="auth-form-title">
          {t('Welcome please login to your account')}
        </p>
        {loginError && <div className="errorBanner">{loginError}</div>}
        <form
          className={`${
            direction === CONTENT_DIRECTION.RTL ? 'text-left' : 'text-right'
          }`}
          onSubmit={this.handleSubmit}
        >
          <div className="form-group">
            <input
              className="auth-form-input"
              required
              placeholder={t('E-Mail')}
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group position-relative">
            <input
              className="auth-form-input"
              required
              placeholder={t('Password')}
              type={this.state.showPassword ? 'text' : 'password'}
              name="password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
            <i
              onClick={() => {
                this.setState({ showPassword: !this.state.showPassword });
              }}
              className={`forgot-password-btn fas ${
                this.state.showPassword ? 'fa-eye-slash' : 'fa-eye'
              } ${direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''}`}
            ></i>
            <Link
              className={`forgot-password-btn ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
              to={'forgot-password'}
            >
              {t('Forgot password')}
            </Link>
          </div>

          <div className="form-group position-relative mb-0">
            <button
              className="tr8s-button loginBtn"
              onClick={this.handleSubmit}
              type="button"
            >
              {t('Login')}
            </button>
          </div>

          {this.props.allowSignup ? (
            <div className="login-signup-switch">
              <span>
                <Link className="footer-copyright" to={'sign-up'}>
                  {t('Dont have an account Sign up')}
                </Link>
              </span>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginError: state.authUser.error,
    loginLoader: state.authUser.isLoading,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchAuthData })(LoginForm),
);
