import React from 'react';
import { useTranslation } from 'react-i18next';

const ImportantCategoryHeaderMobile = ({
  column,
  tasks,
  isOpen,
  handleChangeOpenCategories,
}) => {
  const { t } = useTranslation();

  return (
    <div className="importantCardMobile">
      <div className="cardHeader">{t(column.name)}</div>
      <div className="cardContentWrapper">
        <span>
          {!!tasks.length
            ? tasks.map((task) => t(task.name)).join(', ')
            : t('Choose 5 drivers')}
        </span>
        {isOpen ? (
          <i className="fas fa-minus" onClick={handleChangeOpenCategories} />
        ) : (
          <i className="fas fa-plus" onClick={handleChangeOpenCategories} />
        )}
      </div>
    </div>
  );
};

export default ImportantCategoryHeaderMobile;
