import axios from 'axios';
import {
  GET_ASSESSMENTS_FAILURE,
  GET_ASSESSMENTS_REQUEST,
  GET_ASSESSMENTS_SUCCESS,
} from '../constants/ActionTypes';
import { BASE_URL, PATH } from '../constants/variables';
import setToken from '../setToken';
import unauthorized from '../unauthorized';

const getAssessmentsRequest = (isLoading) => {
  return {
    type: GET_ASSESSMENTS_REQUEST,
    payload: {
      isLoading,
    },
  };
};

const getAssessmentsSuccess = (assessments) => {
  return {
    type: GET_ASSESSMENTS_SUCCESS,
    payload: {
      assessments,
    },
  };
};

const getAssessmentsFailure = (error) => {
  return {
    type: GET_ASSESSMENTS_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchAssessmentsData = (vacancyId) => {
  return async (dispatch) => {
    dispatch(getAssessmentsRequest(true));
    const token = localStorage.getItem('token');
    setToken(token);
    await axios
      .all([
        axios(`${BASE_URL}${PATH}applications/${vacancyId}/progress/`),
        axios(`${BASE_URL}${PATH}applications/${vacancyId}/`),
      ])
      .then((responseArr) => {
        // if (responseArr[0].status !== 200) {
        //   throw Error(responseArr[0].statusText);
        // }
        return responseArr;
      })
      .then((res) => {
        dispatch(
          getAssessmentsSuccess({
            list: res[0].data,
            applicationInfo: res[1].data,
          }),
        );
        dispatch(getAssessmentsRequest(false));
      })
      .catch((err) => {
        if (err[0] !== undefined) {
          unauthorized(err[0].response.status);
        }
        dispatch(getAssessmentsFailure(err));
        dispatch(getAssessmentsRequest(false));
      });
  };
};
